import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import the hooks
import axios from 'axios'; // Import axios for API requests
import { getSecureCookie } from '../utilities/safeSaves';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChatbotDataForm = () => {
    const [formData, setFormData] = useState({
        title: '',
        paragraphText: '',
        url: ''
    });

    const [submittedData, setSubmittedData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const location = useLocation(); // Get location object
    const [errorMessage, setErrorMessage] = useState('');
    const [receivedData, setReceivedData] = useState([]); // Corrected 'recievedData' to 'receivedData'
    const uid = getSecureCookie('uid'); // Assuming `getSecureCookie` is a utility to get cookies

    const handleInputChange = (name, value) => {
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const navigate = useNavigate(); // For navigation

    const handleConfirmSubmit = async () => {
        setLoading(true);
        setErrorMessage(''); // Reset error message

        try {
            if (submittedData.length <= 0) {
                setErrorMessage("Add data to submit")
                return
            }

            // Step 1: Store the chatbot profile in your database using `submittedData` as the summary
            const chatbotDataResponse = await axios.post(
                'https://api-bny5h3g2lq-uc.a.run.app/api/chatbot',
                {
                    ownerName: uid,
                    website: receivedData.websiteUrl, // Including the website URL from the form
                    summary: submittedData, // Sending the manually entered data as summary
                    description: receivedData.description, // Using the description from the form
                    address: receivedData.address || null, // Storing address (can be null)
                    email: receivedData.contactEmail || null, // Storing contact email (can be null)
                    phone: receivedData.contactNumber || null, // Storing contact number (can be null)
                    additional: null, // Add any additional data if needed
                    botName: receivedData.chatbotName, // Using the chatbot name as the bot name
                    customization: {},
                },
                {
                    headers: {
                        Authorization: `Bearer ${getSecureCookie('accessToken')}` // Send Bearer token
                    }
                }
            );

            const chatbotId = chatbotDataResponse.data.chatbotId;
            // Redirect to response page with state (if applicable)
            navigate(`/chatbot-response/${chatbotId}`);

        } catch (error) {
            console.error("Error creating chatbot:", error);
            if (error.response && error.response.status === 403 && error.response.data.error === "Email not verified. Please verify your email to proceed.") {
                setErrorMessage('Email not verified. Please verify your email to proceed.');
            } else if (error.response && error.response.status === 403 && error.response.data.error === "Bot creation limit reached") {
                setErrorMessage('Bot creation limit reached for your plan, upgrade your account to create more!');
            }
            else if (error.response && error.response.status === 403 && error.response.data.error === "Please Recharge Your Balance to Create Bot") {
                setErrorMessage('Please Recharge Your Balance to Create Bot!');
            }
            else {
                setErrorMessage("An error occurred while creating the chatbot. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };

    // Retrieve the previous data from navigation state
    useEffect(() => {
        if (location.state && location.state.formData) {
            setReceivedData(location.state.formData); // Set received data
        } else {
            // Redirect if formData is missing in location.state
            navigate('/create-chatbot/ai-manual');
        }
    }, [location.state, navigate]);

    const splitIntoSentences = (text) => {
        return text
            .split(/(?<=[.!?])\s+/)
            .map(sentence => sentence.trim())
            .filter(sentence => sentence !== '');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true); // Set loading state
        const paragraphs = splitIntoSentences(formData.paragraphText);

        if (formData.title === '') {
            setErrorMessage("Title is mendatory")
            setLoading(false);
            return
        }
        else if (paragraphs.length <= 0) {
            setErrorMessage('Content is needed')
            setLoading(false);
            return
        }

        const newEntry = {
            title: formData.title,
            paragraphs,
            url: formData.url
        };


        setSubmittedData((prevData) => {
            const updatedData = [...prevData, newEntry];
            return updatedData;
        });

        setFormData({
            title: '',
            paragraphText: '',
            url: ''
        });

        setCurrentPage(submittedData.length);
        setLoading(false); // Reset loading state
    };

    const handlePageChange = (step) => {
        setCurrentPage((prevPage) => Math.min(Math.max(prevPage + step, 0), submittedData.length - 1));
    };

    const getPaginationNumbers = () => {
        return Array.from({ length: submittedData.length }, (_, i) => i + 1);
    };

    const remainingCharacters = 5000 - formData.paragraphText.length;

    // Determine the text color based on remaining characters
    let textColorClass = 'text-gray-500'; // Default color
    if (remainingCharacters <= 200) {
        textColorClass = 'text-red-500'; // Red if 200 or fewer characters remaining
    } else if (remainingCharacters <= 500) {
        textColorClass = 'text-orange-500'; // Orange if between 200 and 500
    } else {
        textColorClass = 'text-green-500'; // Green if more than 500 characters remaining
    }

    const handleDeleteEntry = (index) => {
        setSubmittedData((prevData) => prevData.filter((_, i) => i !== index));
        if (currentPage >= submittedData.length - 1 && currentPage > 0) {
            setCurrentPage(currentPage - 1); // Adjust pagination if last item is deleted
        }
    };

    const handleEditEntry = (index) => {
        const entryToEdit = submittedData[index];

        setFormData({
            title: entryToEdit.title,
            paragraphText: entryToEdit.paragraphs.join(' '), // Combine paragraphs for form input
            url: entryToEdit.url
        });

        setSubmittedData((prevData) => prevData.filter((_, i) => i !== index));

        if (currentPage >= submittedData.length - 1 && currentPage > 0) {
            setCurrentPage(currentPage - 1); // Adjust pagination if needed
        }
    };




    return (
        <>
            <div className='mt-2'>
                <h1
                    className="text-3xl text-center uppercase text-white font-bold mb-4 mt-16"
                    style={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.6)' }}
                    data-aos="fade-up" // AOS animation
                    data-aos-duration="1000" // Animation duration
                >
                    Manually Input Your Data
                </h1>
                <div className="w-3/4 relative flex container mx-auto bg-gradient-to-br from-gray-100 via-white to-gray-200 mt-10 rounded-lg shadow-2xl p-6">
                    <div className="flex flex-col lg:flex-row w-full gap-6">
                        {/* Left: Submitted Data Section */}
                        <div className="flex-grow lg:w-5/6">
                            <div className="w-full h-fit mx-auto bg-white p-8 rounded-lg shadow-lg transition-transform transform">
                                {/* Scrollable Area */}
                                <div className="h-96 overflow-y-auto max-h-96 mb-4 bg-gray-50 p-4 rounded-lg shadow-inner">
                                    {submittedData.length === 0 ? (
                                        <div className="text-center text-gray-500 mt-20">
                                            <h3 className="text-2xl font-bold">No Data Submitted Yet</h3>
                                            <p className="mt-2">Please enter data in the form on the right.</p>
                                        </div>
                                    ) : (
                                        submittedData[currentPage] && (
                                            <div className="m-6 relative">
                                                <h3 className="text-3xl font-bold text-center mb-4 text-gray-800">
                                                    {submittedData[currentPage].title}
                                                </h3>
                                                <p className="text-blue-600 mb-4 text-center underline">
                                                    {submittedData[currentPage].url}
                                                </p>
                                                {submittedData[currentPage].paragraphs.map((para, index) => (
                                                    <p key={index} className="text-gray-700 mb-2 text-lg leading-relaxed">
                                                        {para}
                                                    </p>
                                                ))}

                                                {/* Edit Button */}
                                                <button
                                                    onClick={() => handleEditEntry(currentPage)}
                                                    className="absolute top-0 right-20 mt-2 mr-2 px-3 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                                                >
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>

                                                {/* Delete Button */}
                                                <button
                                                    onClick={() => handleDeleteEntry(currentPage)}
                                                    className="absolute top-0 right-0 mt-2 mr-2 px-3 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                        )
                                    )}

                                </div>

                                {/* Navigation Buttons */}
                                <div className="mt-4">
                                    <div className="flex justify-between">
                                        <button
                                            onClick={() => handlePageChange(-1)}
                                            disabled={currentPage === 0}
                                            className={`w-full px-4 py-2 rounded-lg text-white font-semibold transition duration-300 
                                        ${currentPage === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-gray-500 hover:bg-gray-600'}`}
                                        >
                                            Previous
                                        </button>
                                        <button
                                            onClick={() => handlePageChange(1)}
                                            disabled={currentPage >= submittedData.length - 1}
                                            className={`w-full px-4 py-2 rounded-lg ml-2 text-white font-semibold transition duration-300 
                                        ${currentPage >= submittedData.length - 1 ? 'bg-blue-300 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
                                        >
                                            Next
                                        </button>
                                    </div>

                                    {/* Pagination Numbers */}
                                    <div className="flex justify-center mt-4 space-x-2">
                                        {getPaginationNumbers().map((number, index) => (
                                            <button
                                                key={index}
                                                onClick={() => setCurrentPage(number - 1)}
                                                className={`mx-1 px-3 py-1 border rounded-lg font-semibold transition-colors 
                                            ${currentPage === number - 1 ? 'bg-blue-600 text-white' : 'bg-white text-blue-600 border-blue-600'} 
                                            hover:bg-blue-100 hover:text-blue-600`}
                                            >
                                                {number}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right: Form Section */}
                        <div className="flex-shrink-0 w-full lg:w-2/6 p-1 h-fit">
                            <form onSubmit={handleSubmit}>
                                <label className="block text-gray-700 text-lg font-semibold mb-2">Title</label>
                                <input
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={(e) => handleInputChange('title', e.target.value)}
                                    className="w-full p-3 mb-4 border border-gray-300 rounded-lg shadow-sm focus:ring focus:ring-blue-200"
                                    placeholder="Enter Title"
                                    aria-label="Title"
                                />


                                <label className="block text-gray-700 text-lg font-semibold mb-2">Paragraph</label>
                                <textarea
                                    name="paragraphText"
                                    value={formData.paragraphText}
                                    onChange={(e) => handleInputChange('paragraphText', e.target.value)}
                                    rows="4"
                                    maxLength={5000}
                                    className="w-full p-3 mb-1 border border-gray-300 rounded-lg shadow-sm focus:ring focus:ring-blue-200"
                                    placeholder="Enter Paragraph (Each sentence will be treated as a separate paragraph)"
                                    aria-label="Paragraph"
                                />
                                <div className={`${textColorClass} text-sm mb-2`}>
                                    {remainingCharacters} characters remaining
                                </div>


                                <label className="block text-gray-700 text-lg font-semibold mb-2">URL (Optional)</label>
                                <input
                                    type="url"
                                    name="url"
                                    value={formData.url}
                                    onChange={(e) => handleInputChange('url', e.target.value)}
                                    className="w-full p-3 mb-4 border border-gray-300 rounded-lg shadow-sm focus:ring focus:ring-blue-200"
                                    placeholder="Enter URL"
                                    aria-label="URL"
                                />

                                <button
                                    type="submit"
                                    className="w-full py-3 mt-4 bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-gradient-to-r hover:from-indigo-600 hover:to-blue-600 transition duration-300"
                                    disabled={loading}
                                >
                                    {loading ? 'Submitting...' : 'Submit Data'}
                                </button>
                            </form>

                            {errorMessage && (<>
                                <div className="mt-6 bg-red-100 p-4 rounded-lg shadow-lg">

                                    <h3 className="text-xl font-bold text-red-700">Error:</h3>
                                    <p className="text-red-600">{errorMessage}</p>
                                    {errorMessage === "Email not verified. Please verify your email to proceed." ? (
                                        <p className="text-blue-600">Click "Send Verification Email" button on a top bar</p>
                                    ) : errorMessage === "Account upgrade needed. Please upgrade your account." ? (
                                        <a href="/pricing" className="text-blue-600 underline">Click here to upgrade the account.</a>
                                    ) : errorMessage === "Please Recharge Your Balance to Create Bot!" ? (
                                        <a href={`/${uid}/usage`} className="text-blue-600 underline">Click here to add balance.</a>
                                    ) : errorMessage === "You cannot use URL scrapping in your plan" ? (
                                        <a href="/pricing" className="text-blue-600 underline">Click here to upgrade the account.</a>
                                    ) : errorMessage === "Title is mendatory" ? (
                                        <></>
                                    ) : errorMessage === "Content is needed" ? (
                                        <></>
                                    ) :
                                        (< a href="create-chatbot/ai-manual" className="text-blue-600 underline">Click here to proceed with manual input.</a>
                                        )}
                                </div>

                            </>)}

                            {/* Confirm All Data Button */}
                            <div className="flex justify-end mt-4">
                                <button
                                    onClick={handleConfirmSubmit}
                                    className={`py-2 px-4 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-semibold rounded-md shadow-lg transition-all duration-300 ease-in-out 
                                ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:from-pink-600 hover:to-purple-600 hover:scale-105'}`}
                                    disabled={loading}
                                >
                                    Confirm All Data and Train
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default ChatbotDataForm;