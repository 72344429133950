import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import Navbar from './components/Navbar';
import LandingPage from './components/LandingPage';
import CreateChatbot from './components/CreateChatbot';
import MyBots from './components/MyBots';
import About from './components/About';
import PricingPage from './components/Pricing';
import ChatbotResponsePage from './components/ChatbotResponsePage';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import SearchPage from './components/ChatBot';
import BotTypeSelection from './components/BotTypeSelection';
import CustomizeAIChatBotDesign from './components/CustomizeBot';
import TemplateCarousel from './components/TemplateSelector';
import PricingInfo from './components/PricingInfo';
import UsagePage from './components/UsagePage';
import LoadingScreen from './components/LoadingScreen';
import RequestPasswordReset from './components/RequestPasswordReset';
import ResetPassword from './components/ResetPassword';
import CompleteProfilePage from './components/CompleteProfile';
import Settings from './components/Settings';
import VerifyEmail from './components/Verification';
import EmailNotVerifiedBar from './components/EmailVerifyBar';
import { getSecureCookie, setSecureCookie } from './utilities/safeSaves';
import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/Footer';
import Terms from './components/Terms';
import Refund from './components/Refund';
import CreateAIBot from './components/CreateAIBot';
import CreateChatbotJSON from './components/CreateChatBotJSON';
import AddChatbotDetails from './components/AddChatbotDetails';
import NotFoundPage from './components/404';
import DeploymentInstructions from './components/Instruction';
import ChattingBot from './components/AllPageChat';
import DevMode from './components/DevMode';
import Documentation from './components/Docs';
import PricingCalculator from './components/PricingCalculator';
import PaymentComponent from './components/PaymentComponent';
import PaymentSuccess from './components/PaymentSuccess';

function App() {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const devMode = false;

  // Function to check if the token has expired
  const isTokenExpired = (decodedToken) => {
    const currentTime = Date.now() / 1000; // Get current time in seconds
    return decodedToken.exp < currentTime;
  };
  useEffect(() => {
    setLoading(true)
    const token = getSecureCookie('accessToken'); // Fetch token from cookies
    if (token) {
      try {
        const decoded = jwtDecode(token); // Decode the JWT token
        if (isTokenExpired(decoded)) {
          localStorage.clear();
          setToken(null); // Clear the token state
        } else {
          setToken(token);
        }
        setLoading(false)
      } catch (error) {
        console.error('Invalid token:', error);
        localStorage.clear();
        setToken(null); // Clear the token state
        setLoading(false)
      }
    }
    else {
      setLoading(false)
    }

    setLoading(false); // Set loading to false after token check
  }, []);

  useEffect(() => {

    const fetchUserProfiles = async () => {
      setLoading(true)

      if (!token) {
        setLoading(false)
        return; // If no token, skip fetching profiles
      }

      try {
        const response = await fetch(`https://api-bny5h3g2lq-uc.a.run.app/api/userProfile/${getSecureCookie('uid')}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getSecureCookie('accessToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }

        const data = await response.json();
        setProfile(data);
        setLoading(false)
        setSecureCookie('plan', data.plan, { expires: 7, secure: true });

      } catch (error) {
        console.error('Error fetching user profiles:', error.message);
        setLoading(false)
      }
      finally {
        setLoading(false)
      }
    };


    fetchUserProfiles();
  }, [token]); // Fetch user profiles when token changes

  // Display a loader or blank screen while loading
  if (loading) {
    return <LoadingScreen textToShow={"Initializing Your Bot Companion..."} />;
  }

  const promotion = true;
  // Check if the user is not premium (on the basic plan) or if they have a balance above 300
  const isNotPremium = (profile ? (profile.plan === "basic" && profile.balance < 350) && !promotion : false);
  const isNotManualPremium = (profile ? (profile.plan === "basic" && profile.balance < 150) && !promotion : false);


  return (
    <Router>
      <Navbar />
      <div className="relative min-h-screen bg-gradient-to-br from-pink-500 via-blue-700 to-indigo-900 p-5 sm:p-0 sm:m-0">
        <div className="absolute inset-0 bg-black/60 backdrop-blur-3xl rounded-lg shadow-xl z-10"></div>
        <div className="container mx-auto mt-2 sm:mt-5 relative z-20">
          {token && profile && !profile.emailVerified && <EmailNotVerifiedBar />}
          {devMode ? ( // Conditionally render DevModePage
            <DevMode />
          ) : (
            <>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/create-chatbot/ai" element={token ? <CreateAIBot isNotPremium={true} userHasPremium={!isNotPremium} userHasManualPremium={!isNotManualPremium} /> : <LoginPage />} />
                <Route path="/select-service" element={token ? <BotTypeSelection /> : <LoginPage />} />
                <Route path="/create-chatbot/ai-url" element={
                  token
                    ? !isNotPremium
                      ? <CreateChatbot />
                      : <CreateAIBot isNotPremium={true} userHasPremium={!isNotPremium} />
                    : <LoginPage />
                } />
                <Route path="/create-chatbot/ai-manual" element={token
                  ? !isNotPremium
                    ? <CreateChatbotJSON />
                    : <CreateAIBot isNotPremium={true} userHasPremium={!isNotPremium} />
                  : <LoginPage />} />
                <Route path="/create-chatbot/ai-manual/data" element={token
                  ? !isNotPremium
                    ? <AddChatbotDetails />
                    : <CreateAIBot isNotPremium={true} userHasPremium={!isNotPremium} />
                  : <LoginPage />} />
                <Route path="/my-bots" element={token ? <MyBots /> : <LoginPage />} />
                <Route path="/about" element={<About />} />
                <Route path="/docs" element={<Documentation />} />
                <Route path="/pricing-calculator" element={<PricingCalculator />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/:userid/usage" element={token ? <UsagePage /> : <LoginPage />} />
                <Route path="/login" element={token ? <Navigate to="/" /> : <LoginPage />} />
                <Route path="/register" element={token ? <Navigate to="/" /> : <RegisterPage />} />
                <Route path="/complete-registration" element={token ? <Navigate to="/" /> : <CompleteProfilePage />} />
                <Route path="/chatBot/:chatbotId" element={token ? <SearchPage /> : <LoginPage />} />
                <Route path="/chatBot/:chatbotId/customize" element={token ? <CustomizeAIChatBotDesign /> : <LoginPage />} />
                <Route path="/chatBot/:chatbotId/deploy" element={token ? <DeploymentInstructions /> : <LoginPage />} />
                <Route path="/chatbot-response/:chatbotId" element={token ? <ChatbotResponsePage /> : <LoginPage />} />
                <Route path="/settings" element={token ? <Settings /> : <LoginPage />} />
                <Route path="/requestResetPassword" element={token ? <Navigate to="/" /> : <RequestPasswordReset />} />
                <Route path="/resetPassword" element={token ? <Navigate to="/" /> : <ResetPassword />} />
                <Route path="/verify" element={token ? <VerifyEmail /> : <LoginPage />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/refund" element={<Refund />} />
                <Route path="/chatBot/:chatbotId/templates" element={token ? <TemplateCarousel /> : <LoginPage />} />
                {/* Not Found Route */}
                <Route path='/payment' element={<PaymentComponent />} />
                <Route path='/payment-success' element={<PaymentSuccess />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </>)}

        </div>
      </div>
      <ChattingBot />
    </Router >
  );
}

export default App;
