//make generate project id for each edit

import { React, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    colors, themes, images, defaultDimensions, globalIcons, predefinedUserAvatars, predefinedBotAvatars,
    HEADER_IDS, MESSAGE_AREA_IDS, INPUT_BOX_IDS, templates // Import all necessary constants and templates
} from './Theme';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { getSecureCookie } from '../utilities/safeSaves';


const Sidebar = ({ onThemeChange, onWidthChange, onIconChange, onAvatarChange, onCustomThemeChange, onTagLineChange,
    currentTheme, onFooterChange, onBodyChange, onHeaderChange, botId, setUpdateLoading, updateLoading, isInitialRender, fetchChatbotData }) => {
    const [userAvatarPreview, setUserAvatarPreview] = useState('');
    const [botAvatarPreview, setBotAvatarPreview] = useState('');
    const [selectedTheme, setSelectedTheme] = useState('default');
    const [width, setSelectedWidth] = useState('default');
    const [tagline, setSelectedTagLine] = useState('I am an assistant Bot')
    const [selectedHeader, setSelectedHeader] = useState('Basic');
    const [selectedMessageArea, setSelectedMessageArea] = useState('Basic');
    const [selectedInputBox, setSelectedInputBox] = useState('Basic');
    const [updateMessage, setUpdateMessage] = useState('');
    const navigate = useNavigate();

    const getCustomizationData = () => {
        const data = {
            theme: selectedTheme,
            width: width,
            customColors: customColors,
            userAvatar: userAvatarPreview,
            botAvatar: botAvatarPreview,
            selectedIcon: localStorage.getItem(`icon-${botId}`) || 'None',
        };
        console.log('Customization Data:', data);
        return data;
    };




    const handleReset = () => {
        // Reset to initial state
        setUserAvatarPreview('');
        setBotAvatarPreview('');
        setSelectedTheme('default');
        setSelectedWidth('default');
        setCustomColors({
            userMessageBg: '#1D4ED8',
            botMessageBg: '#D1D5DB',
            buttonBg: '#1D4ED8',
            buttonHoverBg: '#2563EB',
            botTextColor: '#111827',
            userTextColor: '#111827',
            linkTextColor: '#1D4ED8',
            headerBg: '#1D4ED8',
            headerTextColor: '#fffff',

        });

        // Notify parent components to reset their states
        onThemeChange('default');
        onCustomThemeChange({
            userMessageBg: '#1D4ED8',
            botMessageBg: '#D1D5DB',
            buttonBg: '#1D4ED8',
            buttonHoverBg: '#2563EB',
            botTextColor: '#111827',
            userTextColor: '#111827',
            linkTextColor: '#1D4ED8',
            headerBg: '#1D4ED8',
            headerTextColor: '#fffff',

        });
        onAvatarChange('userAvatar', images.userAvatar); // Reset user avatar
        onAvatarChange('botAvatar', images.botAvatar); // Reset bot avatar
        onIconChange(globalIcons.sendIcon); // Reset to a default icon
        handleMessageAreaChange('Basic');
        handleHeaderChange('Basic');
        handleInputBoxChange('Basic');
        handleWidthChange('360px')
        localStorage.clear(); // Clear all local storage data related to settings
    };


    const [customColors, setCustomColors] = useState({
        userMessageBg: '#1D4ED8',
        botMessageBg: '#D1D5DB',
        buttonBg: '#1D4ED8',
        buttonHoverBg: '#2563EB',
        botTextColor: '#111827',
        userTextColor: '#111827',
        linkTextColor: '#1D4ED8',
        headerBg: '#1D4ED8',
        headerTextColor: '#fffff',
    });

    const [customTemplate, setCustomTemplate] = useState({
        headerId: 'Basic',
        messageAreaId: 'Basic',
        inputBoxId: 'Basic',
    });

    const handleIconChange = (icon, iconName) => {
        const nameOfIcon = iconName; // Store only the icon name
        onIconChange(icon); // Notify the parent component
        localStorage.setItem(`icon-${botId}`, nameOfIcon); // Save the name to localStorage
        getCustomizationData();
    };

    const handleUserAvatarChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const result = e.target.result;
                setUserAvatarPreview(result);
                onAvatarChange('userAvatar', result); // Notify with the new file data
            };
            reader.readAsDataURL(file);
        }
    };
    const handleBotAvatarChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const result = e.target.result;
                setBotAvatarPreview(result);
                onAvatarChange('botAvatar', result); // Notify with the new file data
            };
            reader.readAsDataURL(file);
        }
    };
    const handleAvatarSelect = (avatarUrl, avatarType) => {
        if (avatarType === 'userAvatar') {
            setUserAvatarPreview(avatarUrl);
        } else if (avatarType === 'botAvatar') {
            setBotAvatarPreview(avatarUrl);
        }
        onAvatarChange(avatarType, avatarUrl); // Notify about avatar selection
    };

    const handleThemeChange = (value) => {
        setSelectedTheme(value); // Update selected theme
        onThemeChange(value); // Notify parent of theme change
    };

    const handleColorChange = (event) => {
        const { name, value } = event.target;
        const updatedColors = { ...customColors, [name]: value };
        setCustomColors(updatedColors);
        onCustomThemeChange(updatedColors); // Update the parent with the new theme
    };
    //local storage data
    const saveThemeToLocalStorage = (themeData) => {
        localStorage.setItem(`customTheme-${botId}`, JSON.stringify(themeData));
    };

    // Load the saved theme from localStorage
    const loadThemeFromLocalStorage = () => {
        const savedTheme = localStorage.getItem(`customTheme-${botId}`);
        if (savedTheme) {
            const parsedTheme = JSON.parse(savedTheme);
            setCustomColors(parsedTheme);
            onCustomThemeChange(parsedTheme); // Apply theme on load
        }

        const themeName = localStorage.getItem(`themeName-${botId}`);
        if (themeName) {
            setSelectedTheme(themeName);
        }

        const width = localStorage.getItem(`width-${botId}`);
        if (width) {
            onWidthChange(width);
            setSelectedWidth(width)
        }

        const savedIconName = localStorage.getItem(`icon-${botId}`);
        if (savedIconName && globalIcons[savedIconName]) {
            onIconChange(globalIcons[savedIconName]); // Use the matching icon
        }

        const savedHeader = localStorage.getItem(`selectedHeader-${botId}`);
        if (savedHeader) {
            setSelectedHeader(savedHeader);
            handleHeaderChange(savedHeader)
        }

        const savedMessageArea = localStorage.getItem(`selectedMessageArea-${botId}`);
        if (savedMessageArea) {
            setSelectedMessageArea(savedMessageArea);
            handleMessageAreaChange(savedMessageArea)
        }

        const savedInputBox = localStorage.getItem(`selectedInputBox-${botId}`);
        if (savedInputBox) {
            setSelectedInputBox(savedInputBox);
            handleInputBoxChange(savedInputBox)
        }

        const savedTagline = localStorage.getItem(`tagline-${botId}`);
        if (savedTagline) {
            setSelectedTagLine(savedTagline)
            handleTagLineChange(savedTagline)
        }
    };

    const handleWidthChange = (width) => {
        onWidthChange(width);
        localStorage.setItem(`width-${botId}`, width);
        setSelectedWidth(width)
    }

    const handleTagLineChange = (tagline) => {
        onTagLineChange(tagline);
        setSelectedTagLine(tagline)
        localStorage.setItem(`tagline-${botId}`, tagline);
    }

    const handleHeaderChange = (value) => {
        setSelectedHeader(value);
        onHeaderChange(value);
        localStorage.setItem(`selectedHeader-${botId}`, value); // Store selection
    };

    const handleMessageAreaChange = (value) => {
        setSelectedMessageArea(value);
        onBodyChange(value);
        localStorage.setItem(`selectedMessageArea-${botId}`, value); // Store selection
    };

    const handleInputBoxChange = (value) => {
        setSelectedInputBox(value);
        onFooterChange(value);
        localStorage.setItem(`selectedInputBox-${botId}`, value); // Store selection
    };


    useEffect(() => {
        loadThemeFromLocalStorage(); // Load saved theme on mount
        console.log("Loaded")
    }, []);

    useEffect(() => {
        if (isInitialRender.current) {
            // Skip the effect on initial render
            isInitialRender.current = false;
            return;
        }

        if (typeof currentTheme === 'object') {
            setCustomColors(currentTheme);
            saveThemeToLocalStorage(currentTheme);
        }
    }, [currentTheme]);

    const getCustomizationData_new = () => {
        const data = {};

        // Fetch data from localStorage that corresponds to the chatbotId
        for (const key in localStorage) {
            if (key.endsWith(`-${botId}`)) {
                const value = localStorage.getItem(key);
                try {
                    data[key.split('-')[0]] = JSON.parse(value);
                } catch (error) {
                    console.warn(`Value for ${key} is not valid JSON: ${value}`);
                    // Store non-JSON values directly
                    data[key.split('-')[0]] = value;
                }
            }
        }

        console.log('Customization Data:', data);
        return data;
    };

    const [customizationData, setCustomizationData] = useState(() => {
        const initialData = {

        };

        // Fetch data from localStorage and override initialData
        const storedData = getCustomizationData_new();
        return { ...initialData, ...storedData }; // Merge initial data with stored data
    });



    const handleUpdateCustomization = async () => {
        setUpdateLoading(true);  // Start loading state
        setUpdateMessage('');     // Clear any previous message

        const uid = getSecureCookie('uid');
        const token = getSecureCookie('accessToken');

        // Gather all customization data from localStorage
        const customizationData = getCustomizationData_new();

        try {
            const response = await fetch(`https://api-bny5h3g2lq-uc.a.run.app/api/chatbot/${botId}/customization`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ customization: customizationData }),
            });

            const data = await response.json();

            if (response.ok) {
                console.log('Customization data updated successfully:', data);
                setUpdateMessage('Customization saved successfully!'); // Success message
                navigate(`/chatbot/${botId}/deploy`);
            } else {
                console.error('Error updating customization data:', data);
                setUpdateMessage('Error updating customization. Please try again.'); // Error message
            }
        } catch (error) {
            console.error('Network error:', error);
            setUpdateMessage('Network error. Please try again.'); // Network error message
        } finally {
            setUpdateLoading(false); // End loading state
            fetchChatbotData()
        }
    };



    return (
        <div className="mt-12 pb-12 fixed left-0 top-0 w-64 h-full bg-gray-800 text-white p-4 overflow-y-auto">
            <h2 className="text-xl font-semibold mb-4">Chatbot Settings</h2>
            {/* Predefined Theme Selection */}
            <div className="mb-6">
                <label className="block text-gray-300 mb-2">Select Predefined Theme</label>
                <select
                    className="w-full p-2 bg-gray-700 text-white rounded"
                    onChange={(e) => handleThemeChange(e.target.value)}
                    value={selectedTheme}
                >
                    <option value="default">Default</option>
                    {/* Nature Category */}
                    <optgroup label="Nature Themes">
                        <option value="garden">Garden Theme</option>
                        <option value="greenish">Greenish Theme</option>
                        <option value="peacock">Peacock Theme</option>
                        <option value="forest">Forest Theme</option>
                        <option value="sunset">Sunset Theme</option>
                        <option value="ocean">Ocean Theme</option>
                        <option value="mountain">Mountain Theme</option>
                    </optgroup>
                    {/* Standard Category */}
                    <optgroup label="Standard Themes">
                        <option value="gold">Gold Theme</option>
                        <option value="red">Red Theme</option>
                        <option value="bluish">Bluish Theme</option>
                        <option value="brownish">Brownish Theme</option>
                        <option value="silver">Silver Theme</option>
                        <option value="black">Black Theme</option>
                    </optgroup>
                    {/* Professional Category */}
                    <optgroup label="Professional Themes">
                        <option value="medieval">Medieval Theme</option>
                        <option value="penguin">Penguin Theme</option>
                        <option value="sleek">Sleek Theme</option>
                        <option value="tech">Tech Theme</option>
                    </optgroup>
                    {/* Fantasy Category */}
                    <optgroup label="Fantasy Themes">
                        <option value="galaxy">Galaxy Theme</option>
                        <option value="nebula">Nebula Theme</option>
                        <option value="dragon">Dragon Theme</option>
                        <option value="fire">Fire Theme</option>
                    </optgroup>
                    {/* Abstract Category */}
                    <optgroup label="Abstract Themes">
                        <option value="neon">Neon Theme</option>
                        <option value="industrialGlow">Industrial Glow Theme</option>
                        <option value="geometric">Geometric Theme</option>
                    </optgroup>
                </select>
            </div>


            {/* Header Style Selection */}
            <div className="mb-6">
                <label className="block text-gray-300 mb-2">Custom Header Style</label>
                <select
                    className="w-full p-2 bg-gray-700 text-white rounded"
                    value={selectedHeader}
                    onChange={(e) => {
                        const value = e.target.value;
                        setSelectedHeader(value); // Update local state
                        handleHeaderChange(value); // Notify parent component
                    }}
                >
                    {HEADER_IDS.map((id) => (
                        <option key={id} value={id}>
                            {id}
                        </option>
                    ))}
                </select>
            </div>

            {/* Chatbot Tagline */}
            {selectedHeader === 'Elegant' && (<div className="mb-6">
                <label className="block mb-2 text-gray-300">Chatbot Tagline</label>
                <input
                    type="text"
                    className="w-full"
                    onChange={(e) => handleTagLineChange(e.target.value)}
                    value={tagline}
                    placeholder="Assistant Bot" // Placeholder instead of defaultValue for clarity
                    style={{ color: 'black' }}
                />
            </div>)}

            {/* Message Area Style Selection */}
            <div className="mb-6">
                <label className="block text-gray-300 mb-2">Custom Message Area Style</label>
                <select
                    className="w-full p-2 bg-gray-700 text-white rounded"
                    value={selectedMessageArea}
                    onChange={(e) => {
                        const value = e.target.value;
                        setSelectedMessageArea(value); // Update local state
                        handleMessageAreaChange(value); // Notify parent component
                    }}
                >
                    {MESSAGE_AREA_IDS.map((id) => (
                        <option key={id} value={id}>
                            {id}
                        </option>
                    ))}
                </select>
            </div>

            {/* Input Box Style Selection */}
            <div className="mb-6">
                <label className="block text-gray-300 mb-2">Custom Input Box Style</label>
                <select
                    className="w-full p-2 bg-gray-700 text-white rounded"
                    value={selectedInputBox}
                    onChange={(e) => {
                        const value = e.target.value;
                        setSelectedInputBox(value); // Update local state
                        handleInputBoxChange(value); // Notify parent component
                    }}
                >
                    {INPUT_BOX_IDS.map((id) => (
                        <option key={id} value={id}>
                            {id}
                        </option>
                    ))}
                </select>
            </div>


            <div className="mb-6">
                <h3 className="text-lg font-semibold mb-2">Create your own theme</h3>
                <div className="grid grid-cols-2 gap-4">
                    {/* Header Background */}
                    <div className="flex items-center">
                        <label className="block text-gray-300">Header Background</label>
                    </div>
                    <div>
                        <input
                            type="color"
                            name="headerBg"
                            value={customColors.headerBg}
                            onChange={handleColorChange}
                            className="w-10 h-10 p-0 border-none cursor-pointer bg-gray-700 rounded"
                        />
                    </div>

                    <div className="flex items-center">
                        <label className="block text-gray-300">Header Text Color</label>
                    </div>
                    <div>
                        <input
                            type="color"
                            name="headerTextColor"
                            value={customColors.headerTextColor}
                            onChange={handleColorChange}
                            className="w-10 h-10 p-0 border-none cursor-pointer bg-gray-700 rounded"
                        />
                    </div>

                    {/* User Message Background */}
                    <div className="flex items-center">
                        <label className="block text-gray-300">User Message Background</label>
                    </div>
                    <div>
                        <input
                            type="color"
                            name="userMessageBg"
                            value={customColors.userMessageBg}
                            onChange={handleColorChange}
                            className="w-10 h-10 p-0 border-none cursor-pointer bg-gray-700 rounded"
                        />
                    </div>

                    {/* Bot Text Color */}
                    <div className="flex items-center">
                        <label className="block text-gray-300">User Message Text Color</label>
                    </div>
                    <div>
                        <input
                            type="color"
                            name="userTextColor"
                            value={customColors.userTextColor}
                            onChange={handleColorChange}
                            className="w-10 h-10 p-0 border-none cursor-pointer bg-gray-700 rounded"
                        />
                    </div>

                    {/* Bot Message Background */}
                    <div className="flex items-center">
                        <label className="block text-gray-300">Bot Message Background</label>
                    </div>
                    <div>
                        <input
                            type="color"
                            name="botMessageBg"
                            value={customColors.botMessageBg}
                            onChange={handleColorChange}
                            className="w-10 h-10 p-0 border-none cursor-pointer bg-gray-700 rounded"
                        />
                    </div>
                    {/* Bot Text Color */}
                    <div className="flex items-center">
                        <label className="block text-gray-300">Bot Message Text Color</label>
                    </div>
                    <div>
                        <input
                            type="color"
                            name="botTextColor"
                            value={customColors.botTextColor}
                            onChange={handleColorChange}
                            className="w-10 h-10 p-0 border-none cursor-pointer bg-gray-700 rounded"
                        />
                    </div>

                    {/* Link color */}

                    <div className="flex items-center">
                        <label className="block text-gray-300">Link Text Color</label>
                    </div>
                    <div>
                        <input
                            type="color"
                            name="linkTextColor"
                            value={customColors.linkTextColor}
                            onChange={handleColorChange}
                            className="w-10 h-10 p-0 border-none cursor-pointer bg-gray-700 rounded"
                        />
                    </div>
                    {/* Button Background */}
                    <div className="flex items-center">
                        <label className="block text-gray-300">Button Background</label>
                    </div>
                    <div>
                        <input
                            type="color"
                            name="buttonBg"
                            value={customColors.buttonBg}
                            onChange={handleColorChange}
                            className="w-10 h-10 p-0 border-none cursor-pointer bg-gray-700 rounded"
                        />
                    </div>
                    {/* Button Hover Background */}
                    <div className="flex items-center">
                        <label className="block text-gray-300">Button Hover Background</label>
                    </div>
                    <div>
                        <input
                            type="color"
                            name="buttonHoverBg"
                            value={customColors.buttonHoverBg}
                            onChange={handleColorChange}
                            className="w-10 h-10 p-0 border-none cursor-pointer bg-gray-700 rounded"
                        />
                    </div>

                </div>
            </div>
            {/* Avatar Customization */}
            {/* <div className="mb-6">
                <label className="block text-gray-300 mb-2">Select User Avatar</label>
                <div className="grid grid-cols-6 gap-4 mb-2">
                    {predefinedUserAvatars.map((avatar, index) => (
                        <div
                            key={index}
                            className="w-8 h-8 rounded-full cursor-pointer border-2 border-transparent hover:border-blue-500 flex items-center justify-center"
                            style={{
                                backgroundImage: `url(${avatar})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                            onClick={() => handleAvatarSelect(avatar, 'userAvatar')}
                        />
                    ))}
                </div> */}
            {/* <input
                    type="file"
                    className="w-full p-2 bg-gray-700 text-white rounded"
                    onChange={handleUserAvatarChange}
                /> */}
            {/* </div> */}
            <div className="mb-6">
                <label className="block text-gray-300 mb-2">Select Bot Avatar</label>
                <div className="grid grid-cols-6 gap-4 mb-2">
                    {predefinedBotAvatars.map((avatar, index) => (
                        <div
                            key={index}
                            className="w-8 h-8 rounded-full cursor-pointer border-2 border-transparent hover:border-blue-500 flex items-center justify-center"
                            style={{
                                backgroundImage: `url(${avatar})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                            onClick={() => handleAvatarSelect(avatar, 'botAvatar')}
                        />
                    ))}
                </div>
                {/* <input
                    type="file"
                    className="w-full p-2 bg-gray-700 text-white rounded"
                    onChange={handleBotAvatarChange}
                /> */}
            </div>


            {/* Chatbot Width Slider */}
            <div className="mb-6">
                <label className="block text-gray-300 mb-2">Chatbot Width</label>
                <input
                    type="range"
                    min="360"
                    max="500"
                    step="10"
                    className="w-full"
                    onChange={(e) => handleWidthChange(`${e.target.value}px`)}
                    value={parseInt(width)}
                    defaultValue='360' // Default width
                />
            </div>
            {/* Icon Selection Dropdown */}
            {/* Icon Selection Grid */}
            <div className="mb-6">
                <label className="block text-gray-300 mb-2">Send Button Icon</label>
                <div className="grid grid-cols-4 gap-4">
                    <div
                        className="p-2 bg-gray-700 text-white rounded cursor-pointer flex justify-center"
                        onClick={() => handleIconChange(globalIcons.sendIcon, 'sendIcon')}
                    >
                        <FontAwesomeIcon icon={globalIcons.sendIcon} size="2x" />
                    </div>
                    <div
                        className="p-2 bg-gray-700 text-white rounded cursor-pointer flex justify-center"
                        onClick={() => handleIconChange(globalIcons.arrowIcon, 'arrowIcon')}
                    >
                        <FontAwesomeIcon icon={globalIcons.arrowIcon} size="2x" />
                    </div>
                    <div
                        className="p-2 bg-gray-700 text-white rounded cursor-pointer flex justify-center"
                        onClick={() => handleIconChange(globalIcons.confirmIcon, 'confirmIcon')}
                    >
                        <FontAwesomeIcon icon={globalIcons.confirmIcon} size="2x" />
                    </div>
                    <div
                        className="p-2 bg-gray-700 text-white rounded cursor-pointer flex justify-center"
                        onClick={() => handleIconChange(globalIcons.rocketIcon, 'rocketIcon')}
                    >
                        <FontAwesomeIcon icon={globalIcons.rocketIcon} size="2x" />
                    </div>
                </div>
                <div className="flex justify-center">
                    <button
                        onClick={handleReset}
                        className="mt-10 mb-10 w-20 p-2 bg-red-600 hover:bg-red-700 text-white rounded"
                    >
                        Reset
                    </button>
                </div>

            </div>
            <button
                onClick={handleUpdateCustomization}
                className={`fixed right-20 bottom-4 py-2 px-4 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-semibold rounded-md shadow-lg transition-all duration-300 ease-in-out 
                    ${updateLoading ? 'opacity-50 cursor-not-allowed' : 'hover:from-pink-600 hover:to-purple-600 hover:scale-105'}`}
                disabled={updateLoading}
            >
                {updateLoading ? 'Saving...' : 'Finish Design'}
            </button>
        </div >
    );
};
export default Sidebar;