import React, { useState, useEffect } from 'react';
import documentationData from '../data/docs.json';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { getSecureCookie } from '../utilities/safeSaves';

const Documentation = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        const hash = window.location.hash;
        const sectionId = hash ? hash.substring(1) : 'introduction';
        const sectionIndex = documentationData.documentation.findIndex(section => section.id === sectionId);
        if (sectionIndex !== -1) {
            setActiveIndex(sectionIndex);
            document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
        } else {
            const introIndex = documentationData.documentation.findIndex(section => section.id === 'introduction');
            if (introIndex !== -1) {
                setActiveIndex(introIndex);
                document.getElementById('introduction')?.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    const toggleSection = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const handleLinkClick = (id) => {
        const index = documentationData.documentation.findIndex(section => section.id === id);
        setActiveIndex(index);
        document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const renderContent = (content) => {
        return content.map((contentItem) => {
            // Replace in the main text
            let processedText = contentItem.text.replace(
                /{getSecureCookie\(uid\)}/g,
                getSecureCookie('uid') ? getSecureCookie('uid') : 'your uid'
            );

            // If the contentItem has steps, replace in each step's text
            if (contentItem.steps) {
                const processedSteps = contentItem.steps.map((step) => {
                    const processedStepText = step.text.replace(
                        /{getSecureCookie\(uid\)}/g,
                        getSecureCookie('uid') ? getSecureCookie('uid') : 'your uid'
                    );
                    return { ...step, text: processedStepText };
                });
                // Update steps in the content item
                return { ...contentItem, text: processedText, steps: processedSteps };
            }

            // If there are no steps, return contentItem with updated text
            return { ...contentItem, text: processedText };
        });
    };

    return (
        <div className="flex flex-col md:flex-row container mx-auto mt-16 mb-2">
            <div className="md:w-full mt-6 md:mt-0 md:ml-6">
                <h1 className="text-4xl font-extrabold text-white mb-6 mt-4 text-center">Documentation</h1>

                {documentationData.documentation.map((section, index) => {
                    const processedContent = renderContent(section.content);

                    return (
                        <div
                            key={section.id}
                            id={section.id}
                            className="mb-4 rounded-lg shadow-md overflow-hidden border border-gray-200"
                        >
                            <button
                                onClick={() => toggleSection(index)}
                                className="w-full flex items-center justify-between bg-gray-100 px-5 py-4 font-semibold text-lg text-gray-800 hover:bg-gray-200 transition duration-200"
                            >
                                <span dangerouslySetInnerHTML={{ __html: section.title }} />
                                {activeIndex === index ? (
                                    <FaChevronUp className="text-gray-500" />
                                ) : (
                                    <FaChevronDown className="text-gray-500" />
                                )}
                            </button>
                            <div
                                className={`transition-all duration-300 ease-in-out ${activeIndex === index ? 'h-auto p-5' : 'max-h-0 overflow-hidden'} bg-gray-100/80`}
                            >
                                {processedContent && processedContent.length > 0 ? (
                                    processedContent.map((contentItem, i) => (
                                        <div key={i} className="mb-4">
                                            <h2 dangerouslySetInnerHTML={{ __html: contentItem.heading }} />
                                            <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: contentItem.text }} />
                                            {contentItem.steps && Array.isArray(contentItem.steps) && contentItem.steps.length > 0 && (
                                                <ul className="list-decimal ml-5">
                                                    {contentItem.steps.map((step, index) => (
                                                        <li key={index} className="mb-2">
                                                            <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: step.subheading }} />
                                                            <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: step.text }} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <p>No content available</p>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Documentation;
