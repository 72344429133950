// cookieUtils.jsx
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

// Replace with a strong secret key
const SECRET_KEY = 'your-secret-key';

// Function to encrypt data
const encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
};

// Function to decrypt data
const decryptData = (cipherText) => {
    try {
        const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
        const originalData = bytes.toString(CryptoJS.enc.Utf8);
        if (!originalData) {
            throw new Error('Decryption failed. No original data returned.');
        }
        return originalData;
    } catch (error) {
        console.error('Error decrypting data:', error);
        return null; // Return null or handle error as needed
    }
};

// Function to set secure cookies with key and value as parameters
export const setSecureCookie = (key, value, options = {}) => {
    const encryptedValue = encryptData(value);
    Cookies.set(key, encryptedValue, { ...options, secure: true, sameSite: 'Strict' });
};

// Function to get secure cookies with key as parameter
export const getSecureCookie = (key) => {
    const encryptedValue = Cookies.get(key);
    if (encryptedValue) {
        return decryptData(encryptedValue);
    }
    return null;
};

// Function to remove a cookie using key
export const removeSecureCookie = (key) => {
    Cookies.remove(key);
};

// Function to get all cookies (names)
export const getAllCookies = () => {
    return Cookies.get(); // This will return an object with all cookies
};

// Function to remove all cookies
export const removeAllCookies = () => {
    const allCookies = getAllCookies();
    for (const cookie in allCookies) {
        removeSecureCookie(cookie); // Remove each cookie by name
    }
};