import React from 'react';

const StepIndicator = ({ currentStep }) => {
    return (
        <div className="text-center mb-6">
            <h2 className="text-xl text-white font-semibold mb-2">
                Step {currentStep} of 5
            </h2>
            <div className="flex justify-center mt-2 space-x-2">
                {/* Step Circles */}
                {[1, 2, 3, 4, 5].map((step) => (
                    <div key={step} className="flex items-center">
                        <div
                            className={`w-8 h-8 rounded-full flex items-center justify-center 
                                ${currentStep >= step ? 'bg-gradient-to-r from-pink-400 to-pink-700 text-white border-2 border-pink-200' : 'bg-gray-300 text-gray-500'} 
                                transition-all duration-300 ease-in-out`}
                        >
                            {step}
                        </div>
                        {step < 5 && (
                            <div className={`w-12 h-1 ${currentStep > step ? 'bg-gradient-to-r from-pink-400 to-pink-700' : 'bg-gray-300'} transition-all duration-300 ease-in-out`} />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StepIndicator;
