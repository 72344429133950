// src/firebase.js
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { initializeApp } from 'firebase/app';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBuIh0HWsRHaF4JQSw3azQUE96VFQEy8Mk",
    authDomain: "chatbotmaker-68f01.firebaseapp.com",
    databaseURL: "https://chatbotmaker-68f01-default-rtdb.firebaseio.com",
    projectId: "chatbotmaker-68f01",
    storageBucket: "chatbotmaker-68f01.appspot.com",
    messagingSenderId: "283871041518",
    appId: "1:283871041518:web:1dab6e0b490e63e068e0ff",
    measurementId: "G-4J17PLMG4H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
