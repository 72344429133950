import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getSecureCookie } from '../utilities/safeSaves'; // Adjust path as needed

const DeploymentInstructions = () => {
    const { chatbotId } = useParams();
    const uid = getSecureCookie('uid');

    // Assign default values if chatbotId or uid are null
    const effectiveChatbotId = chatbotId || '$chatbotId'; // Use '$chatbotId' if null
    const effectiveUid = uid || '$uid'; // Use '$uid' if null

    // Retrieve button colors from navigation state
    const location = useLocation();
    const buttonBackgroundColor = location.state?.buttonBackgroundColor || 'purple'; // Default to purple
    const buttonTextColor = location.state?.buttonTextColor || 'white'; // Default to white

    const reactCodeSnippet = `
import React, { useState } from 'react';

const ChattingBot = () => {
    const [isVisible, setIsVisible] = useState(false);
    
    const toggleChatbot = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div>
            {/* Chatbot iframe */}
            <iframe
                id="chatbotIframe"
                src="https://chatbot.botanion.cc/chatbot/${effectiveChatbotId}?uid=${effectiveUid}&apiToken=YOUR_API_TOKEN&chatbotId=${effectiveChatbotId}"
                sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
                style={{
                    display: isVisible ? 'block' : 'none',
                    position: 'fixed',
                    bottom: '0',
                    right: '20px',
                    border: 'none',
                    zIndex: '1000',
                    width: '900px',
                    height: '700px'
                }}
                title="Chatbot"
            />
            {/* Toggle button */}
            <button
                id="toggleButton"
                onClick={toggleChatbot}
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    zIndex: '1001',
                    width: '50px',
                    height: '50px',
                    backgroundColor: '${buttonBackgroundColor}',
                    color: '${buttonTextColor}',
                    border: 'none',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <i className="fas fa-comments"></i>
            </button>
        </div>
    );
};

export default ChattingBot;
    `;

    const htmlCodeSnippet = `
<iframe id="chatbotIframe"
    src="https://chatbot.botanion.cc/chatbot/${effectiveChatbotId}?uid=${effectiveUid}&apiToken=YOUR_API_TOKEN&chatbotId=${effectiveChatbotId}"
    sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
    style="display: none; position: fixed; bottom: 0; right: 20px; border: none; z-index: 1000; width: 900px; height: 700px;">
</iframe>

<button id="toggleButton"
    style="position: fixed; bottom: 20px; right: 20px; z-index: 1001; width: 50px; height: 50px; background-color: ${buttonBackgroundColor}; color: ${buttonTextColor}; border: none; border-radius: 50%; cursor: pointer; display: flex; align-items: center; justify-content: center;">
    <i class="fas fa-comments"></i>
</button>

<script>
    const toggleButton = document.getElementById('toggleButton');
    const chatbotIframe = document.getElementById('chatbotIframe');

    toggleButton.addEventListener('click', function () {
        chatbotIframe.style.display = chatbotIframe.style.display === 'none' ? 'block' : 'none';
    });
</script>
    `;

    const vueCodeSnippet = `
<template>
  <div>
    <iframe
      v-show="isVisible"
      id="chatbotIframe"
      :src="'https://chatbot.botanion.cc/chatbot/${effectiveChatbotId}?uid=${effectiveUid}&apiToken=YOUR_API_TOKEN&chatbotId=${effectiveChatbotId}'"
      sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
      style="position: fixed; bottom: 0; right: 20px; border: none; z-index: 1000; width: 900px; height: 700px;"
    />
    <button
      @click="toggleChatbot"
      id="toggleButton"
      :style="{ backgroundColor: '${buttonBackgroundColor}', color: '${buttonTextColor}' }"
      style="position: fixed; bottom: 20px; right: 20px; z-index: 1001; width: 50px; height: 50px; border: none; border-radius: 50%; cursor: pointer; display: flex; align-items: center; justify-content: center;"
    >
      <i class="fas fa-comments"></i>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    toggleChatbot() {
      this.isVisible = !this.isVisible;
    }
  }
};
</script>
    `;

    const angularCodeSnippet = `
<!-- app.component.html -->
<iframe
  id="chatbotIframe"
  [src]="sanitizer.bypassSecurityTrustResourceUrl('https://chatbot.botanion.cc/chatbot/${effectiveChatbotId}?uid=${effectiveUid}&apiToken=YOUR_API_TOKEN&chatbotId=${effectiveChatbotId}')"
  sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
  style="display: none; position: fixed; bottom: 0; right: 20px; border: none; z-index: 1000; width: 900px; height: 700px;">
</iframe>

<button id="toggleButton"
  (click)="toggleChatbot()"
  style="position: fixed; bottom: 20px; right: 20px; z-index: 1001; width: 50px; height: 50px; background-color: ${buttonBackgroundColor}; color: ${buttonTextColor}; border: none; border-radius: 50%; cursor: pointer; display: flex; align-items: center; justify-content: center;">
  <i class="fas fa-comments"></i>
</button>`;

    const angularCodeSnippetTwo = `<!-- app.component.ts -->
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isVisible = false;

  constructor(public sanitizer: DomSanitizer) {}

  toggleChatbot() {
    const chatbotIframe = document.getElementById('chatbotIframe');
    chatbotIframe.style.display = this.isVisible ? 'none' : 'block';
    this.isVisible = !this.isVisible;
  }
}
    `;

    const wordpressCodeSnippet = `
function chatbot_shortcode() {
    ob_start();
    ?>
    <!-- Chatbot iframe with initial display set to 'none' -->
    <iframe id="chatbotIframe"
        src="https://chatbot.botanion.cc/chatbot/<?php echo $chatbotId; ?>?uid=<?php echo $uid; ?>&apiToken=YOUR_API_TOKEN&chatbotId=<?php echo $chatbotId; ?>"
        sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
        style="display: none; position: fixed; bottom: 0; right: 20px; border: none; z-index: 1000; width: 900px; height: 700px;">
    </iframe>

    <!-- Toggle button -->
    <button id="toggleButton"
        style="position: fixed; bottom: 20px; right: 20px; z-index: 1001; width: 50px; height: 50px; background-color: <?php echo $buttonBackgroundColor; ?>; color: <?php echo $buttonTextColor; ?>; border: none; border-radius: 50%; cursor: pointer; display: flex; align-items: center; justify-content: center;">
        <i class="fas fa-comments"></i>
    </button>

    <script>
        document.getElementById('toggleButton').addEventListener('click', function () {
            const chatbotIframe = document.getElementById('chatbotIframe');
            chatbotIframe.style.display = chatbotIframe.style.display === 'none' ? 'block' : 'none';
        });
    </script>
    <?php
    return ob_get_clean();
}
add_shortcode('chatbot', 'chatbot_shortcode');
`;

    const wordpressCodeSnippetTwo = `
<!-- Chatbot iframe with initial display set to 'none' -->
<iframe id="chatbotIframe"
    src="https://chatbot.botanion.cc/chatbot/<?php echo $chatbotId; ?>?uid=<?php echo $uid; ?>&apiToken=YOUR_API_TOKEN&chatbotId=<?php echo $chatbotId; ?>"
    sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
    style="display: none; position: fixed; bottom: 0; right: 20px; border: none; z-index: 1000; width: 900px; height: 700px;">
</iframe>

<!-- Toggle button -->
<button id="toggleButton"
    style="position: fixed; bottom: 20px; right: 20px; z-index: 1001; width: 50px; height: 50px; background-color: <?php echo $buttonBackgroundColor; ?>; color: <?php echo $buttonTextColor; ?>; border: none; border-radius: 50%; cursor: pointer; display: flex; align-items: center; justify-content: center;">
    <i class="fas fa-comments"></i>
</button>

<script>
    document.getElementById('toggleButton').addEventListener('click', function () {
        const chatbotIframe = document.getElementById('chatbotIframe');
        chatbotIframe.style.display = chatbotIframe.style.display === 'none' ? 'block' : 'none';
    });
</script>
`;



    const [copySuccess, setCopySuccess] = useState('');

    const handleCopyCode = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            setCopySuccess('Code copied to clipboard!');
            setTimeout(() => setCopySuccess(''), 3000);
        });
    };

    return (
        <div className="container mx-auto p-6 rounded-lg shadow-md">
            <div className='mt-20'>
                <h1 className="uppercase text-3xl md:text-5xl font-bold text-white text-center mb-6"
                    style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Chatbot Deployment Instructions</h1>

                <p className="mb-6 text-white text-lg text-center">
                    Follow these steps to integrate your chatbot on your website by embedding the code snippet provided.
                </p>



                {/* Step-by-Step Deployment Section */}
                <div className="mb-8 p-6 bg-gray-800/10 rounded-lg border border-gray-600 shadow-lg">
                    <h2 className="text-2xl font-semibold text-white mb-4">Step-by-Step Deployment</h2>
                    <p className="text-white mb-4">
                        Follow these steps to seamlessly integrate the chatbot code snippet into your website. These instructions can be adapted for various frameworks and programming languages.
                    </p>

                    {/* HTML/JavaScript Section */}
                    <div className="mb-8 p-6 bg-gray-800/10 rounded-lg border border-gray-600 shadow-lg">
                        <h2 className="text-2xl font-semibold text-white mb-4">HTML/JavaScript</h2>
                        <p className="text-white mb-4">
                            To integrate the chatbot into your website using HTML/JavaScript, please follow these steps:
                        </p>
                        <ol className="list-decimal list-inside text-white mb-4">
                            <li className="mb-2">Locate the HTML file of your website where you would like the chatbot to appear.</li>
                            <li className="mb-2">Find the closing <code className="whitespace-pre-wrap language-javascript">&lt;/body&gt;</code> tag in your HTML file.</li>
                            <li className="mb-2">Paste the following code snippet just before the <code className="whitespace-pre-wrap language-javascript">&lt;/body&gt;</code> tag:</li>
                            <div className="mb-6 p-6 bg-gray-700 rounded-lg relative">
                                <button
                                    onClick={() => handleCopyCode(htmlCodeSnippet)}
                                    className="absolute top-1 right-3 text-white py-1 px-3 rounded-lg transition duration-200 hover:bg-purple-600"
                                >
                                    {copySuccess ? 'Copied' : 'Copy Code'}
                                </button>
                                <pre className="whitespace-pre-wrap text-white language-javascript">{htmlCodeSnippet}</pre>
                            </div>
                            <li className="mb-2">
                                In the code snippet, make sure to replace <code>YOUR_API_TOKEN</code> with your actual API token. You can learn how to generate your API token <a href="https://www.botanion.cc/docs#generate-api-key" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">here</a>.
                            </li>
                            <li className="mb-2">After pasting the code, save the changes to your HTML file.</li>
                            <li className="mb-2">Open your website in a web browser.</li>
                            <li className="mb-2">Check that the chatbot is displayed correctly on the page.</li>
                        </ol>
                    </div>



                    {/* React Code */}
                    <div className="mb-8 p-6 bg-gray-800/10 rounded-lg border border-gray-600 shadow-lg">
                        <h2 className="text-2xl font-semibold text-white mb-4">React</h2>
                        <p className="text-white mb-4">
                            To integrate the chatbot into your React application, please follow these steps:
                        </p>
                        <ol className="list-decimal list-inside text-white mb-4">
                            <li className="mb-2">Open the React component file where you want to add the chatbot.</li>
                            <li className="mb-2">Import any necessary dependencies at the top of your component file, if needed.</li>
                            <li className="mb-2">Paste the following code snippet in the component's render method or return statement:</li>
                            <div className="mb-6 p-6 bg-gray-700 rounded-lg relative">
                                <button
                                    onClick={() => handleCopyCode(reactCodeSnippet)}
                                    className="absolute top-1 right-3 text-white py-1 px-3 rounded-lg transition duration-200 hover:bg-purple-600"
                                >
                                    {copySuccess ? 'Copied' : 'Copy Code'}
                                </button>
                                <pre className="whitespace-pre-wrap text-white language-javascript">{reactCodeSnippet}</pre>
                            </div>
                            <li className="mb-2">
                                In the code snippet, make sure to replace <code>YOUR_API_TOKEN</code> with your actual API token. You can learn how to generate your API token <a href="https://www.botanion.cc/docs#generate-api-key" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">here</a>.
                            </li>
                            <li className="mb-2">Save your changes to the component file.</li>
                            <li className="mb-2">Start or refresh your React application to see the chatbot in action.</li>
                            <li className="mb-2">To use the chatbot component in your application, add it to the JSX of your parent component like this:</li>
                            <div className="mb-6 p-6 bg-gray-700 rounded-lg relative">
                                <button
                                    onClick={() => handleCopyCode(reactCodeSnippet)}
                                    className="absolute top-1 right-3 text-white py-1 px-3 rounded-lg transition duration-200 hover:bg-purple-600"
                                >
                                    {copySuccess ? 'Copied' : 'Copy Code'}
                                </button>
                                <pre className="whitespace-pre-wrap text-white language-javascript">{'<Chatbot />'}</pre>
                            </div>
                            <li className="mb-2">Ensure that you have defined the `&lt;Chatbot/&gt;` component in the same file or imported it from another file where it is defined.</li>
                        </ol>
                    </div>



                    {/* Angular Section */}
                    <div className="mb-8 p-6 bg-gray-800/10 rounded-lg border border-gray-600 shadow-lg">
                        <h2 className="text-2xl font-semibold text-white mb-4">Angular</h2>
                        <p className="text-white mb-4">
                            To integrate the chatbot into your Angular application, please follow these steps:
                        </p>
                        <ol className="list-decimal list-inside text-white mb-4">
                            <li className="mb-2">Open the Angular component file where you want to add the chatbot. This is typically <code>app.component.ts</code> and <code>app.component.html</code>.</li>

                            <li className="mb-2">In your component's TypeScript file (e.g., <code>app.component.ts</code>), import the necessary Angular modules:</li>
                            <div className="mb-6 p-6 bg-gray-700 rounded-lg relative">
                                <button
                                    onClick={() => handleCopyCode(angularCodeSnippetTwo)}
                                    className="absolute top-1 right-3 text-white py-1 px-3 rounded-lg transition duration-200 hover:bg-purple-600"
                                >
                                    {copySuccess ? 'Copied' : 'Copy Code'}
                                </button>
                                <pre className="whitespace-pre-wrap text-white language-javascript">{angularCodeSnippetTwo}</pre>
                            </div>

                            <li className="mb-2">In your component's HTML file (e.g., <code>app.component.html</code>), add the following code to create the chatbot iframe and toggle button:</li>
                            <div className="mb-6 p-6 bg-gray-700 rounded-lg relative">
                                <button
                                    onClick={() => handleCopyCode(angularCodeSnippet)}
                                    className="absolute top-1 right-3 text-white py-1 px-3 rounded-lg transition duration-200 hover:bg-purple-600"
                                >
                                    {copySuccess ? 'Copied' : 'Copy Code'}
                                </button>
                                <pre className="whitespace-pre-wrap text-white language-javascript">{angularCodeSnippet}</pre>
                            </div>
                            <li className="mb-2">
                                In the code snippet, make sure to replace <code>YOUR_API_TOKEN</code> with your actual API token. You can learn how to generate your API token <a href="https://www.botanion.cc/docs#generate-api-key" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">here</a>.
                            </li>
                            <li className="mb-2">Save your changes to both the TypeScript and HTML files.</li>
                            <li className="mb-2">Run or refresh your Angular application to see the chatbot in action.</li>
                            <li className="mb-2">Ensure that you have included any necessary styles and scripts for the chatbot in your main <code>index.html</code> file, if required.</li>
                            <li className="mb-2">You can further customize the chatbot integration by modifying the iframe's properties or the toggle button's appearance as per your application's needs.</li>
                        </ol>
                    </div>


                    {/* Vue Section */}
                    <div className="mb-8 p-6 bg-gray-800/10 rounded-lg border border-gray-600 shadow-lg">
                        <h2 className="text-2xl font-semibold text-white mb-4">Vue</h2>
                        <p className="text-white mb-4">
                            To integrate the chatbot into your Vue application, follow these steps:
                        </p>
                        <ol className="list-decimal list-inside text-white mb-4">
                            <li className="mb-2">Open the Vue component file where you want to add the chatbot, typically <code>YourComponent.vue</code>.</li>

                            <li className="mb-2">Copy and paste the following code snippet into your component:</li>
                            <div className="mb-6 p-6 bg-gray-700 rounded-lg relative">
                                <button
                                    onClick={() => handleCopyCode(vueCodeSnippet)}
                                    className="absolute top-1 right-3 text-white py-1 px-3 rounded-lg transition duration-200 hover:bg-purple-600"
                                >
                                    {copySuccess ? 'Copied' : 'Copy Code'}
                                </button>
                                <pre className="whitespace-pre-wrap text-white language-javascript">{vueCodeSnippet}</pre>
                            </div>

                            <li className="mb-2">
                                In the code snippet, make sure to replace <code>YOUR_API_TOKEN</code> with your actual API token. You can learn how to generate your API token <a href="https://www.botanion.cc/docs#generate-api-key" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">here</a>.
                            </li>
                            <li className="mb-2">Save your changes to the component file.</li>
                            <li className="mb-2">Run or refresh your Vue application to see the chatbot in action.</li>
                            <li className="mb-2">If needed, customize the chatbot's appearance by adjusting the button styles and iframe dimensions in the code.</li>
                        </ol>
                    </div>


                    <h3 className="text-xl font-semibold text-white mt-4">Custom Placement Options</h3>
                    <p className="text-white mb-2">
                        Developers can also choose to add the chatbot to specific areas of their site by modifying the placement of the iframe:
                    </p>
                    <ul className="list-disc list-inside text-white mb-4">
                        <li><strong>In a Sidebar:</strong> Embed the chatbot iframe in a sidebar component to allow constant access across all pages.</li>
                        <li><strong>As a Modal:</strong> Trigger the chatbot as a modal window when users click a specific button or link.</li>
                        <li><strong>On Certain Pages:</strong> Include the snippet only in specific components or templates where interaction is desired.</li>
                        <li><strong>With CSS Animations:</strong> Use CSS animations to bring the chatbot into view, enhancing the user experience.</li>
                    </ul>
                    <p className="text-white mb-4">
                        Tailor the integration to fit your application's specific needs and enhance user engagement with your chatbot.
                    </p>
                </div>


                {/* Platform-Specific Instructions Section */}
                <div className="mb-8 p-6 bg-gray-800/10 rounded-lg border border-gray-600 shadow-lg">
                    <h2 className="text-2xl font-semibold text-white mb-4">Platform-Specific Instructions</h2>

                    {/* WordPress Integration Section */}
                    <h3 className="font-bold text-white mt-2">WordPress Integration</h3>
                    <p className="text-white mb-4">Follow these steps to add the chatbot to your WordPress site:</p>
                    <ol className="list-decimal list-inside text-white mb-4">
                        <li className="mb-2">Navigate to <strong>Appearance &gt; Theme Editor</strong> in your WordPress dashboard.</li>
                        <li className="mb-2">Open the <strong>footer.php</strong> file and paste the shortcode snippet before the closing <strong>&lt;/body&gt;</strong> tag:</li>
                        <div className="mb-6 p-6 bg-gray-700 rounded-lg relative">
                            <button
                                onClick={() => handleCopyCode(wordpressCodeSnippet)}
                                className="absolute top-1 right-3 text-white py-1 px-3 rounded-lg transition duration-200 hover:bg-purple-600"
                            >
                                {copySuccess ? 'Copied' : 'Copy Code'}
                            </button>
                            <pre className="whitespace-pre-wrap text-white language-javascript">{wordpressCodeSnippet}</pre>
                        </div>
                        <li className="mb-2">
                            In the code snippet, make sure to replace <code>YOUR_API_TOKEN</code> with your actual API token. You can learn how to generate your API token <a href="https://www.botanion.cc/docs#generate-api-key" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">here</a>.
                        </li>
                        <li className="mb-2">Alternatively, use the <strong>Insert Headers and Footers</strong> plugin to add the code to your site.</li>
                        <li className="mb-2">Save your changes and refresh your site to see the chatbot in action.</li>
                    </ol>

                    {/* Other CMS Platforms Section */}
                    <h3 className="font-bold text-white mt-4">Other CMS Platforms</h3>
                    <p className="text-white mb-4">
                        Ensure your CMS allows custom HTML/JavaScript additions, and insert the code snippet before the <strong>&lt;/body&gt;</strong> tag to display the chatbot correctly.
                    </p>
                </div>


                {/* Customizing the Toggle Button Section */}
                <div className="mb-8 p-6 bg-gray-800/10 rounded-lg border border-gray-600 shadow-lg">
                    <h2 className="text-2xl font-semibold text-white mb-4">Customizing the Toggle Button</h2>
                    <p className="text-white">
                        You can personalize the toggle button by updating its background color, text color, or icon.
                        Use state variables in your navigation route to pass custom <code className="whitespace-pre-wrap language-javascript">buttonBackgroundColor </code> ,
                        <code className="whitespace-pre-wrap language-javascript">buttonTextColor</code>, and <code className="whitespace-pre-wrap language-javascript"> toggleIconClass</code> (any FontAwesome icon class).
                    </p>
                    <p className="text-white">
                        Example icon classes:
                        <ul className="list-disc list-inside text-white">
                            <li><code className="whitespace-pre-wrap language-javascript">fas fa-comments</code> (Default icon)</li>
                            <li><code className="whitespace-pre-wrap language-javascript">fas fa-robot</code> (Robot icon)</li>
                            <li><code className="whitespace-pre-wrap language-javascript">fas fa-message</code> (Message icon)</li>
                        </ul>
                    </p>
                </div>

                {/* Customizing Chatbot Iframe Styles Section */}
                <div className="mb-8 p-6 bg-gray-800/10 rounded-lg border border-gray-600 shadow-lg">
                    <h2 className="text-2xl font-semibold text-white mb-4">Customizing Chatbot Iframe Styles</h2>
                    <p className="text-white">
                        Adjust the iframe style directly within the snippet to control its size, positioning, and appearance.
                    </p>
                    <ul className="list-disc list-inside text-white mb-6">
                        <li><strong>Width and Height:</strong> Modify <code className="whitespace-pre-wrap language-javascript">width</code> and <code className="whitespace-pre-wrap language-javascript">height</code> values in the <code className="whitespace-pre-wrap language-javascript">style</code> attribute to control the iframe’s dimensions.</li>
                        <li><strong>Positioning:</strong> Use <code className="whitespace-pre-wrap language-javascript">bottom</code>, <code className="whitespace-pre-wrap language-javascript">right</code>, or other positioning properties to adjust placement on the page.</li>
                        <li><strong>Border:</strong> Add <code className="whitespace-pre-wrap language-javascript">border</code> properties (e.g., <code className="whitespace-pre-wrap language-javascript">border: 1px solid #ddd;</code>) to give the iframe a visible border if desired.</li>
                        <li><strong>Visibility on Load:</strong> Set <code className="whitespace-pre-wrap language-javascript">display</code> to <code className="whitespace-pre-wrap language-javascript">block</code> if you want the iframe to appear by default on page load.</li>
                    </ul>
                </div>

                {/* Testing and Troubleshooting Section */}
                <div className="mb-8 p-6 bg-gray-800/10 rounded-lg border border-gray-600 shadow-lg">
                    <h2 className="text-2xl font-semibold text-white mb-4">Testing and Troubleshooting</h2>
                    <ul className="list-disc list-inside text-white mb-6">
                        <li className="mb-2">
                            <strong>Test in Multiple Browsers:</strong> Check chatbot appearance and functionality in different browsers (Chrome, Firefox, Safari).
                        </li>
                        <li className="mb-2">
                            <strong>Mobile Testing:</strong> Ensure the iframe displays properly on mobile devices.
                        </li>
                        <li className="mb-2">
                            <strong>Token Validation:</strong> Ensure the API token is correct to avoid authentication errors.
                        </li>
                        <li className="mb-2">
                            <strong>Inspect Console for Errors:</strong> Open the browser console to view any JavaScript errors and troubleshoot if needed.
                        </li>
                    </ul>
                </div>

                {/* FAQ and Support Section */}
                <div className="mb-8 p-6 bg-gray-800/10 rounded-lg border border-gray-600 shadow-lg">
                    <h2 className="text-2xl font-semibold text-white mb-4">FAQ and Support</h2>
                    <p className="text-white">
                        For common issues, please refer to our <strong>FAQs</strong> or <strong>Contact Support</strong> for assistance.
                    </p>
                    <p className="mt-6 text-sm text-white text-center">
                        For further support, contact our team at <a href="mailto:support@botanion.cc" className="text-purple-300 underline">support@botanion.cc</a>.
                    </p>
                </div>
            </div>
        </div >
    );

}

export default DeploymentInstructions;
