import React, { useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import Cookies from 'js-cookie';
import axios from 'axios';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { setSecureCookie } from '../utilities/safeSaves';
import { Link, useLocation } from 'react-router-dom';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const handleGoogleAuth = async () => {
        try {
            setLoading(true);
            // Trigger Google Sign-in Popup
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            // Extract user information
            const email = user.email;
            const username = user.displayName;
            const userId = user.uid;
            const emailVerified = user.emailVerified;

            console.log('User Info:', { email, username, userId, emailVerified });

            // Send email and username to backend token generator API
            const response = await axios.post('https://api-bny5h3g2lq-uc.a.run.app/api/google-signin', {
                email,
                name: username,
                uid: userId,
                emailVerified: emailVerified
            });

            const { token, name, plan, message } = response.data;
            console.log(message + " " + response.status + " " + userId)

            if (response.status === 201 && message === 'User profile completion required.') {
                // Navigate to profile completion page with email and username
                navigate('/complete-registration', {
                    state: {
                        email, name: username, userId: userId, emailVerified
                    }
                });
                console.log('Navigating with state:', {
                    email, name: username, userId: userId, emailVerified
                });

                setLoading(false);
            } else if (response.status === 200) {
                // Handle successful login (user already completed profile)
                handleLoginSuccess(token, name, userId, plan);
            }

        } catch (error) {
            console.error('Google authentication failed:', error);
            setError('Failed to authenticate with Google.');
            setLoading(false);
        }
        finally {
            setEmail('');
            setPassword('')
            setLoading(false)
        }
    };

    const handleLoginSuccess = (token, name, uid, plan) => {
        setSecureCookie('accessToken', token, { expires: 7, secure: true });
        setSecureCookie('uid', uid, { expires: 7, secure: true });
        setSecureCookie('username', name, { expires: 7, secure: true });
        setSecureCookie('plan', plan, { expires: 7, secure: true });

        setLoading(false);
        window.location.replace(location.pathname);
    };



    // Function to apply active styling based on current path
    const getActiveClass = (path) =>
        location.pathname === path ? "active" : "";

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(''); // Clear any previous errors

        try {
            const response = await axios.post('https://api-bny5h3g2lq-uc.a.run.app/api/signin', {
                email,
                password,
            });

            // If login is successful
            if (response.status === 200) {
                const { token, uid, name, plan } = response.data;
                setSecureCookie('accessToken', token, { expires: 7, secure: true });
                setSecureCookie('uid', uid, { expires: 7, secure: true });
                setSecureCookie('username', name, { expires: 7, secure: true });
                setSecureCookie('plan', plan, { expires: 7, secure: true });

                setLoading(false);

                window.location.replace(location.pathname);
            }
            else {
                console.log(response.status);
            }

        } catch (error) {
            console.error('Login error:', error);

            // Handle errors from the API
            if (error.response && error.response.data) {
                setError('Invalid login credentials');
            } else {
                setError('Something went wrong. Please try again.');
            }
        } finally {
            setEmail('');
            setPassword('')
            setLoading(false)
        }
    };

    return (
        <div className="flex items-center justify-center w-full min-h-screen">
            <div className="bg-white/10 shadow-xl rounded-3xl overflow-hidden w-96 p-6 mt-20 mb-10">
                <div className="flex flex-col justify-center items-center">
                    <img
                        src="https://res.cloudinary.com/dbe2fast6/image/upload/v1729357377/logo-botanion-01_us1eyb.svg"
                        alt="logo"
                        className="w-12 h-12 mb-2"
                    />
                    <h2 className="text-3xl font-bold text-center text-purple-300 mb-2">
                        Welcome Back!
                    </h2>
                    <p className="text-center text-gray-200 mb-4">
                        Innovating the Future,<br /> Build Once, Use Anywhere
                    </p>

                    <form onSubmit={handleSubmit} className="w-full">
                        {error && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4 text-center">
                                <span>{error}</span>
                            </div>
                        )}
                        <div className="mb-4">
                            <label className="block text-gray-200 font-medium">Email</label>
                            <input
                                type="email"
                                className="w-full p-3 border rounded-2xl border-gray-300 bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-600"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    if (error) setError('');
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-200 font-medium">Password</label>
                            <input
                                type="password"
                                className="w-full p-3 border rounded-2xl border-gray-300 bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-600"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    if (error) setError('');
                                }}
                            />
                        </div>

                        <div className="sm:flex justify-between items-center mb-4">
                            <label className="flex items-center text-white">
                                <input type="checkbox" className="mr-2" />
                                Remember me
                            </label>
                            <a href="/requestResetPassword" className="text-purple-400 text-sm">
                                Forgot Password?
                            </a>
                        </div>

                        <button
                            type="submit"
                            disabled={loading}
                            className={`w-full bg-purple-600 text-white py-3 rounded-lg font-semibold transition duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-700'
                                }`}
                        >
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                    </form>

                    <div className="flex items-center justify-center mt-2">
                        <div className="border-t w-1/3"></div>
                        <span className="mx-2 text-gray-400">or</span>
                        <div className="border-t w-1/3"></div>
                    </div>

                    <button className="w-full mt-2 bg-white text-black border py-3 rounded-lg flex items-center justify-center gap-2 hover:bg-gray-100"
                        onClick={handleGoogleAuth}
                    >
                        <FcGoogle size={24} />
                        Login with Google
                    </button>

                    <p className="text-center mt-6 text-gray-200">
                        Don't have an account?{' '}
                        <a href="/register" className="text-purple-400 font-bold">
                            Sign up
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
