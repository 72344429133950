

import React, { useEffect, useRef, useState } from 'react';

const Carousel = () => {
    const scrollContainer = useRef(null);
    const [scrolling, setScrolling] = useState(true);

    const images = [
        {
            title: 'Customizable',
            description: 'Effortlessly design bots tailored to your business needs, with complete control over responses and behavior.',
            image: 'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731297760/standard_wjr9pm.gif',
        },
        {
            title: 'AI-Powered',
            description: 'Utilize AI to enhance user interactions and provide intelligent, context-aware responses.',
            image: 'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731297760/curved_smpxzk.gif',
        },
        {
            title: 'Easy Integration',
            description: 'Quickly embed your chatbot into any website or application without the need for coding skills.',
            image: 'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731297762/wave_dypuaj.gif',
        },
        {
            title: 'Cloud-Based',
            description: 'Everything runs in the cloud, ensuring smooth performance and easy access whenever you need it.',
            image: 'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731297840/elegant_jtndzo.gif',
        },
        {
            title: 'Cloud-Based',
            description: 'Everything runs in the cloud, ensuring smooth performance and easy access whenever you need it.',
            image: 'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731297839/basic_usjugn.gif',
        }

    ];


    useEffect(() => {
        const scrollInterval = setInterval(() => {
            if (scrollContainer.current && scrolling) {
                scrollContainer.current.scrollLeft += 1; // Move the scroll position to the right

                // Reset scroll position when we reach the end
                const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.current;
                if (scrollLeft >= scrollWidth - clientWidth) {
                    scrollContainer.current.scrollLeft = 0; // Restart to the beginning
                }
            }
        }, 20); // Adjust speed by changing interval time

        return () => clearInterval(scrollInterval); // Clear the interval on unmount
    }, [scrolling]); // Runs whenever 'scrolling' state changes

    return (
        <div
            ref={scrollContainer}
            style={{
                display: 'flex',
                overflowX: 'hidden', // Hide overflow to avoid shivering
                scrollBehavior: 'smooth',
                whiteSpace: 'nowrap',
                width: '100%',
            }}
            onMouseEnter={() => setScrolling(false)} // Pause scrolling on hover
            onMouseLeave={() => setScrolling(true)} // Resume scrolling when not hovering
        >
            <div className="carousel-wrapper">
                <div className="carousel">
                    {images.concat(images).map((img, index) => ( // Duplicate for infinite scrolling effect
                        <img
                            key={index}
                            src={img.image}
                            alt={`Carousel item ${index}`}
                            className="carousel-image"
                        />
                    ))}
                </div>
            </div>

        </div>

    );

};

export default Carousel;