import { React, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'; // Import tooltip styles
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome styles
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { getSecureCookie } from '../utilities/safeSaves';
import Modal from './Modal';
import MarxIPGOrder from './PaymentComponent';

function PricingPage() {
    // Mapping of features to tooltip IDs
    const [orderDetails, setOrderDetails] = useState(null);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handlePayment = async (rechargeOption) => {
        try {
            // Fetch user profile to get the current plan
            const response = await fetch(`https://api-bny5h3g2lq-uc.a.run.app/api/userProfile/${getSecureCookie('uid')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getSecureCookie('accessToken')}`,
                },
            });

            const data = await response.json();
            const currentPlan = data.plan;  // Get the user's current plan level

            // Determine if the upgrade/downgrade action is allowed based on the current plan
            if (currentPlan === 'pro' && rechargeOption.plan === 'pro') {
                setErrorMessage("You are already on the Pro plan.");
                return;
            } else if (currentPlan === 'super' && rechargeOption.plan !== 'super') {
                setErrorMessage("You cannot downgrade from the Super plan.");
                return;
            } else if (currentPlan === 'super' && rechargeOption.plan === 'super') {
                setErrorMessage("You are already on the Super plan.");
                return;
            }

            // Proceed with plan change if no error
            setErrorMessage(""); // Clear any previous error
            // Add your plan change logic here


            // If the conditions are met, set the order details and proceed with the payment
            setOrderDetails({
                price: rechargeOption.price,
                summary: `You are going to buy ${rechargeOption.plan} for ${rechargeOption.price} dollars and receive ${rechargeOption.credits} credits.`,
                type: 'upgrade',  // Adjust based on plan
            });

            // Set the session storage to indicate the payment action
            sessionStorage.setItem('paymentAction', 'upgrade');

            // Open the payment modal
            setIsPaymentModalOpen(true);
        } catch (error) {
            console.error("Error fetching user profile:", error);
            alert("Failed to fetch user profile. Please try again.");
        }
    };


    const tooltips = {
        "Free Test Messages": "free-messages-tooltip",
        "Bots Creation": "bots-creation-tooltip",
        "Free Credits": "free-credits-tooltip",
        "Message Limits": "message-limit-tooltip",
        "Overage Charges": "overage-tooltip",
        "Recharge Options": null, // No tooltip
        "API Access": "api-access-tooltip",
        "Use URL to Train Bot": "use-url-to-train-bot-tooltip",
        "Priority Support": "priority-support-tooltip",
        "Custom Integrations": "custom-integrations-tooltip",
        "Cloud Storage": null // No tooltip
    };

    return (
        <div className="min-h-screen py-10 mt-10">
            <Helmet>
                <title>Pricing Plans | Botanion</title>
                <meta name="description" content="Explore our pricing plans and find the best fit for your chatbot needs. Flexible options available." />
                <meta name="keywords" content="chatbot pricing, chatbot plans, chatbot features, AI chatbot, Botanion pricing" />

                {/* Open Graph tags */}
                <meta property="og:title" content="Pricing Plans | Botanion" />
                <meta property="og:description" content="Explore our pricing plans and find the best fit for your chatbot needs. Flexible options available." />
                <meta property="og:image" content="https://res.cloudinary.com/dbe2fast6/image/upload/v1728982602/AiPowered_1_lvthbf.png" />
                <meta property="og:url" content="https://www.botanion.cc/pricing" />
                <meta property="og:type" content="website" />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Pricing Plans | Botanion" />
                <meta name="twitter:description" content="Explore our pricing plans and find the best fit for your chatbot needs. Flexible options available." />
                <meta name="twitter:image" content="https://res.cloudinary.com/dbe2fast6/image/upload/v1728982602/AiPowered_1_lvthbf.png" />
            </Helmet>

            <div className="container mx-auto px-6">
                <h1 className="uppercase text-5xl font-bold text-white text-center mb-6"
                    style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>
                    Pricing Plans
                </h1>

                <p className="text-lg text-gray-300 text-center mb-12">
                    Choose a plan that fits your needs and start building your chatbot today!
                </p>

                {/* Pricing Calculator Button */}
                <div className="mt-8">
                    <Link to="/pricing-calculator">
                        <button className="uppercase text-lg w-1/4 py-3 mb-6 bg-green-500 hover:bg-green-400 text-white rounded-lg font-semibold transition-colors">
                            Open Pricing Calculator
                        </button>
                    </Link>
                </div>

                {/* Responsive Pricing Table */}
                <div className="overflow-x-auto hidden md:block">
                    <table className="min-w-full bg-white/10 border border-gray-700 backdrop-blur-md rounded-lg shadow-lg">
                        <thead>
                            <tr className="bg-gray-800 text-white text-left">
                                <th className="p-4">Features</th>
                                <th className="p-4">Basic Plan<br /><span className="text-sm text-gray-400">Free, $7Recharge Required</span></th>
                                <th className="p-4">Pro Plan<br /><span className="text-sm text-gray-400">$24/month</span></th>
                                <th className="p-4">Super Plan<br /><span className="text-sm text-gray-400">$49/month</span></th>
                                <th className="p-4">Enterprise<br /><span className="text-sm text-gray-400">Contact Us</span></th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-300">
                            {[
                                {
                                    feature: "Free Test Messages",
                                    details: ["5 Messages", "5 Messages", "5 Messages", "5 Messages"]
                                },
                                {
                                    feature: "Bots Creation",
                                    details: ["1 Bot (No Credit Cost)", "3 Bots (No Credit Cost)", "5 Bots (No Credit Cost)", "Custom Limit (No Credit Cost)"]
                                },

                                {
                                    feature: "Free Credits",
                                    details: ["N/A", "100 Credits", "300 Credits", "Custom Credits"]
                                },
                                {
                                    feature: "Message Limits",
                                    details: ["150 / Month (3 credit/message)", "600 / Month (2 credit/message)", "1400 / Month (1 credit/message)", "Custom"]
                                },
                                {
                                    feature: "Overage Charges",
                                    details: ["4 credits/ Message", "3 credits/ Message", "2 credits/ Message", "Custom"]
                                },
                                {
                                    feature: "Recharge Options",
                                    details: [
                                        <ul className="list-disc list-inside">
                                            <li>$7for 150 credits</li>
                                            <li>$11 for 300 credits</li>
                                            <li>$21 for 680 credits</li>
                                        </ul>,
                                        <ul className="list-disc list-inside">
                                            <li>$7for 200 credits</li>
                                            <li>$11 for 400 credits</li>
                                            <li>$21 for 850 credits</li>
                                        </ul>,
                                        <ul className="list-disc list-inside">
                                            <li>$7for 250 credits</li>
                                            <li>$11 for 500 credits</li>
                                            <li>$21 for 1050 credits</li>
                                        </ul>,
                                        <span>Custom Plans</span>
                                    ]
                                },
                                {
                                    feature: "API Access",
                                    details: ["✔️", "✔️", "✔️", "✔️"]
                                },
                                {
                                    feature: "Use URL to Train Bot",
                                    details: [
                                        "✔️ (Recharge 299 credits+ for access)",
                                        "✔️",
                                        "✔️",
                                        "✔️"
                                    ]
                                },
                                {
                                    feature: "Priority Support",
                                    details: ["❌", "✔️", "✔️", "✔️"]
                                },
                                {
                                    feature: "Custom Integrations",
                                    details: ["✔️", "✔️", "✔️", "✔️"]
                                },
                                {
                                    feature: "Cloud Storage",
                                    details: ["✔️", "✔️", "✔️", "✔️"]
                                },
                            ].map((row, index) => (
                                <tr key={index} className="border-b border-gray-600 hover:bg-gray-700">
                                    <td className="p-4">
                                        {row.feature}
                                        {tooltips[row.feature] && (
                                            <i className="fas fa-question-circle ml-2 cursor-pointer" data-tooltip-id={tooltips[row.feature]}></i>
                                        )}
                                    </td>
                                    {row.details.map((detail, idx) => (
                                        <td className="p-4" key={idx}>{detail}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {
                    errorMessage && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                            {errorMessage}
                        </div>
                    )
                }

                {/* Add buttons to buy plans */}
                <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="bg-white/10 border border-gray-700 backdrop-blur-md p-4 rounded-lg shadow-lg">
                        <h3 className="text-xl font-bold text-white text-center mb-2">Basic Plan</h3>
                        <Link to={`/${getSecureCookie('uid')}/usage`} >
                            <button className="w-full py-2 bg-blue-500 text-white rounded-lg font-semibold mb-4 hover:bg-blue-400 transition-colors">
                                Recharge Now
                            </button>
                        </Link>
                    </div>

                    <div className="bg-white/10 border border-gray-700 backdrop-blur-md p-4 rounded-lg shadow-lg">
                        <h3 className="text-xl font-bold text-white text-center mb-2">Pro Plan</h3>

                        <button className="w-full py-2 bg-green-500 text-white rounded-lg font-semibold mb-4 hover:bg-green-400 transition-colors"
                            onClick={() => handlePayment({ price: 24, credits: 100, plan: 'Pro Plan' })}
                        >
                            Buy Pro
                        </button>

                    </div>

                    <div className="bg-white/10 border border-gray-700 backdrop-blur-md p-4 rounded-lg shadow-lg">
                        <h3 className="text-xl font-bold text-white text-center mb-2">Super Plan</h3>

                        <button className="w-full py-2 bg-green-500 text-white rounded-lg font-semibold mb-4 hover:bg-green-400 transition-colors"
                            onClick={() => handlePayment({ price: 49, credits: 300, plan: 'Super Plan' })}
                        >
                            Buy Super
                        </button>

                    </div>

                    <div className="bg-white/10 border border-gray-700 backdrop-blur-md p-4 rounded-lg shadow-lg">
                        <h3 className="text-xl font-bold text-white text-center mb-2">Enterprise</h3>
                        <Link to="/contact-us">
                            <button className="w-full py-2 bg-red-500 text-white rounded-lg font-semibold mb-4 hover:bg-red-400 transition-colors">
                                Contact Now
                            </button>
                        </Link>
                    </div>
                </div>

                {/* Responsive Card Layout for Pricing */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:hidden">
                    {[
                        { plan: "Basic", details: ["Free Test Messages: 5 Messages", "Bots Creation: 1 Bot", "Free Credits: N/A", "Message Limits: 150 / Month (3 credit/message)", "Overage Charges: 4 credits/ Message", "Recharge Options: $7for 150 credits, $11 for 300 credits, $22 for 680 credits", "API Access: ✔️", "Use URL to Train Bot: ✔️ (Recharge 299 credits+ for access)", "Priority Support: ❌", "Custom Integrations: ✔️", "Cloud Storage: ✔️"] },
                        { plan: "Pro", details: ["Free Test Messages: 5 Messages", "Bots Creation: 3 Bots", "Free Credits: 100 Credits", "Message Limits: 600 / Month (2 credit/message)", "Overage Charges: 3 credits/ Message", "Recharge Options: $7for 200 credits, $11 for 400 credits, $22 for 850 credits", "API Access: ✔️", "Use URL to Train Bot: ✔️", "Priority Support: ✔️", "Custom Integrations: ✔️", "Cloud Storage: ✔️"] },
                        { plan: "Super", details: ["Free Test Messages: 5 Messages", "Bots Creation: 5 Bots", "Free Credits: 300 Credits", "Message Limits: 1400 / Month (1 credit/message)", "Overage Charges: 2 credits/ Message", "Recharge Options: $7for 250 credits, $11 for 500 credits, $22 for 1050 credits", "API Access: ✔️", "Use URL to Train Bot: ✔️", "Priority Support: ✔️", "Custom Integrations: ✔️", "Cloud Storage: ✔️"] },
                        { plan: "Enterprise", details: ["Free Test Messages: 5 Messages", "Bots Creation: Custom Limit", "Free Credits: Custom Credits", "Message Limits: Custom", "Overage Charges: Custom", "Recharge Options: Custom Plans", "API Access: ✔️", "Use URL to Train Bot: ✔️", "Priority Support: ✔️", "Custom Integrations: ✔️", "Cloud Storage: ✔️"] }
                    ].map((plan, index) => (
                        <div className="bg-white/10 border border-gray-700 backdrop-blur-md p-4 rounded-lg shadow-lg" key={index}>
                            <h3 className="text-xl font-bold text-white text-center mb-2">{`${plan.plan} Plan`}</h3>
                            <ul className="text-gray-300">
                                {plan.details.map((feature, i) => (
                                    <li key={i} className="py-2 border-b border-gray-600">{feature}</li>
                                ))}
                            </ul>
                        </div>
                    ))}

                </div>

                {/* Tooltips */}
                <Tooltip id="free-messages-tooltip" place="top" effect="solid" className="tooltip-custom">
                    You can test your bot with 5 Free Test Messages.
                </Tooltip>
                <Tooltip id="bots-creation-tooltip" place="top" effect="solid" className="tooltip-custom">
                    The number of bots you can create is limited by your plan.
                </Tooltip>
                <Tooltip id="free-credits-tooltip" place="top" effect="solid" className="tooltip-custom">
                    Free credits provided with each plan.
                </Tooltip>
                <Tooltip id="message-limit-tooltip" place="top" effect="solid" className="tooltip-custom">
                    Monthly limit of messages allowed on each plan.
                </Tooltip>
                <Tooltip id="overage-tooltip" place="top" effect="solid" className="tooltip-custom">
                    Charges for messages sent over the plan limit.
                </Tooltip>
                <Tooltip id="api-access-tooltip" place="top" effect="solid" className="tooltip-custom">
                    Access to the API for integrating the chatbot into your applications.
                </Tooltip>
                <Tooltip id="use-url-to-train-bot-tooltip" place="top" effect="solid" className="tooltip-custom">
                    Train your bot using a URL, available for Pro, Super users and the basic users who recharge 300 credits or more.
                </Tooltip>
                <Tooltip id="priority-support-tooltip" place="top" effect="solid" className="tooltip-custom">
                    Get priority support for faster assistance.
                </Tooltip>
                <Tooltip id="custom-integrations-tooltip" place="top" effect="solid" className="tooltip-custom">
                    Custom integrations to connect with your existing services.
                </Tooltip>

                {/* Key Features Section */}
                <div className="mb-12 text-center mt-10">
                    <h2 className="text-4xl font-semibold text-white mb-6">Key Features</h2>
                    <div className="flex flex-wrap justify-center gap-8">
                        <div className="bg-white/10 border border-gray-700 backdrop-blur-md p-4 rounded-lg shadow-lg w-72">
                            <h3 className="text-xl font-bold text-white mb-2">Affordable Pricing</h3>
                            <p className="text-gray-300">Plans that suit your budget with flexible recharge options.</p>
                        </div>

                        <div className="bg-white/10 border border-gray-700 backdrop-blur-md p-4 rounded-lg shadow-lg w-72">
                            <h3 className="text-xl font-bold text-white mb-2">Innovative Features</h3>
                            <p className="text-gray-300">Utilize cutting-edge technology to enhance customer engagement.</p>
                        </div>

                        <div className="bg-white/10 border border-gray-700 backdrop-blur-md p-4 rounded-lg shadow-lg w-72">
                            <h3 className="text-xl font-bold text-white mb-2">User Support</h3>
                            <p className="text-gray-300">Dedicated support to help you with any questions or issues.</p>
                        </div>

                        <div className="bg-white/10 border border-gray-700 backdrop-blur-md p-4 rounded-lg shadow-lg w-72">
                            <h3 className="text-xl font-bold text-white mb-2">Cloud-Based Solutions</h3>
                            <p className="text-gray-300">Access your chatbot anytime, anywhere, with cloud storage.</p>
                        </div>
                    </div>
                </div>

                <Modal isOpen={isPaymentModalOpen} onClose={() => setIsPaymentModalOpen(false)}>
                    {orderDetails && (
                        <MarxIPGOrder
                            price={orderDetails.price}
                            summary={orderDetails.summary}
                            type={orderDetails.type}
                        />
                    )}
                </Modal>

                <Footer />

            </div>
        </div >
    );
}

export default PricingPage;
