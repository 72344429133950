import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import StepIndicator from './StepIndicator';
import { getSecureCookie, setSecureCookie } from '../utilities/safeSaves';


const SearchPage = () => {
    const { chatbotId } = useParams();
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchError, setSearchError] = useState(null);
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [botTyping, setBotTyping] = useState(false);
    const messagesEndRef = useRef(null); // Ref to handle auto-scroll
    const [currentStep, setCurrentStep] = useState(3);
    const uid = getSecureCookie('uid');
    const [greetings, setGreetings] = useState([
        {
            text: 'Hello! 👋 How can I assist you today?',
            type: 'bot'
        }
    ]);
    const [editGreeting, setEditGreeting] = useState(greetings); // Track editable greetings
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    // Effect to set initial messages based on editGreeting
    useEffect(() => {
        const updatedGreetings = editGreeting.map(greet => ({
            text: greet.text,
            type: 'bot'
        }));
        if (messages.length === 0) {
            setMessages(updatedGreetings); // Only set messages if they are empty
        }
    }, [editGreeting]);

    // Function to handle greeting changes
    const handleGreetingChange = (index, value) => {
        const newGreetings = [...editGreeting];
        newGreetings[index] = value; // Update the specific greeting

        // Update greetings state for storage
        setEditGreeting(newGreetings);
        setGreetings(newGreetings); // Ensure greetings reflect the updated values

        // Update only the corresponding message in messages without removing others
        const updatedMessages = [...messages]; // Create a copy of current messages
        if (updatedMessages[index]) {
            updatedMessages[index].text = value; // Update the text of the specific bot message
        }
        setMessages(updatedMessages); // Set the updated messages
    };


    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        localStorage.removeItem(`chatbotGreetings_${chatbotId}`);
        setLoading(true);
        const token = getSecureCookie('accessToken');

        try {
            // Filter out any empty or non-string greetings

            const stringGreetings = (JSON.stringify(editGreeting) === JSON.stringify(greetings))
                ? greetings // Use default if not edited
                : editGreeting.filter(greeting => typeof greeting.text === 'string' && greeting.text.trim() !== "");

            // Ensure at least one greeting exists to send
            if (stringGreetings.length > 0) {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };

                // Make the API call to replace existing greetings
                const response = await axios.put(
                    `https://api-bny5h3g2lq-uc.a.run.app/api/chatbot/predefined/${chatbotId}`,
                    { greetings: stringGreetings }, // Send the updated greetings array
                    config
                );


                setSuccess('Greetings updated successfully!');
                setError(null);
            } else {
                setError('No valid greetings to save.'); // Handle the case where no valid greetings exist
            }

            // Optionally reset the greetings state
            // setEditGreeting([]); // Uncomment this if you want to clear after save

            // Redirect after success
            window.location.href = `/chatBot/${chatbotId}/customize`;
        } catch (err) {
            console.error("Error updating greetings:", err); // Debugging output
            setError('Failed to update greetings.');
            setSuccess(null);
        } finally {
            setLoading(false);
        }
    };


    const handleRemoveGreeting = (index) => {
        const newGreetings = editGreeting.filter((_, i) => i !== index); // Create a new array without the removed greeting
        setEditGreeting(newGreetings); // Update state
        setGreetings(newGreetings)
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const obj = await fetchChatbotSummary();
                if (obj && Array.isArray(obj.greetings)) {
                    setMessages(obj.greetings); // Sets messages as intended

                    // Set greetings and editGreeting to contain at least two items
                    const updatedGreetings = obj.greetings.length < 1 ? [...obj.greetings, ""] : obj.greetings;
                    setGreetings(updatedGreetings.text);
                    setEditGreeting(updatedGreetings); // Ensures editGreeting is updated correctly
                } else {
                    console.warn("Greetings data is not an array or is undefined.");
                }
            } catch (error) {
                console.error("Failed to fetch chatbot summary:", error);
            }
        };

        fetchData();
    }, []);



    const fetchChatbotSummary = async () => {
        const uid = getSecureCookie('uid');
        const token = getSecureCookie('accessToken');
        // Get chatbot summary and timestamp from cookies
        // Check local storage for summary
        const storedData = localStorage.getItem(`chatbotSummary_${chatbotId}`);
        const storedTimestamp = localStorage.getItem(`chatbotSummaryTime_${chatbotId}`);
        const storedGreetings = localStorage.getItem(`chatbotGreetings_${chatbotId}`);
        const tenHoursInMillis = 10 * 60 * 60 * 1000; // 10 hours in milliseconds
        const currentTime = Date.now();

        // Check if summary is stored and is less than 10 hours old
        if (storedGreetings && storedData && storedTimestamp && (currentTime - storedTimestamp < tenHoursInMillis)) {
            const storedBotName = localStorage.getItem(`chatbotName_${chatbotId}`);
            const storedWebsite = localStorage.getItem(`chatbotWebsite_${chatbotId}`);

            return { greetings: JSON.parse(storedGreetings), summary: JSON.stringify(storedData), botName: storedBotName, website: storedWebsite }; // Return the cached data
        }

        try {
            const response = await axios.get(`https://api-bny5h3g2lq-uc.a.run.app/api/chatbot/${chatbotId}/${uid}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.data) {
                if (response.data.greetings && Array.isArray(response.data.greetings)) {
                    const greeting = response.data.greetings.map(greet => ({
                        text: greet,
                        type: 'bot'
                    }));
                    localStorage.setItem(`chatbotGreetings_${chatbotId}`, JSON.stringify(greeting))
                }
            }

            const summary = response.data.summary; // Adjust based on your API response structure
            const botName = response.data.botName; // Assuming API returns botName
            const website = response.data.website; // Assuming API returns website


            // Save chatbot summary, botName, and website with an expiration of 10 hours
            localStorage.setItem(`chatbotSummary_${chatbotId}`, JSON.stringify(summary), { expires: 10 / 24 });
            localStorage.setItem(`chatbotSummaryTime_${chatbotId}`, currentTime, { expires: 10 / 24 });
            localStorage.setItem(`chatbotName_${chatbotId}`, botName, { expires: 10 / 24 });
            localStorage.setItem(`chatbotWebsite_${chatbotId}`, website, { expires: 10 / 24 });

            const storedData = localStorage.getItem(`chatbotSummary_${chatbotId}`);
            const storedBotName = localStorage.getItem(`chatbotName_${chatbotId}`);
            const storedWebsite = localStorage.getItem(`chatbotWebsite_${chatbotId}`);
            const storedGreetings = localStorage.getItem(`chatbotGreetings_${chatbotId}`);

            return { greetings: JSON.parse(storedGreetings), summary: JSON.stringify(storedData), botName: storedBotName, website: storedWebsite }; // Return the cached data

        } catch (error) {
            console.error('Error fetching chatbot summary:', error);
            setSearchError('Failed to fetch chatbot summary.');
            return null; // Handle failure by returning null or appropriate data
        }
    };



    const searchChatbots = async (query) => {
        setLoading(true);
        setSearchError(null);
        const { summary, website, botName } = await fetchChatbotSummary();
        const uid = getSecureCookie('uid');
        const token = getSecureCookie('accessToken');

        try {
            const response = await axios.post(
                'https://api-bny5h3g2lq-uc.a.run.app/chatbot-search',
                {
                    chatbotId,
                    uid,
                    token,
                    query,
                    summary: JSON.parse(summary),
                    website,
                    botName,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getSecureCookie('accessToken')}`, // Add your auth token here
                        'Content-Type': 'application/json', // Optional, ensures correct format
                    },
                }
            );
            setSearchResults(response.data);

            // Simulate bot typing
            setTimeout(() => {
                const finalResponse = response.data.response;
                setMessages(prevMessages => [
                    ...prevMessages,
                    { text: finalResponse, type: 'bot' }
                ]);
                setBotTyping(false);
            }, 1000);

        } catch (error) {
            if (error.response && error.response.status === 403 && error.response.data.error === "Email not verified. Please verify your email to proceed.") {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        text: 'Email not verified. Please verify your email to proceed.',
                        type: 'bot'
                    },
                ]);
            }
            else if (error.response && error.response.status === 403) {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        text: 'Your Test Balance is Over, Just continue to the next step and start designing your bot UI.',
                        type: 'bot'
                    },
                ]);

            }
            else {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        text: 'Error fetching chatbot data. Please try again later.',
                        type: 'bot'
                    },
                ]);
            }
            setSearchError('Error fetching chatbot data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleSearchSubmit = (e) => {
        const trimmedQuery = searchQuery.trim();

        // Check if the trimmed query is empty or contains only symbols
        if (trimmedQuery === "" || /^[^a-zA-Z0-9]+$/.test(trimmedQuery)) {
            // If searchQuery is empty or contains only non-alphanumeric characters
            return; // Block the submission
        }
        e.preventDefault();

        // Add user message immediately to the messages array
        setMessages(prevMessages => [
            ...prevMessages,
            { text: searchQuery, type: 'user' }
        ]);

        // Trigger bot typing animation immediately
        setBotTyping(true);

        // Clear the input field
        setSearchQuery('');

        // Trigger the chatbot search
        searchChatbots(searchQuery);
    };

    // Auto scroll to the bottom when messages update
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages, loading, botTyping]);

    const formatMessageText = (text) => {
        // Convert non-string input to a JSON string or handle it in a specific way if needed
        if (typeof text !== 'string') {
            return text.text; // or handle accordingly
        }

        // Remove hashtag symbols
        let formattedText = text.replace(/#/g, '');

        // Bold formatting
        const boldTextRegex = /\*\*(.*?)\*\*/g;
        formattedText = formattedText.replace(boldTextRegex, (match, boldText) => `<b>${boldText}</b>`);

        // URL and email detection regex
        const regex = /(\[([^\]]+)\])?\s*(https?:\/\/[^\s]+|www\.[^\s]+|[^\s]+\.[^\s]+|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;

        // Split text by new lines and apply link formatting
        return formattedText.split('\n').map((line) => {
            return line.replace(regex, (match, p1, p2, p3) => {
                let finalLink;

                // Check if p3 is an email
                if (p3.includes('@')) {
                    finalLink = `mailto:${p3.trim()}`;
                    return `<br/><a href="${finalLink}" style="color: #A9E4EF; text-decoration: underline;" target="_blank" rel="noopener noreferrer"><i class="fa fa-envelope"></i> ${p3.trim()}</a>`;
                } else {
                    // Clean and process links
                    const cleanLink = p3.replace(/^[^\w]+|[^\w]+$/g, '');
                    finalLink = cleanLink.startsWith('http') || cleanLink.startsWith('www') ? cleanLink : `http://${cleanLink}`;

                    if (p1) {
                        // Use the text in brackets as link text
                        return ` <a href="${finalLink}" style="color: #A9E4EF; text-decoration: underline;" target="_blank" rel="noopener noreferrer">${p2}</a>`;
                    }
                    return `<br/><i class="fas fa-link"></i> <a href="${finalLink}" style="color: #A9E4EF; text-decoration: underline;" target="_blank" rel="noopener noreferrer">${finalLink}</a>`;
                }
            });
        }).join('<br/>'); // Convert new lines to <br/>
    };



    return (
        <>

            <div className="mt-16">

                <h1 className="uppercase text-3xl font-bold text-white text-center mb-6"
                    style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>Test Your Bot's Response</h1>

                {/* Step Indicator */}
                <StepIndicator currentStep={currentStep} />


                <div className='w-11/12 h-fit relative flex container mx-auto bg-gradient-to-br from-gray-100 via-white to-gray-200 mt-10 rounded-lg shadow-2xl p-6'>

                    {/* Edit Bot's Memory Link as Back Button */}
                    <Link
                        to={`/chatbot-response/${chatbotId}`}
                        className={`absolute top-4 left-4 p-2 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-semibold rounded-md shadow-lg 
                    transition-all duration-300 ease-in-out 
                    ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:from-pink-600 hover:to-purple-600 hover:scale-105'}`}
                    >
                        Edit Bot's Memory
                    </Link>


                    <div className="mx-auto mt-5 rounded-lg overflow-hidden bg-white relative"
                        style={{
                            width: '40rem',
                            height: '30rem',
                            minHeight: '24rem',
                            margin: '2.5rem auto',
                            boxShadow: '10px 10px 15px rgba(0, 0, 0, 0.3)',
                            borderRadius: '0.5rem',
                            overflow: 'hidden',
                            backgroundColor: 'white',
                            position: 'relative'
                        }}
                    >

                        {/* Gradient Overlay */}
                        <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-600 text-white opacity-30 rounded-lg shadow-lg"></div>

                        <div className="relative z-10 flex flex-col h-full">
                            {/* Header */}
                            <div className="bg-pink-600 p-4 text-white text-lg font-semibold rounded-t-lg flex justify-between items-center">
                                <span>Test Chatbot Functionality</span>
                                <div className="w-10 h-10 relative rounded-full overflow-hidden ml-2 flex-shrink-0">
                                    <img loading="lazy"
                                        src={"https://res.cloudinary.com/dbe2fast6/image/upload/v1728982612/logo-botanion-01_us1eyb.svg"}
                                        alt={"Bot Avatar"}
                                        className="absolute inset-0 w-full h-full object-cover"
                                    />
                                </div>
                            </div>

                            {/* Message Display Section */}
                            <div className="flex-1 p-4 overflow-y-auto bg-white rounded-b-lg shadow-inner">
                                {/* Display messages */}
                                {messages.map((message, index) => (
                                    <div
                                        key={index}
                                        className={`flex mb-2 ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}
                                    >
                                        <div className="w-10 h-10 relative rounded-full overflow-hidden mr-2 flex-shrink-0">
                                            <img loading="lazy"
                                                src={message.type === 'user'
                                                    ? "https://th.bing.com/th/id/OIP.hxh6omrkOuj2l9rGHNe7qQHaHa?w=188&h=188&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                    : "https://res.cloudinary.com/dbe2fast6/image/upload/v1728982612/logo-botanion-01_us1eyb.svg"}
                                                alt={message.type === 'user' ? "User Avatar" : "Bot Avatar"}
                                                className="absolute inset-0 w-full h-full object-cover"
                                            />
                                        </div>
                                        <div
                                            className={`p-2 rounded-lg max-w-xs text-white shadow-md ${message.type === 'user' ? 'bg-pink-900' : 'bg-pink-600 text-gray-900'}`}
                                            style={{
                                                wordBreak: 'break-word',
                                                whiteSpace: 'pre-wrap'
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    formatMessageText(message.text)
                                                ),
                                            }}
                                        />
                                    </div>
                                ))}

                                {botTyping && (
                                    <div className="flex mb-2 justify-start">
                                        <div className="w-10 h-10 relative rounded-full overflow-hidden mr-2 flex-shrink-0">
                                            <img loading="lazy"
                                                src="https://res.cloudinary.com/dbe2fast6/image/upload/v1728982612/logo-botanion-01_us1eyb.svg"
                                                alt="Bot Avatar"
                                                className="absolute inset-0 w-full h-full object-cover"
                                            />
                                        </div>
                                        <div className="p-2 rounded-lg max-w-xs bg-gray-300 text-gray-900 shadow-md">
                                            <div className="flex items-center">
                                                <div className="h-2 w-2 bg-blue-500 rounded-full animate-bounce mr-2"></div>
                                                <div className="h-2 w-2 bg-blue-500 rounded-full animate-bounce mr-2"></div>
                                                <div className="h-2 w-2 bg-blue-500 rounded-full animate-bounce"></div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Auto-scroll reference */}
                                <div ref={messagesEndRef} />
                            </div>

                            {/* Input Form */}
                            <form onSubmit={handleSearchSubmit} className="flex items-center p-2 border-t">
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="flex-1 border border-gray-300 rounded-lg p-2 shadow-md"
                                    placeholder="Type a message..."
                                />
                                <button
                                    type="submit"
                                    className="ml-2 p-2 bg-pink-600 text-white rounded-lg shadow-md transition duration-200 hover:bg-blue-500"
                                >
                                    Send
                                </button>
                            </form>
                        </div>
                    </div>

                    <div className="greetings-form p-10 bg-gray-400/20 rounded-lg shadow-md mb-6 h-fit w-fit mx-auto mt-32">
                        <h2 className="text-lg font-semibold mb-4">Customize Greetings</h2>
                        {editGreeting.map((greeting, index) => (
                            <div key={index} className="flex items-center mb-2">
                                <input
                                    type="text"
                                    value={typeof greeting === 'string' ? greeting : typeof greeting.text === 'string' ? greeting.text : greeting.text.text} // Ensure greeting is a string
                                    onChange={(e) => handleGreetingChange(index, e.target.value)}
                                    className="border rounded-lg p-2 flex-1 mr-2"
                                />
                            </div>
                        ))}
                    </div>


                    {/* Customize Button outside Form */}
                    <button
                        onClick={async (e) => {
                            await handleSubmit(e); // Call the handleSubmit function
                        }}
                        className={`p-3 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-semibold rounded-md shadow-lg 
                transition-all duration-300 ease-in-out 
                ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:from-pink-600 hover:to-purple-600 hover:scale-105'}`}
                        style={{
                            position: 'absolute',   // Positioned relative to the parent container
                            bottom: '1rem',         // 1rem from the bottom
                            right: '1rem',          // 1rem from the right
                            zIndex: 10,             // Ensure it stays above other content
                        }}
                    >
                        Start Designing Your Bot
                    </button>

                </div>

            </div>

        </>
    );
};

export default SearchPage;