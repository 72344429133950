import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import StepIndicator from './StepIndicator';
import Cookies from 'js-cookie';
import LoadingScreen from './LoadingScreen';
import { getSecureCookie } from '../utilities/safeSaves';
import NotFoundPage from './404';


const ChatbotResponsePage = () => {
    const { chatbotId } = useParams(); // Get the chatbot ID from the URL
    const [chatbotData, setChatbotData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [additionalData, setAdditionalData] = useState({ title: '', paragraphs: [''] }); // Initialize with an empty paragraphs array
    const [additionalItems, setAdditionalItems] = useState([]); // Array to hold additional data items
    const [currentStep, setCurrentStep] = useState(2);
    const scrollContainerRef = useRef(null); // Create a ref for the scrollable container
    const [currentPage, setCurrentPage] = useState(0); // Track current page
    const [TempParagraph, setTempParagraph] = useState('');
    // Function to filter out invalid items
    const getValidSummaries = () => {
        return (chatbotData?.summary || []).filter(item => item && typeof item === 'object' && item.title);
    };

    const validSummaries = getValidSummaries();
    const totalPages = Math.ceil(validSummaries.length / 1); // 1 item per page

    const splitIntoSentences = (text) => {
        return text
            .split(/(?<=[.!?])\s+/)
            .map(sentence => sentence.trim())
            .filter(sentence => sentence !== '');
    };


    // Helper function to generate pagination numbers
    const getPaginationNumbers = () => {
        if (totalPages <= 6) {
            return Array.from({ length: totalPages }, (_, index) => index + 1); // Start from 1
        }

        const pages = [];
        const leftSide = Math.max(1, currentPage); // Current page is 1-based now
        const rightSide = Math.min(totalPages - 2, currentPage + 1); // Ensure we don’t exceed the total pages

        // Always show the first page
        pages.push(1);

        if (leftSide > 2) {
            pages.push(2);
            if (leftSide > 3) pages.push('...'); // Show ellipsis
        }

        // Show page numbers around the current page
        for (let i = leftSide; i <= rightSide + 1; i++) {
            if (i < totalPages) {
                pages.push(i + 1); // Adjust for 1-based index
            }
        }

        // Always show the last two pages
        if (rightSide < totalPages - 2) {
            if (rightSide < totalPages - 3) pages.push('...'); // Show ellipsis
            pages.push(totalPages - 1);
            pages.push(totalPages);
        }

        return pages;
    };


    // Function to scroll to the bottom of the container
    const scrollToBottom = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
    };

    // Use useEffect to scroll to bottom whenever additionalItems changes
    useEffect(() => {
        scrollToBottom();
    }, [additionalItems]);

    useEffect(() => {
        const fetchChatbotData = async () => {
            setLoading(true); // Set loading state before the API call
            const uid = getSecureCookie('uid');


            try {
                const token = getSecureCookie('accessToken'); // Replace with your actual token retrieval method

                const response = await axios.get(`https://api-bny5h3g2lq-uc.a.run.app/api/chatbot/${chatbotId}/${uid}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Add the authorization header
                    },
                });

                setChatbotData(response.data);
            } catch (err) {
                if (err.response) {
                    if (err.response.status === 404) {
                        setError('Chatbot not found. Please check the chatbot ID.');
                    } else {
                        setError('Error fetching chatbot data. Please try again later.');
                    }
                } else {
                    setError('Network error. Please check your connection.');
                }
            } finally {
                setLoading(false); // Reset loading state
            }
        };

        if (chatbotId) { // Ensure chatbotId is valid before fetching
            fetchChatbotData();
        }
    }, [chatbotId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        // Merge additional entries into the summary
        const updatedSummary = [...(chatbotData?.summary || []), ...additionalItems]; // Use optional chaining here

        try {
            const response = await axios.put(
                `https://api-bny5h3g2lq-uc.a.run.app/api/chatbot/${chatbotId}`, // Use PUT to update the chatbot
                { ...chatbotData, summary: updatedSummary }, // Update the summary with new entries
                {
                    headers: {
                        Authorization: `Bearer ${getSecureCookie('accessToken')}`,
                    },
                }
            );

            setAdditionalItems([]); // Clear the entries
            setChatbotData((prev) => ({ ...prev, summary: updatedSummary })); // Update local state with the new summary
        } catch (err) {
            console.error('Error submitting additional data', err);
            if (err.response && err.response.status === 403 && err.response.data.error === "Email not verified. Please verify your email to proceed.") {
                setError('Email not verified. Please verify your email to proceed.')
            }

        } finally {
            setLoading(false);
        }
    };

    const addAdditionalDataItem = () => {
        if (additionalData.title && additionalData.paragraphs.length) {
            setAdditionalItems([...additionalItems, additionalData]); // Add the current data to the additional items
            setAdditionalData({ title: '', paragraphs: [''] }); // Clear the fields
        }
    };

    const convertMarkdownToHTML = (text) => {
        // Ensure this handles only string inputs
        if (typeof text !== 'string') {
            return text; // Return as is if it's not a string
        }
        return text
            .replace(/(?<!\d)(\*\*(.*?)\*\*)/g, (match, p1, p2) => `<br><br><b>${p2}</b>`)
            .replace(/-\s*\*\*(.*?)\*\*/g, (match, p1) => `<br><b>${p1}</b>`)
            .replace(/(?<=\d+\.?)\s*\*\*(.*?)\*\*/g, (match, p1) => `<b>${p1}</b>`)
            .replace(/(?<=\d)\s*-\s*\*\*(.*?)\*\*/g, (match, p1) => `<b>${p1}</b>`)
            .replace(/-/g, ''); // Replace remaining "-" with "<br>"
    };

    const renderSummary = (item) => {
        // Check if the item is defined and has valid properties
        if (!item || typeof item !== 'object') {
            console.error("Invalid item:", item);
            return null; // Return null or a fallback UI element
        }

        const { title, paragraphs, url } = item;

        // Check if paragraphs is defined and is an array
        const validParagraphs = Array.isArray(paragraphs) ? paragraphs : [];

        return (
            <div className="mb-4">
                <h4 className="font-extrabold text-gray-800">{title}</h4>
                <br />
                <hr />
                <br />
                {validParagraphs.map((para, index) => (
                    <p
                        key={index}
                        className="text-gray-600"
                        dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML(para) }}
                    />
                ))}
                <br />
                {url && (
                    <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 underline"
                    >
                        {url}
                    </a>
                )}
            </div>
        );
    };


    const handleAdditionalDataChange = (field, value) => {
        setAdditionalData((prev) => ({ ...prev, [field]: value })); // Update additionalData state correctly
    };

    if (loading) {
        return <LoadingScreen textToShow={"Creating Your Bot..."} />; // Show a loading state
    }


    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) => {
            const newPage = prevPage + direction;
            return Math.max(0, Math.min(newPage, validSummaries.length - 1));
        });
    };


    const handleParagraphChange = (value) => {
        setTempParagraph(value)
        const sentences = splitIntoSentences(value); // Use the existing splitIntoSentences function
        setAdditionalData((prev) => ({ ...prev, paragraphs: sentences })); // Update additionalData with the array of sentences
    };

    const remainingCharacters = 5000 - TempParagraph.length;

    // Determine the text color based on remaining characters
    let textColorClass = 'text-gray-500'; // Default color
    if (remainingCharacters <= 200) {
        textColorClass = 'text-red-500'; // Red if 200 or fewer characters remaining
    } else if (remainingCharacters <= 500) {
        textColorClass = 'text-orange-500'; // Orange if between 200 and 500
    } else {
        textColorClass = 'text-green-500'; // Green if more than 500 characters remaining
    }


    return (
        <>
            {loading ? (
                <div className="flex items-center justify-center h-screen">
                    <p>Loading...</p>
                </div>
            ) : chatbotData ? (
                <div className='mt-16'>
                    <h1
                        className="text-2xl md:text-3xl text-center uppercase text-white font-bold mb-4 sm:mt-9"
                        style={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.6)' }}
                        data-aos="fade-up" // AOS animation
                        data-aos-duration="1000" // Animation duration
                    >
                        Chatbot Response Summary
                    </h1>

                    <StepIndicator currentStep={currentStep} />

                    <div className="w-full lg:w-3/4 relative flex flex-col lg:flex-row container mx-auto bg-gradient-to-br from-gray-100 via-white to-gray-200 mt-6 sm:mt-10 rounded-lg shadow-2xl p-4 sm:p-6">

                        {/* Left Side: Chatbot Response Summary */}
                        <div className="flex flex-col w-full lg:w-3/4 gap-4 lg:gap-6">
                            <div className="w-full h-fit mx-auto bg-white p-6 sm:p-8 rounded-lg shadow-lg transition-transform transform">
                                {chatbotData && (
                                    <div className="h-64 sm:h-96 overflow-y-auto bg-gray-50 p-4 rounded-lg shadow-inner">
                                        <p
                                            className="text-green-600"
                                            dangerouslySetInnerHTML={{ __html: convertMarkdownToHTML(chatbotData.additional) }}
                                        />
                                        {validSummaries.length > 0 && renderSummary(validSummaries[currentPage])}
                                    </div>
                                )}
                            </div>

                            {/* Navigation Buttons */}
                            <div className="mt-4">
                                <div className="flex justify-between items-center mt-4">
                                    <button
                                        onClick={() => handlePageChange(-1)}
                                        disabled={currentPage === 0}
                                        className="bg-blue-500 text-white px-4 py-2 rounded-lg disabled:opacity-50"
                                    >
                                        Previous
                                    </button>
                                    <span className="text-sm sm:text-base">{currentPage + 1} of {validSummaries.length}</span>
                                    <button
                                        onClick={() => handlePageChange(1)}
                                        disabled={currentPage >= validSummaries.length - 1}
                                        className="bg-blue-500 text-white px-4 py-2 rounded-lg disabled:opacity-50"
                                    >
                                        Next
                                    </button>
                                </div>

                                {/* Pagination Numbers */}
                                <div className="flex justify-center mt-4">
                                    {getPaginationNumbers().map((number, index) => (
                                        <button
                                            key={index}
                                            onClick={() => {
                                                if (typeof number === 'number') {
                                                    handlePageChange(number - currentPage);
                                                }
                                            }}
                                            className={`mx-1 px-2 sm:px-3 py-1 border rounded-lg 
                                ${currentPage === number ? 'bg-blue-600 text-white' : 'bg-white text-blue-600'} 
                                hover:bg-blue-100 transition-colors`}
                                            disabled={number === '...'}
                                        >
                                            {number}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Right Side: Additional Data Form */}
                        <div className="w-full lg:w-1/3 p-4 sm:p-8" data-aos="fade-left" data-aos-duration="1600">
                            <h2 className="text-lg md:text-xl font-semibold text-gray-700 mb-4">Add Additional Data</h2>

                            <div
                                ref={scrollContainerRef}
                                style={{ maxHeight: '150px', overflowY: 'auto' }}
                                className="mb-4"
                            >
                                {additionalItems.map((item, index) => (
                                    <div key={index} className="mb-4 border p-3 sm:p-4 rounded-lg bg-gray-100">
                                        <h4 className="font-semibold text-gray-800">{item.title}</h4>
                                        {item.paragraphs.map((para, paraIndex) => (
                                            <p key={paraIndex} className="text-gray-600">{para}</p>
                                        ))}
                                    </div>
                                ))}
                            </div>

                            <input
                                type="text"
                                name="title"
                                value={additionalData.title}
                                onChange={(e) => handleAdditionalDataChange('title', e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                                placeholder="Title"
                                data-aos="fade-right" // AOS animation
                                data-aos-duration="1800" // Animation duration
                            />

                            <textarea
                                name="paragraph"
                                value={additionalData.paragraphs.join('\n')}
                                onChange={(e) => handleParagraphChange(e.target.value)}
                                rows="4"
                                maxLength={5000}
                                className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                                placeholder="Type your paragraph here..."
                                data-aos="fade-right" // AOS animation
                                data-aos-duration="1800" // Animation duration
                            />

                            <div className="text-sm text-gray-600 mb-2">
                                {remainingCharacters} characters remaining
                            </div>

                            <button
                                type="button"
                                onClick={addAdditionalDataItem}
                                className="mt-2 w-full py-2 px-4 bg-green-600 text-white font-semibold rounded-md shadow-lg hover:bg-green-700 transition-colors"
                                data-aos="fade-up"
                                data-aos-duration="2000"
                            >
                                Add
                            </button>

                            <form onSubmit={handleSubmit} className="mt-1">
                                <button
                                    type="submit"
                                    className="mt-2 w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md shadow-lg"
                                    data-aos="fade-up"
                                    data-aos-duration="2000"
                                >
                                    Submit Additional Data
                                </button>
                            </form>

                            {/* Confirm All Data Button */}
                            <button
                                onClick={() => window.location.replace(`/chatbot/${chatbotId}`)}
                                className={`absolute bottom-4 right-4 py-2 px-4 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-semibold rounded-md shadow-lg 
                    ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:from-pink-600 hover:to-purple-600 hover:scale-105'}`}
                                data-aos="fade-up"
                                data-aos-duration="2000"
                            >
                                Confirm All Data
                            </button>
                        </div>
                    </div>
                </div>) : (
                <NotFoundPage />
            )}
        </>

    );
};

export default ChatbotResponsePage;