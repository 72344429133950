// SimpleHeader.jsx
import React from 'react';

const SimpleHeader = ({ currentTheme, botName, avatars, tagline }) => {
    return (
        <div className="p-5 rounded-t-lg shadow-md flex flex-col items-center" style={{ backgroundColor: currentTheme.headerBg, color: currentTheme.headerTextColor }}>
            {/* Company Logo */}
            <img
                src={avatars.botAvatar} // Replace with your logo URL
                alt="Company Logo"
                className="h-10 w-10 rounded-full border border-white"
            />
            {/* Company Name */}
            <h1 className="text-xl font-bold mb-1">{botName}</h1>
            {/* Description */}
            <p className="text-sm text-center">{tagline}</p>
        </div>
    );
};

export default SimpleHeader;