import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className="fixed left-0 right-0 flex items-center justify-center w-screen h-screen overflow-hidden">
            {/* Full-screen background image */}
            <img
                src="https://res.cloudinary.com/dbe2fast6/image/upload/v1729852506/freepik__candid-image-photography-natural-textures-highly-r__69314_yb9ylo.jpg" // Replace with your image path
                alt="404 Not Found"
                className="absolute inset-0 object-cover w-full h-full"
            />

            {/* Dark overlay for better text readability */}
            <div className="absolute inset-0 bg-black opacity-40" />

            {/* Overlay with message and button */}
            <div className="relative z-10 flex flex-col items-center justify-center text-center text-white p-6 rounded-md">
                <h1 className="text-6xl sm:text-7xl md:text-8xl font-bold mb-4">404</h1>
                <p className="text-xl sm:text-2xl md:text-3xl mb-6">Page Not Found</p>
                <button
                    onClick={handleGoBack}
                    className="px-4 sm:px-6 py-2 text-lg sm:text-xl font-medium text-gray-800 bg-white rounded-md shadow-lg hover:bg-gray-200 focus:outline-none transition duration-300"
                >
                    Go Back
                </button>
            </div>
        </div>
    );
};

export default NotFoundPage;
