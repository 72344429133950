import React, { useState } from 'react';
import axios from 'axios';
import { getSecureCookie } from '../utilities/safeSaves';

const EmailNotVerifiedBar = ({ uid, token }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSendVerification = async () => {
        setLoading(true);
        setError('');
        setMessage('');

        try {
            uid = getSecureCookie('uid')
            // Call the API to send the verification email
            const response = await axios.post(
                'https://api-bny5h3g2lq-uc.a.run.app/api/send-verification-email',
                { uid },
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Token for authentication
                    },
                }
            );

            if (response.status === 200) {
                setMessage('Verification email sent successfully. Please check your inbox.');
            } else {
                setError('Failed to send verification email. Please try again.');
            }
        } catch (err) {
            console.error('Error sending verification email:', err);
            setError('Failed to send verification email. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-pink-100 border-l-4 border-purple-500 text-yellow-700 p-4 mb-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-bold">Email Not Verified</h3>
            <p>Your email is not verified. Please verify your email to continue using all features.</p>

            {/* Button to send verification email */}
            <button
                onClick={handleSendVerification}
                disabled={loading}
                className={`mt-3 px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
                    }`}
            >
                {loading ? 'Sending...' : 'Send Verification Email'}
            </button>

            {/* Display Success or Error Messages */}
            {message && <p className="text-green-600 mt-2">{message}</p>}
            {error && <p className="text-red-600 mt-2">{error}</p>}
        </div>
    );
};

export default EmailNotVerifiedBar;
