// src/Chatbot.js
import React, { useState, useEffect } from 'react';

const ChattingBot = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Load visibility state from localStorage on component mount
    useEffect(() => {
        const savedVisibility = localStorage.getItem('chatbotVisibility');
        if (savedVisibility === 'true') {
            setIsVisible(true);
        }
    }, []);

    // Toggle visibility and save state to localStorage
    const toggleChatbot = () => {
        const newVisibility = !isVisible;
        setIsVisible(newVisibility);
        localStorage.setItem('chatbotVisibility', newVisibility);
    };

    return (
        <div>
            {/* Chatbot iframe */}
            {isVisible && (
                <iframe
                    id="chatbotIframe"
                    src="https://chatbot.botanion.cc/chatbot/lpGc2PqGYRSsoaBnlfbW?uid=YRM2DGlLwccQdUgTctnuvrE069x2&apiToken=94eca37d29ee0e8cd484b246fb1efc6bbf9cab4d958d13c4bcaeae21018ff10f&chatbotId=lpGc2PqGYRSsoaBnlfbW"
                    sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
                    style={{
                        display: 'block',
                        position: 'fixed',
                        bottom: '10px',
                        right: '2px',
                        border: 'none',
                        zIndex: '1000',
                        width: '900px',
                        height: '700px'
                    }}
                    title="Chatbot"
                />
            )}
            {/* Toggle button */}
            <button
                id="toggleButton"
                onClick={toggleChatbot}
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    zIndex: '1001',
                    width: '50px',
                    height: '50px',
                    backgroundColor: 'purple',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <i className="fas fa-comments"></i>
            </button>
        </div>
    );
};

export default ChattingBot;
