import React from 'react';
import { FaEllipsisV, FaCircle } from 'react-icons/fa';

const GradientHeader = ({ currentTheme, botName, avatars }) => {
    const gradientColor = `linear-gradient(to right, ${currentTheme.buttonBg}, ${currentTheme.buttonHoverBg})`;

    return (
        <div className="relative" style={{ backgroundColor: currentTheme.headerBg, color: currentTheme.headerTextColor, padding: '16px 20px' }}>
            {/* Profile Section */}
            <div className="flex items-center">
                {/* Profile Picture */}
                <img
                    src={avatars.botAvatar}
                    alt="User Avatar"
                    className="h-10 w-10 rounded-full border border-white mr-3"
                />
                {/* User Name and Status */}
                <div className="flex flex-col text-left">
                    <span className="text-lg font-semibold">{botName}</span>
                    <div className="flex items-center text-sm">
                        <FaCircle className="text-green-400 mr-1" />
                        <span>Online</span>
                    </div>
                </div>
            </div>

            {/* Action Menu Icon */}
            <div className="absolute top-4 right-4">
                <FaEllipsisV className="text-white text-xl" />
            </div>
        </div>
    );
};

export default GradientHeader;