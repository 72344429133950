import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StepIndicator from './StepIndicator';
import { getSecureCookie } from '../utilities/safeSaves';

function CreateChatbotJSON() {
    const [formData, setFormData] = useState({
        chatbotName: '',
        description: '',
        address: '',
        contactEmail: '',
        contactNumber: '',
        websiteUrl: '' // Add website URL to the form data
    });

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleNext = () => {
        // Step 1: Validate form data before proceeding
        const { chatbotName, websiteUrl, description, contactEmail } = formData;

        // Check if required fields are filled
        if (!chatbotName || !websiteUrl || !description) {
            setErrorMessage('Chatbot Name, Website URL, and Description are required.');
            setLoading(false);
            return;
        }

        // Check if the website URL is in a valid format
        let trimmedWebsiteUrl = websiteUrl.trim();  // Use 'let' here to allow reassignment

        // Check if the URL doesn't start with 'http://' or 'https://', if so, add 'https://'
        if (!/^https?:\/\//i.test(trimmedWebsiteUrl)) {
            trimmedWebsiteUrl = 'https://' + trimmedWebsiteUrl;  // Modify the 'trimmedWebsiteUrl' variable
        }

        // URL validation
        const urlPattern = /^(https?:\/\/)?([\w\d-]+\.)+[a-z]{2,6}(\/[\w\d-]*)*\/?$/;
        if (!urlPattern.test(trimmedWebsiteUrl)) {
            setErrorMessage('Please enter a valid website URL.');
            setLoading(false);
            return;
        }

        // Optionally, validate the email if provided
        if (contactEmail && !/\S+@\S+\.\S+/.test(contactEmail)) {
            setErrorMessage('Please enter a valid contact email address.');
            setLoading(false);
            return;
        }
        // Pass the current state to the next page
        navigate('/create-chatbot/ai-manual/data', { state: { formData } });
    };

    return (
        <>
            <div className="flex justify-center items-center mb-6 mt-10 sm:mt-16">
                <h1 className="text-2xl sm:text-3xl uppercase text-white font-bold text-center" style={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.6)' }}>
                    Manual Bot Training
                </h1>
            </div>

            {/* Step Indicator */}
            <StepIndicator currentStep={1} />
            <div className=''>
                <div className="mb-4 sm:flex sm:items-center max-w-3xl md:max-w-5xl mx-auto"> {/* Added sm:items-center for vertical alignment */}
                    <img
                        src="https://res.cloudinary.com/dbe2fast6/image/upload/v1730730575/freepik__candid-image-photography-natural-textures-highly-r__46321_hztopn.jpg"
                        alt="Chatbot illustration"
                        className="hidden sm:block w-full h-64 object-cover rounded-lg shadow-md" // Hide on mobile, show on sm and larger
                    />
                </div>

                <div className="flex flex-col sm:flex-row max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto bg-black/10 rounded-lg shadow-lg overflow-hidden" style={{
                    boxShadow: '2px 4px 20px rgba(0, 0, 0, 0.7)'
                }}>

                    {/* Left Side Form */}
                    <div className="w-full sm:w-1/2 p-4 sm:p-8 overflow-y-auto">
                        {/* Image - Visible only on mobile view */}
                        <div className="mb-4 sm:hidden">
                            <img src="https://res.cloudinary.com/dbe2fast6/image/upload/v1730730575/freepik__candid-image-photography-natural-textures-highly-r__46321_hztopn.jpg" alt="Chatbot" className="w-full h-32 object-cover rounded-lg shadow-md" />
                        </div>

                        <form onSubmit={(e) => { e.preventDefault(); handleNext(); }}>

                            {/** Chatbot Name Field */}
                            <div className="mb-4">
                                <label htmlFor="chatbotName" className="block text-base sm:text-lg font-medium text-white mb-2">
                                    Chatbot Name
                                </label>
                                <input
                                    type="text"
                                    id="chatbotName"
                                    name="chatbotName"
                                    value={formData.chatbotName}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                    required
                                />
                            </div>

                            {/** Website URL Field */}
                            <div className="mb-4">
                                <label htmlFor="websiteUrl" className="block text-base sm:text-lg font-medium text-white mb-2">
                                    Website URL
                                </label>
                                <input
                                    type="url"
                                    id="websiteUrl"
                                    name="websiteUrl"
                                    value={formData.websiteUrl}
                                    onChange={handleChange}
                                    placeholder="https://example.com"
                                    className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                    required
                                />
                            </div>

                            {/** Description Field */}
                            <div className="mb-4">
                                <label htmlFor="description" className="block text-base sm:text-lg font-medium text-white mb-2">
                                    Description
                                </label>
                                <textarea
                                    id="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                    required
                                    rows="4"
                                />
                            </div>
                        </form>
                    </div>

                    {/* Right Side Form */}
                    <div className="w-full sm:w-1/2 p-4 sm:p-8 overflow-y-auto">
                        <form onSubmit={(e) => { e.preventDefault(); handleNext(); }}>
                            {/** Additional Optional Fields */}
                            {["address", "contactEmail", "contactNumber"].map((field, index) => (
                                <div className="mb-4" key={index}>
                                    <label htmlFor={field} className="block text-base sm:text-lg font-medium text-white mb-2">
                                        {field === "contactEmail" ? "Contact Email" : field.charAt(0).toUpperCase() + field.slice(1)} (optional)
                                    </label>
                                    <input
                                        type={field === "contactEmail" ? "email" : field === "contactNumber" ? "tel" : "text"}
                                        id={field}
                                        name={field}
                                        value={formData[field]}
                                        onChange={handleChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                    />
                                </div>
                            ))}

                            {/** Submit Button */}
                            <button
                                type="submit"
                                className="w-full py-2 px-4 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-semibold rounded-md shadow-lg transition-all duration-300 ease-in-out hover:scale-105"
                            >
                                Next
                            </button>
                            {errorMessage && (
                                <div className="mt-4 sm:mt-6 bg-red-100 p-4 rounded-lg shadow-lg">
                                    <h3 className="text-lg sm:text-xl font-bold text-red-700">Error:</h3>
                                    <p className="text-red-600">{errorMessage}</p>
                                </div>
                            )}
                        </form>
                    </div>

                </div>

            </div>
        </>
    );
}

export default CreateChatbotJSON;