import React, { useState } from 'react';
import axios from 'axios';

const VerifyEmail = () => {
    const [isVerified, setIsVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Properly extracting uid and token from URL params
    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get('uid'); // Correct way to get uid
    const token = urlParams.get('token'); // Correct way to get token

    const handleVerify = async () => {
        setLoading(true);

        try {
            const response = await axios.post(
                'https://api-bny5h3g2lq-uc.a.run.app/api/verify-email',
                { token }
            );

            setIsVerified(true);
            setError(null);
            alert('Email verified successfully!');
        } catch (error) {
            console.error('Error verifying email:', error);
            setError('Failed to verify email. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="bg-white/10 shadow-xl rounded-3xl overflow-hidden w-full md:w-1/2 lg:w-1/3 p-8">
                <div className="text-center mb-6">
                    <img
                        src="https://res.cloudinary.com/dbe2fast6/image/upload/v1729357377/logo-botanion-01_us1eyb.svg"
                        alt="logo"
                        className="w-14 h-14 mx-auto"
                    />
                </div>

                <h2 className="text-3xl font-bold text-center text-purple-300 mb-6">
                    Verify Your Email
                </h2>

                {isVerified ? (
                    <p className="text-center text-white text-lg">
                        Your email has been verified! You can now log in.
                    </p>
                ) : (
                    <div>
                        <p className="text-center text-gray-200 mb-4">
                            Click the button below to verify your email address.
                        </p>

                        <button
                            onClick={handleVerify}
                            disabled={loading}
                            className={`w-full bg-purple-600 text-white py-3 rounded-lg font-semibold transition duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-700'
                                }`}
                        >
                            {loading ? 'Verifying...' : 'Verify Email'}
                        </button>

                        {error && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mt-4">
                                <span>{error}</span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default VerifyEmail;
