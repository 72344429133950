import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BiChevronDown, BiMenu, BiX } from 'react-icons/bi'; // Import BiMenu for hamburger icon
import Cookies from 'js-cookie';
import { getSecureCookie, removeAllCookies } from '../utilities/safeSaves';

function Navbar() {
    const [token, setToken] = useState(null);
    const [username, setUsername] = useState(null);
    const [profilePicUrl, setProfilePicUrl] = useState("https://th.bing.com/th/id/OIP.Yaficbwe3N2MjD2Sg0J9OgHaHa?rs=1&pid=ImgDetMain");
    const [plan, setPlan] = useState("https://th.bing.com/th/id/OIP.Yaficbwe3N2MjD2Sg0J9OgHaHa?rs=1&pid=ImgDetMain");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); // State for mobile menu

    // Fetch the token, username, and profile picture from local storage
    useEffect(() => {
        const token = getSecureCookie('accessToken');
        const username = getSecureCookie('username');
        const plan = getSecureCookie('plan');
        const profilePicUrl = localStorage.getItem('profilePicUrl');

        setToken(token);
        setUsername(username);
        setPlan(plan);
        if (profilePicUrl) {
            setProfilePicUrl(profilePicUrl);
        }
    }, []); // Run this only once when the component mounts

    useEffect(() => {
        const handleClickOutside = (event) => {
            const dropdown = document.getElementById('dropdown');
            const menu = document.getElementById('mobile-menu');
            if (dropdown && !dropdown.contains(event.target)) {
                if (menu && !menu.contains(event.target)) {
                    setDropdownOpen(false);
                }
            }

        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLinkClick = () => {
        setMenuOpen(false);   // Close the mobile menu
        setDropdownOpen(false); // Close the dropdown
    };

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const uid = getSecureCookie('uid');

    // Toggle mobile menu visibility
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const location = useLocation();

    console.log(location.pathname)

    // Function to apply active styling based on current path
    const getActiveClass = (path) =>
        location.pathname === path ? "active" : "";


    return (
        <nav className="fixed top-0 left-0 w-screen bg-white shadow-md py-2 z-50">
            <div className="container mx-auto px-4 flex justify-between items-center">
                {/* Logo and Botanion name */}
                <div className="flex items-center space-x-2 text-2xl font-bold text-purple-600">
                    <img
                        src='https://res.cloudinary.com/dbe2fast6/image/upload/v1728982612/logo-botanion-01_us1eyb.svg'
                        alt="Botanion Logo"
                        className="w-10 h-10 object-contain"
                    />
                    <Link to="/" className="hover:text-pink-600">
                        Botanion
                    </Link>
                </div>

                {/* Hamburger Menu for Mobile */}
                <button onClick={toggleMenu} className="md:hidden text-gray-700">
                    {menuOpen ? (
                        <BiX className="w-8 h-8" /> // Show X icon when menu is open
                    ) : (
                        <BiMenu className="w-8 h-8" /> // Show hamburger icon when menu is closed
                    )}
                </button>


                {/* Navigation Links */}
                <ul className={`md:flex space-x-8 text-lg font-medium items-center ${menuOpen ? 'hidden' : 'hidden'} md:block`}>
                    <li>
                        <Link to="/" className={`${getActiveClass("/")} text-gray-700 hover:text-pink-600 nav-link`}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/about" className={`${getActiveClass("/about")} text-gray-700 hover:text-pink-600 nav-link`}>
                            About
                        </Link>
                    </li>
                    <li>
                        <Link to="/pricing" className={`${getActiveClass("/pricing")} text-gray-700 hover:text-pink-600 nav-link`}>
                            Pricing
                        </Link>
                    </li>
                    <li>
                        <Link to="/docs" className={`${getActiveClass("/docs")} text-gray-700 hover:text-pink-600 nav-link`}>
                            Docs
                        </Link>
                    </li>
                    {token ? (
                        <li className="relative flex items-center space-x-2" onClick={toggleDropdown}>
                            <div className="flex items-center space-x-2 bg-gray-100 rounded-full cursor-pointer p-px">
                                {profilePicUrl && (
                                    <img
                                        src={profilePicUrl}
                                        alt="Profile"
                                        className="w-10 h-10 rounded-full object-cover shadow-lg border-2 border-white"
                                    />
                                )}
                                <div className="flex flex-col">
                                    <span className="text-gray-700 font-semibold">Howdy, {username}!</span>
                                    <span className="text-gray-500 text-sm">{plan}</span>
                                </div>
                                <BiChevronDown className="text-gray-700" />
                            </div>
                            {dropdownOpen && (
                                <div
                                    id="dropdown"
                                    className="absolute right-0 mt-40 w-48 bg-white rounded-md shadow-lg z-10"
                                >
                                    <ul className="py-2">
                                        <li>
                                            <Link to="/my-bots" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                                                My Bots
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/settings" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                                                Settings
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`${uid}/usage`}
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                            >
                                                Usage
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={() => {
                                                    localStorage.clear();
                                                    removeAllCookies();
                                                    window.location.reload();
                                                }}
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                            >
                                                Log Out
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </li>
                    ) : (
                        <li>
                            <Link
                                to="/login"
                                className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition duration-300"
                            >
                                Log In
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
            {/* Mobile Menu Toggle */}
            {menuOpen && (
                <div id="mobile-menu" className="md:hidden bg-white shadow-lg rounded-md mt-2">

                    {/* Main Navigation Links */}
                    <ul className="py-2">
                        <li>
                            <Link to="/" onClick={handleLinkClick} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to="/about" onClick={handleLinkClick} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                                About
                            </Link>
                        </li>
                        <li>
                            <Link to="/pricing" onClick={handleLinkClick} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                                Pricing
                            </Link>
                        </li>
                        <li>
                            <Link to="/docs" onClick={handleLinkClick} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                                Docs
                            </Link>
                        </li>
                    </ul>
                    {token && <div className="flex flex-col items-start px-4 py-2 border-b">
                        <div className="flex items-center justify-between w-full" onClick={toggleDropdown}>
                            <div className="flex flex-col">
                                <span className="text-gray-800 font-semibold text-base">Howdy, {username}!</span>
                                <span className="text-gray-600 text-sm">{plan}</span>
                            </div>
                            {/* Change icon based on dropdown state */}
                            <BiChevronDown className={`text-gray-700 transition-transform ${dropdownOpen ? 'transform rotate-180' : ''}`} />
                        </div>
                    </div>}

                    {/* Dropdown for user options */}
                    {token && dropdownOpen && (
                        <ul className="py-2">
                            <li>
                                <Link to="/my-bots" onClick={handleLinkClick} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                                    My Bots
                                </Link>
                            </li>
                            <li>
                                <Link to="/settings" onClick={handleLinkClick} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                                    Settings
                                </Link>
                            </li>
                            <li>
                                <Link to={`${uid}/usage`} onClick={handleLinkClick} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                                    Usage
                                </Link>
                            </li>
                            <li>
                                <Link
                                    onClick={() => {
                                        localStorage.clear();
                                        removeAllCookies();
                                        window.location.reload();
                                        handleLinkClick();
                                    }}
                                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                >
                                    Log Out
                                </Link>
                            </li>
                        </ul>
                    )}

                    {/* Login Link */}
                    {!token && (
                        <div className="py-2">
                            <Link
                                to="/login"
                                onClick={handleLinkClick}
                                className="block px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition duration-300"
                            >
                                Log In

                            </Link>
                        </div>
                    )}
                </div>
            )}
        </nav>
    );
}

export default Navbar;
