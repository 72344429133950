import React from 'react';
import { Link } from 'react-router-dom';
import { FaBrain, FaKeyboard, FaCogs, FaBriefcase } from 'react-icons/fa'; // Icons for visual appeal
import { Helmet } from 'react-helmet-async';

function BotTypeSelection() {
    return (
        <div>
            <Helmet>
                <title>Select Your Service Type | Botanion</title>
                <meta name="description" content="Choose the perfect AI chatbot type tailored to your business needs." />
                <meta name="keywords" content="Botanion, chatbot, AI-powered bot, business suite, bot selection" />

                {/* Open Graph tags */}
                <meta property="og:title" content="Select Your Service Type | Botanion" />
                <meta property="og:description" content="Choose the perfect AI chatbot type tailored to your business needs." />
                <meta property="og:image" content="https://res.cloudinary.com/dbe2fast6/image/upload/v1728982601/easy-integration_sbl9qz.png" />  {/* Provide a valid URL to an image */}
                <meta property="og:url" content="https://www.botanion.cc/select-service" />
                <meta property="og:type" content="website" />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Select Your Service Type | Botanion" />
                <meta name="twitter:description" content="Choose the perfect AI chatbot type tailored to your business needs." />
                <meta name="twitter:image" content="https://res.cloudinary.com/dbe2fast6/image/upload/v1728982601/easy-integration_sbl9qz.png" />  {/* Provide a valid URL to an image */}
            </Helmet>

            <div className="container mx-auto px-6 py-12 text-center">
                <h1
                    className="text-4xl uppercase font-extrabold text-white mb-6 mt-6"
                    style={{ textShadow: '4px 4px 8px rgba(0, 0, 0, 0.4)' }}
                >
                    Select Your Service
                </h1>
                <p className="text-lg text-gray-300 mb-12">
                    Choose the service that fits your business goals perfectly.
                </p>

                <div className="flex flex-wrap justify-center gap-8">
                    {/* Keyword-Based Bot Card */}
                    {/* <div className="bg-white/10 border-2 border-gray-100 backdrop-blur-md p-8 rounded-2xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl w-80">
                        <div className="flex justify-center mb-6">
                            <FaKeyboard className="text-5xl text-yellow-400" />
                        </div>
                        <h2 className="text-2xl font-bold text-white mb-4">Keyword-Based Bot</h2>
                        <p className="text-gray-300 mb-6">
                            Respond to common queries with keywords. Great for FAQs and basic interactions.
                        </p>
                        <Link to="/create-chatbot/keyword">
                            <button className="w-full py-3 bg-yellow-500 hover:bg-yellow-400 text-white rounded-lg font-semibold transition-colors">
                                Select
                            </button>
                        </Link>
                    </div> */}

                    {/* Rule-Based Bot Card */}
                    {/* <div className="bg-white/10 border-2 border-gray-100 backdrop-blur-md p-8 rounded-2xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl w-80">
                        <div className="flex justify-center mb-6">
                            <FaCogs className="text-5xl text-pink-400" />
                        </div>
                        <h2 className="text-2xl font-bold text-white mb-4">Rule-Based Bot</h2>
                        <p className="text-gray-300 mb-6">
                            Guide users through decision flows with logical rules and responses.
                        </p>
                        <Link to="/create-chatbot/rule">
                            <button className="w-full py-3 bg-pink-500 hover:bg-pink-400 text-white rounded-lg font-semibold transition-colors">
                                Select
                            </button>
                        </Link>
                    </div> */}

                    {/* AI-Powered Bot Card */}
                    <div className="bg-white/10 border-2 border-gray-100 backdrop-blur-md p-8 rounded-2xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl w-80">
                        <div className="flex justify-center mb-6">
                            <FaBrain className="text-5xl text-green-400" />
                        </div>
                        <h2 className="text-2xl font-bold text-white mb-4">AI-Powered Bot</h2>
                        <p className="text-gray-300 mb-6">
                            Leverage AI for dynamic, personalized responses that understand intent.
                        </p>
                        <Link to="/create-chatbot/ai">
                            <button className="w-full py-3 bg-green-500 hover:bg-green-400 text-white rounded-lg font-semibold transition-colors">
                                Select
                            </button>
                        </Link>
                    </div>

                    {/* Business Suite Bot Card (Coming Soon) */}
                    <div className="relative bg-white/10 border-2 border-gray-100 backdrop-blur-md p-8 rounded-2xl shadow-lg w-80">
                        {/* Coming Soon Tag */}
                        <span className="absolute top-4 right-4 bg-yellow-500 text-black text-xs font-bold py-1 px-2 rounded-lg">
                            Coming Soon
                        </span>

                        <div className="flex justify-center mb-6">
                            <FaBriefcase className="text-5xl text-purple-400" />
                        </div>
                        <h2 className="text-2xl font-bold text-white mb-4">AI Business Suite</h2>
                        <p className="text-gray-300 mb-6">
                            Powerful tools and integrations for seamless, enterprise-level engagement
                        </p>
                        <button
                            className="w-full py-3 bg-gray-600 text-white rounded-lg font-semibold cursor-not-allowed"
                            disabled
                        >
                            Coming Soon
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BotTypeSelection;
