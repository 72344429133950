import React from 'react';
import { FaUpload, FaLink, FaCrown, FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { getSecureCookie } from '../utilities/safeSaves';
import { Helmet } from 'react-helmet-async';

const plan = getSecureCookie('plan');
const uid = getSecureCookie('uid');

function CreateAIBot({ isNotPremium, userHasPremium, userHasManualPremium }) {
    return (
        <div className="container mx-auto px-6 py-12 text-center">
            <Helmet>
                <title>Create Your AI-Powered Bot | Botanion</title>
                <meta name="description" content="Create an AI-powered chatbot with ease using automated or manual training options. Start building your bot today!" />
                <meta name="keywords" content="AI bot, chatbot, automated training, manual training, create AI bot, Botanion" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="http://localhost:3000/create-chatbot/ai" />
            </Helmet>
            <h1 className="text-4xl font-extrabold text-white mb-6 mt-4">
                Create Your AI-Powered Bot
            </h1>

            <div className="flex flex-wrap justify-center gap-8">
                {/* Automated Training Card */}
                <div className='bg-white/10 border-2 border-yellow-500 backdrop-blur-md p-8 rounded-2xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl w-80 relative'>
                    {isNotPremium && !userHasPremium && (
                        <div className="absolute top-2 right-2 text-yellow-500 text-lg flex items-center">
                            <FaCrown className="mr-1 opacity-100" />
                            <span className="font-bold">Popular</span>
                        </div>
                    )}
                    <div className={`${isNotPremium && !userHasPremium ? 'opacity-50' : 'opacity-100'}`}>
                        <div className="flex justify-center mb-6">
                            <FaLink className="text-5xl text-blue-400" />
                        </div>
                        <h2 className="text-2xl font-bold text-white mb-4">Automated Training</h2>
                        <p className="text-gray-300 mb-6">
                            Use a URL for automated training of your AI bot.
                        </p>
                        <Link to="/create-chatbot/ai-url">
                            <button className="w-full py-3 bg-blue-500 hover:bg-blue-400 text-white rounded-lg font-semibold transition-colors">
                                Set Up with URL
                            </button>
                        </Link>
                    </div>
                </div>

                {/* Manual Training Card */}
                <div className="bg-white/10 border-2 border-gray-100 backdrop-blur-md p-8 rounded-2xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl w-80 relative">
                    <div className={`${isNotPremium && !userHasManualPremium ? 'opacity-50' : 'opacity-100'}`}>
                        <div className="flex justify-center mb-6">
                            <FaUpload className="text-5xl text-yellow-400" />
                        </div>
                        <h2 className="text-2xl font-bold text-white mb-4">Manual Training</h2>
                        <p className="text-gray-300 mb-6">
                            Train your bot with custom data by entering custom data.
                        </p>
                        <Link to="/create-chatbot/ai-manual">
                            <button className="w-full py-3 bg-yellow-500 hover:bg-yellow-400 text-white rounded-lg font-semibold transition-colors">
                                Proceed with manual data
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            {/* Combined Notice for Basic Users */}
            {isNotPremium && (!userHasPremium || !userHasManualPremium) && plan === 'basic' && (
                <div className="text-md text-gray-900 mt-3 mb-6 bg-yellow-100 border border-yellow-900 rounded-md p-6 font-semibold shadow-md max-w-xl mx-auto flex items-center space-x-2">
                    <FaExclamationTriangle className="text-yellow-900 text-2xl" />
                    <p>
                        <strong className="text-lg text-yellow-900">Notice:</strong> Bot creation is
                        <span className="bg-green-700 text-white font-extrabold text-xl px-2 py-1 rounded-lg mx-1">
                            COMPLETELY FREE !
                        </span>Credits are only used for <span className="text-yellow-900 font-bold">chat messaging</span>. However, as a <span className="text-blue-700 font-bold">basic user</span>, a <span className="text-red-600 font-bold">one-time minimum recharge</span> is required to enable both manual and automated training options. To access <span className="font-bold">Automated Training</span>, a minimum of <span className="bg-yellow-700 text-white font-bold text-base px-2 py-1 rounded-lg mx-1">350 credits</span> is required, while <span className="font-bold">Manual Training</span> requires <span className="bg-yellow-700 text-white font-bold text-base px-2 py-1 rounded-lg mx-1">150 credits</span>. <Link to={`/${uid}/usage`} className='text-blue-600 underline font-bold'>Recharge Now</Link>
                    </p>
                </div>
            )}

            {/* Pricing Calculator Button */}
            <div className="mt-8">
                <Link to="/pricing-calculator">
                    <button className="w-1/2 py-3 mb-6 bg-green-500 hover:bg-green-400 text-white rounded-lg font-semibold transition-colors">
                        Open Pricing Calculator
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default CreateAIBot;
