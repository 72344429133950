import React, { useState } from 'react';
import axios from 'axios';

const RequestPasswordReset = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');
        setLoading(true);

        try {
            const response = await axios.post('https://api-bny5h3g2lq-uc.a.run.app/api/request-password-reset', { email });
            setMessage(response.data.message);
        } catch (err) {
            setError(err.response?.data?.error || 'Something went wrong. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="bg-white/10 shadow-xl rounded-3xl overflow-hidden h-auto w-11/12 md:w-1/2 lg:w-1/3 p-10">
                <div className="flex flex-col items-center">
                    <img
                        src="https://res.cloudinary.com/dbe2fast6/image/upload/v1729357377/logo-botanion-01_us1eyb.svg"
                        alt="logo"
                        className="w-14 h-14 mb-4"
                    />
                    <h2 className="text-3xl font-bold text-center text-purple-300 mb-2">
                        Reset Your Password
                    </h2>
                    <p className="text-center text-gray-200 mb-6">
                        Enter your email to receive a password reset link.
                    </p>

                    <form onSubmit={handleSubmit} className="w-full">
                        {/* Error Message */}
                        {error && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                                <span>{error}</span>
                            </div>
                        )}

                        {/* Success Message */}
                        {message && (
                            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
                                <span>{message}</span>
                            </div>
                        )}

                        <div className="mb-4">
                            <label className="block text-gray-200 font-medium">Email</label>
                            <input
                                type="email"
                                className="w-full p-3 border border-gray-300 rounded-2xl bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-600"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>

                        <button
                            type="submit"
                            className={`w-full bg-purple-600 text-white py-3 rounded-lg font-semibold transition duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-700'
                                }`}
                            disabled={loading}
                        >
                            {loading ? 'Sending...' : 'Send Reset Link'}
                        </button>
                    </form>

                    <p className="text-center mt-6 text-gray-200">
                        Remembered your password?{' '}
                        <a href="/login" className="text-purple-400 font-bold">
                            Login
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RequestPasswordReset;
