import React from 'react';
import { FaRobot } from 'react-icons/fa';

const IconicHeader = ({ currentTheme, botName }) => {
    return (
        <div className="relative z-10 flex items-center justify-center p-5 text-xl font-bold text-white rounded-t-lg"
            style={{ backgroundColor: currentTheme.headerBg }}>
            <FaRobot className="mr-2" />
            <span>{botName}</span>
        </div>
    );
};

export default IconicHeader;