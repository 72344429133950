import React, { useRef } from 'react';
import { FaCloud, FaCog, FaUsers, FaShieldAlt, FaRocket, FaChartLine } from 'react-icons/fa';
import Footer from './Footer';
import { motion } from 'framer-motion';
import useScrollAnimation from '../utilities/useScrollAnimation'; // Import custom scroll hook
import { Helmet } from 'react-helmet-async';

function AboutPage() {
    // Refs for sections
    const titleRef = useRef(null);
    const missionVisionRef = useRef(null);
    const featuresRef = useRef(null);

    // Animation states based on scroll
    const isTitleVisible = useScrollAnimation(titleRef);
    const isMissionVisionVisible = useScrollAnimation(missionVisionRef);
    const isFeaturesVisible = useScrollAnimation(featuresRef);

    return (
        <div className="min-h-screen py-10 mt-10">
            <Helmet>
                <title>About Botanion | Advanced Chatbot Solutions</title>
                <meta
                    name="description"
                    content="Learn about Botanion, a leading provider of advanced chatbot solutions to enhance customer engagement and streamline business communication."
                />
                <meta name="keywords" content="Botanion, chatbot, AI solutions, business engagement, advanced chatbot" />
                <meta property="og:title" content="About Botanion | Advanced Chatbot Solutions" />
                <meta
                    property="og:description"
                    content="Explore Botanion's mission, vision, and features that empower businesses with intelligent chatbot solutions."
                />
                <meta property="og:image" content="https://res.cloudinary.com/dbe2fast6/image/upload/v1728982612/logo-botanion-01_us1eyb.svg" />
                <meta property="og:url" content="https://www.botanion.cc/about" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.botanion.cc/about" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "http://schema.org",
                        "@type": "Organization",
                        "name": "Botanion",
                        "url": "https://www.botanion.cc/",
                        "logo": "https://res.cloudinary.com/dbe2fast6/image/upload/v1728982612/logo-botanion-01_us1eyb.svg",
                        "sameAs": [
                            "https://www.facebook.com/botanion",
                            "https://twitter.com/botanion"
                        ],
                        "description": "Botanion provides advanced AI-powered chatbot solutions for enhancing customer engagement and business efficiency.",
                    })}
                </script>
            </Helmet>
            <div className="container mx-auto px-4 md:px-6 lg:px-8">

                {/* Title Section */}
                <motion.header
                    ref={titleRef}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isTitleVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                    transition={{ duration: 0.5 }}
                >
                    <h1
                        className="uppercase text-3xl md:text-5xl font-bold text-white text-center mb-6"
                        style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}
                    >
                        About Us
                    </h1>
                    <p className="text-lg md:text-xl text-gray-300 text-center mb-10 max-w-2xl lg:max-w-4xl mx-auto leading-relaxed px-4">
                        At <span className="font-bold text-pink-400">Botanion</span>, we empower businesses with advanced chatbot solutions, enhancing customer engagement and streamlining communication across industries.
                    </p>
                </motion.header>

                {/* Mission & Vision Section */}
                <section
                    ref={missionVisionRef}
                    className="relative bg-cover bg-center rounded-3xl mb-10 min-h-[50vh]"
                    style={{
                        backgroundImage: `url('https://res.cloudinary.com/dbe2fast6/image/upload/v1729532997/freepik__candid-image-photography-natural-textures-highly-r__42634_jotcoi.jpg')`,
                    }}
                >
                    {/* Background Overlay */}
                    <div className="absolute inset-0 bg-gradient-to-br from-black/90 via-gray-900/80 to-gray-800/90 rounded-3xl"></div>
                    <motion.article
                        initial={{ opacity: 0, y: 50 }}
                        animate={isMissionVisionVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                        transition={{ duration: 0.5 }}
                        className="relative grid grid-cols-1 lg:grid-cols-3 gap-8 px-4 lg:px-12 py-10 lg:py-16"
                    >
                        {/* Mission Card */}
                        <div className="bg-white/5 backdrop-blur-lg p-8 md:p-10 rounded-2xl shadow-xl text-center lg:text-left text-white z-10 flex flex-col justify-center">
                            <h3 className="text-2xl md:text-3xl font-bold text-pink-400 uppercase mb-4">Our Mission</h3>
                            <p className="text-base md:text-lg leading-relaxed">
                                To provide advanced, customizable, and cost-effective AI solutions that empower businesses to enhance services, optimize operations, and ensure top-notch security and performance.
                            </p>
                        </div>

                        {/* Logo Centered on Medium Screens */}
                        <div className="flex justify-center items-center">
                            <img
                                src="https://res.cloudinary.com/dbe2fast6/image/upload/v1728982612/logo-botanion-01_us1eyb.svg"
                                alt="Botanion Logo"
                                className="w-24 md:w-40 lg:w-48 object-contain drop-shadow-lg"
                            />
                        </div>

                        {/* Vision Card */}
                        <div className="bg-white/5 backdrop-blur-lg p-8 md:p-10 rounded-2xl shadow-xl text-center lg:text-left text-white z-10 flex flex-col justify-center">
                            <h3 className="text-2xl md:text-3xl font-bold text-pink-400 uppercase mb-4">Our Vision</h3>
                            <p className="text-base md:text-lg leading-relaxed">
                                To lead the AI industry with innovative solutions that redefine business interactions, seamlessly integrating intelligent technology into everyday operations.
                            </p>
                        </div>
                    </motion.article>
                </section>

                {/* Why Choose Us Title */}
                <motion.h2
                    ref={featuresRef}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isFeaturesVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                    transition={{ duration: 0.5 }}
                    className="text-3xl md:text-4xl font-semibold text-white text-center mb-10"
                >
                    Why Choose Us?
                </motion.h2>

                {/* Feature Cards */}
                <article className="flex flex-wrap justify-center gap-6 md:gap-8 mb-16 px-4">
                    {[
                        {
                            icon: <FaCloud className="text-4xl md:text-5xl text-blue-400" />,
                            title: "Cloud Storage Utilization",
                            description: "We utilize cloud services to store data securely, providing scalability and reliability for your chatbot solutions."
                        },
                        {
                            icon: <FaCog className="text-4xl md:text-5xl text-green-400" />,
                            title: "Easy Customization",
                            description: "Tailor your chatbot's design and functionality without needing any coding skills."
                        },
                        {
                            icon: <FaUsers className="text-4xl md:text-5xl text-orange-400" />,
                            title: "User-Friendly Interface",
                            description: "Our intuitive interface ensures that anyone can create and manage a chatbot effortlessly."
                        },
                        {
                            icon: <FaShieldAlt className="text-4xl md:text-5xl text-red-400" />,
                            title: "Robust Security",
                            description: "We prioritize your data's safety with industry-leading security measures and encryption."
                        },
                        {
                            icon: <FaRocket className="text-4xl md:text-5xl text-purple-400" />,
                            title: "Rapid Deployment",
                            description: "Get your chatbot up and running in no time, enhancing your customer support instantly."
                        },
                        {
                            icon: <FaChartLine className="text-4xl md:text-5xl text-teal-400" />,
                            title: "Performance Analytics",
                            description: "Access insights into chatbot interactions to continually optimize performance and engagement."
                        }
                    ].map((feature, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={isFeaturesVisible ? { opacity: 1, scale: 1 } : { opacity: 1, scale: 0.9 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            className="bg-white/10 border border-gray-700 backdrop-blur-lg p-8 md:p-10 rounded-xl shadow-lg transition-transform transform hover:scale-105 w-full sm:w-72 md:w-80 text-center"
                        >
                            <div className="flex justify-center mb-4">{feature.icon}</div>
                            <h3 className="text-xl md:text-2xl font-bold text-white mb-4">{feature.title}</h3>
                            <p className="text-sm md:text-base text-gray-300 leading-relaxed">{feature.description}</p>
                        </motion.div>
                    ))}
                </article>
            </div>
            <Footer />
        </div>
    );
}

export default AboutPage;
