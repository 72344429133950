import React from 'react';

const StandardHeader = ({ currentTheme, botName }) => {
    return (
        <div className="relative z-10 flex items-center justify-center p-5 text-xl font-bold text-white rounded-t-lg"
            style={{ backgroundColor: currentTheme.headerBg }}>
            <span>{botName}</span>
        </div>
    );
};

export default StandardHeader;