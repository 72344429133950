import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const SuccessFailedModal = ({ isSuccess, message, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-8 text-center w-11/12 max-w-md mx-auto">
                <FontAwesomeIcon
                    icon={isSuccess ? faCheckCircle : faTimesCircle}
                    className={`text-7xl ${isSuccess ? 'text-green-500' : 'text-red-500'}`}
                />
                <h1 className="mt-4 text-2xl font-semibold">
                    {message}
                </h1>
                <button
                    onClick={onClose}
                    className="mt-6 px-6 py-2 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 transition-colors duration-200"
                >
                    Okay
                </button>
            </div>
        </div>
    );
};

export default SuccessFailedModal;
