// ChatInput.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MinimalChatInput = ({
    searchQuery,
    setSearchQuery,
    sendIcon,
    currentTheme
}) => {
    return (
        <form
            // onSubmit={handleSearchSubmit}
            className="flex items-center p-2 border-t"
        >
            <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="flex-1 p-2 mb-2 border rounded-lg shadow-md"
                style={{
                    borderColor: currentTheme.inputBorder,
                    color: currentTheme.inputTextColor,
                }}
                placeholder="Type a message..."
            />
            <button
                // type="submit"
                type="button"
                className="ml-2 p-2 mb-2 text-white rounded-lg shadow-md"
                style={{
                    backgroundColor: currentTheme.buttonBg,
                }}
                onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                    currentTheme.buttonHoverBg)
                }
                onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                    currentTheme.buttonBg)
                }
            >
                <FontAwesomeIcon icon={sendIcon} size="lg" />
            </button>
        </form>
    );
};

export default MinimalChatInput;
