// ChatInput.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StandardChatInput = ({
    searchQuery,
    setSearchQuery,
    sendIcon,
    currentTheme
}) => {
    return (
        <form
            className="flex items-center p-4 bg-gray-100 rounded-b-lg"
            style={{ backgroundColor: currentTheme.footerBg }}
        >
            <input
                type="text"
                className="flex-grow p-2 mr-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-500"
                value={searchQuery}
                style={{
                    borderColor: currentTheme.inputBorder,
                    color: currentTheme.inputTextColor,
                }}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Type your message here..."
            />
            <button
                type="submit"
                className="ml-2 p-2 text-white rounded-lg shadow-md"
                style={{
                    backgroundColor: currentTheme.buttonBg,
                }}
                onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                    currentTheme.buttonHoverBg)
                }
                onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                    currentTheme.buttonBg)
                }
            >
                <FontAwesomeIcon icon={sendIcon} size="lg" />
            </button>
        </form>
    );
};

export default StandardChatInput;
