import React, { useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();
        setError('');
        setMessage('');


        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const token = searchParams.get('token');
            const uid = searchParams.get('uid');

            const response = await axios.post('https://api-bny5h3g2lq-uc.a.run.app/api/reset-password', {
                uid,
                token,
                newPassword,
            });
            setLoading(false);
            setMessage(response.data.message);
        } catch (err) {
            setError(err.response?.data?.error || 'Something went wrong. Please try again.');
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="w-11/12 max-w-md bg-white/10 p-8 rounded-3xl shadow-lg backdrop-blur-lg">
                <div className="flex flex-col items-center">
                    <img
                        src="https://res.cloudinary.com/dbe2fast6/image/upload/v1729357377/logo-botanion-01_us1eyb.svg"
                        alt="logo"
                        className="w-14 h-14 mb-4"
                    />
                    <h2 className="text-4xl font-bold text-center text-white mb-6">Reset Password</h2>
                </div>

                {/* Error and Success Messages */}
                {message && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded-lg mb-4">
                        {message}
                    </div>
                )}
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded-lg mb-4">
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-gray-200 font-medium mb-2">New Password</label>
                        <input
                            type="password"
                            className="w-full p-3 border border-gray-300 rounded-2xl bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-500"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-gray-200 font-medium mb-2">Confirm Password</label>
                        <input
                            type="password"
                            className="w-full p-3 border border-gray-300 rounded-2xl bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-500"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-purple-600 text-white py-3 rounded-lg font-semibold transition duration-300 hover:bg-purple-700"
                    >
                        {loading ? 'Resetting' : 'Reset'}
                    </button>
                </form>

                <p className="text-center mt-6 text-gray-300">
                    Back to{' '}
                    <a href="/login" className="text-purple-400 font-bold hover:underline">
                        Login
                    </a>
                </p>
            </div>
        </div>
    );
};

export default ResetPassword;
