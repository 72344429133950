import { useEffect, useState } from 'react';
import StepIndicator from './StepIndicator';

// Example template data
const templates = [
    { id: 1, name: 'Modern Bot', description: 'A sleek, modern chatbot template.', variations: Array(5).fill('https://i.ibb.co/y6p0d2C/Whats-App-Image-2024-10-15-at-20-38-09.jpg') },
    { id: 2, name: 'Classic Bot', description: 'A traditional template with classic features.', variations: Array(5).fill('https://i.ibb.co/y6p0d2C/Whats-App-Image-2024-10-15-at-20-38-09.jpg') },
    { id: 3, name: 'Minimal Bot', description: 'A simple and elegant template.', variations: Array(5).fill('https://i.ibb.co/y6p0d2C/Whats-App-Image-2024-10-15-at-20-38-09.jpg') },
    { id: 4, name: 'Elegant Bot', description: 'An elegant and refined chatbot template.', variations: Array(5).fill('https://i.ibb.co/y6p0d2C/Whats-App-Image-2024-10-15-at-20-38-09.jpg') },
];

export default function TemplateShowcase() {
    const [currentVariations, setCurrentVariations] = useState(templates.map(() => 0));

    // Auto-slide variations every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentVariations((prev) =>
                prev.map((currentIndex, i) => (currentIndex + 1) % templates[i].variations.length)
            );
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    const handleCustomize = (templateId) => {
        window.location.href = `/chatBot/${templateId}/customize`;
    };

    const handleDotClick = (templateIndex, variationIndex) => {
        setCurrentVariations((prev) => {
            const newVariations = [...prev];
            newVariations[templateIndex] = variationIndex;
            return newVariations;
        });
    };

    return (
        <>
            <h1
                className="text-4xl text-center uppercase text-white font-extrabold mb-6"
                style={{ textShadow: '4px 4px 6px rgba(0, 0, 0, 0.8)' }}
                data-aos="fade-up"
                data-aos-duration="1000"
            >
                Choose Your Chatbot Template
            </h1>

            <StepIndicator currentStep={3} />

            {/* Grid Layout for Templates */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-6">
                {templates.map((template, index) => (
                    <div
                        key={template.id}
                        className="p-3 relative bg-white rounded-3xl shadow-xl overflow-hidden flex flex-col"
                    >
                        {/* Template Variation Image */}
                        <div style={{ height: '100%', width: 'auto' }}>
                            <img
                                src={template.variations[currentVariations[index]]}
                                alt={`${template.name} Variation`}
                                className="object-cover rounded-t-3xl"
                            />
                        </div>

                        {/* Dots Navigation */}
                        <div className="flex justify-center mt-2">
                            {template.variations.map((_, variationIndex) => (
                                <button
                                    key={variationIndex}
                                    onClick={() => handleDotClick(index, variationIndex)}
                                    className={`h-2 w-2 mx-1 rounded-full ${variationIndex === currentVariations[index]
                                        ? 'bg-purple-500'
                                        : 'bg-gray-300'
                                        }`}
                                />
                            ))}
                        </div>

                        {/* Template Info */}
                        <div className="p-4 text-center flex-grow">
                            <h2 className="text-2xl font-semibold text-gray-700 mb-2">
                                {template.name}
                            </h2>
                            <p className="text-gray-500 mb-4">{template.description}</p>

                            {/* Customize Button */}
                            <button
                                onClick={() => handleCustomize(template.id)}
                                className="mt-auto py-2 px-4 w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold rounded-lg shadow-md hover:from-pink-500 hover:to-purple-500 transition-all"
                            >
                                Customize This
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
