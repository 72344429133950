// src/components/Modal.js
import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;  // Don't render if modal is closed

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div className="relative bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-lg">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-4 text-gray-900 hover:text-gray-800"
                >
                    X
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
