import React from 'react';

const Refund = () => {
    return (
        <div className="container mx-auto my-10 p-6">
            <h1 className="text-4xl text-center font-bold text-white mb-8 uppercase" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)' }}>
                Refund Policy
            </h1>
            <div className="bg-gray-900/10 text-white p-6 rounded-lg shadow-lg space-y-6">
                <h1 className="text-3xl font-bold mb-4">Refund and Return Policy</h1>
                <p className="mb-4">
                    At <strong>Botanion</strong>, we are committed to providing quality services through our subscription and credit systems. Please read the following refund and return policy carefully before subscribing or purchasing credits.
                </p>

                <h2 className="text-2xl font-semibold mb-2">1. No Refund Policy</h2>
                <p className="mb-4">
                    Due to the nature of our digital services, <strong>Botanion does not offer refunds</strong> for any monthly or yearly subscription fees, or for credit purchases. All sales are final once processed, and refunds will not be issued for the following:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>Monthly or yearly subscription fees.</li>
                    <li>Credits purchased for use on the Botanion platform.</li>
                </ul>

                <h2 className="text-2xl font-semibold mb-2">2. Subscription Services</h2>
                <p className="mb-4">
                    <strong>Botanion</strong> offers monthly and yearly subscription plans. Once you subscribe, you are responsible for managing your subscription through your account settings.
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>Subscription changes, such as upgrades or downgrades, will take effect at the end of the current billing cycle.</li>
                    <li>Canceling your subscription will stop future charges, but services will continue until the end of the current billing period.</li>
                    <li>Refunds will not be issued for unused portions of an active subscription.</li>
                </ul>

                <h2 className="text-2xl font-semibold mb-2">3. Credit Purchases</h2>
                <p className="mb-4">
                    Credits purchased on the <strong>Botanion</strong> platform are non-refundable and non-transferable. Once purchased, credits cannot be exchanged for cash or other services.
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>Credits remain valid as long as your account is active.</li>
                    <li>Unused credits will expire if your account remains inactive for more than one year.</li>
                </ul>

                <h2 className="text-2xl font-semibold mb-2">4. Cancellation Policy</h2>
                <p className="mb-4">
                    You may cancel your monthly or yearly subscription at any time through your account settings. After cancellation:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>Future charges will be stopped, but services will remain accessible until the end of the billing period.</li>
                    <li>No refunds will be provided for the unused portion of the subscription period.</li>
                </ul>

                <h2 className="text-2xl font-semibold mb-2">5. Exceptions</h2>
                <p className="mb-4">
                    While we do not offer refunds, if you encounter any billing issues or concerns, please contact our <strong>Support Team</strong> at <a href="mailto:support@botanion.co" className="text-blue-600">support@botanion.co</a>. We will do our best to resolve the issue promptly.
                </p>

                <h2 className="text-2xl font-semibold mb-2">6. Amendments</h2>
                <p>
                    <strong>Botanion</strong> reserves the right to modify this Refund and Return Policy at any time. Changes will be posted on this page, and it is your responsibility to review the policy periodically.
                </p>
            </div>
        </div>
    )
}
export default Refund;
