import React from 'react';

const BasicHeader = ({ currentTheme, botName }) => {
    return (
        <div
            className="p-5 text-xl font-bold rounded-t-lg"
            style={{ backgroundColor: currentTheme.headerBg, color: currentTheme.headerTextColor }}
        >
            {botName}
        </div>
    );
};

export default BasicHeader;