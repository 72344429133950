import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getSecureCookie, setSecureCookie } from '../utilities/safeSaves';
import LoadingScreen from './LoadingScreen';

const Settings = () => {
    const [tab, setTab] = useState('profile'); // Default tab
    const [userProfile, setUserProfile] = useState({});
    const [loading, setLoading] = useState(true);

    const uid = getSecureCookie('uid');
    const plan = getSecureCookie('plan')
    const token = getSecureCookie('accessToken')

    useEffect(() => {
        const fetchUserProfile = async () => {
            setLoading(true); // Start loading state


            console.log(uid)
            console.log(plan)
            console.log(token)

            try {
                if (!token || !uid) {
                    throw new Error('Token or UID is missing');
                }


                const response = await axios.get(`https://api-bny5h3g2lq-uc.a.run.app/api/userProfile/${uid}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Include token in headers
                    },
                });

                setUserProfile(response.data); // Update state with fetched data
                console.log('Fetched user profile:', response.data); // Log the fetched data
            } catch (error) {
                console.error('Error fetching user profile:', error);
                // Optional: Handle specific error cases (e.g., redirect on 403)
                if (error.response && error.response.status === 403) {
                    alert('Access denied. Please log in again.'); // Notify user
                    // Additional logic for handling unauthorized access
                }
            } finally {
                setLoading(false); // Reset loading state
            }
        };

        fetchUserProfile();
    }, [uid, token, plan]); // Only depend on uid and token


    return (
        loading ? (
            <LoadingScreen textToShow={"Wait for a while..."} />
        ) : (
            <div className="flex flex-col lg:flex-row p-4 lg:p-8">
                {/* Sidebar with Tabs */}
                <div className="w-full lg:w-1/4 mb-6 lg:mb-0 mt-28">
                    <div className="bg-white rounded-lg shadow-lg p-3 mr-9">
                        <ul className="space-y-4 text-gray-700">
                            <li
                                className={`cursor-pointer py-2 px-4 rounded-md transition-colors duration-200 ${tab === 'profile'
                                    ? 'bg-blue-100 text-blue-600 font-semibold shadow'
                                    : 'hover:bg-blue-50 hover:text-blue-600'
                                    }`}
                                onClick={() => setTab('profile')}
                            >
                                Profile
                            </li>
                            <li
                                className={`cursor-pointer py-2 px-4 rounded-md transition-colors duration-200 ${tab === 'account'
                                    ? 'bg-blue-100 text-blue-600 font-semibold shadow'
                                    : 'hover:bg-blue-50 hover:text-blue-600'
                                    }`}
                                onClick={() => setTab('account')}
                            >
                                Account
                            </li>
                            <li
                                className={`cursor-pointer py-2 px-4 rounded-md transition-colors duration-200 ${tab === 'privacy'
                                    ? 'bg-blue-100 text-blue-600 font-semibold shadow'
                                    : 'hover:bg-blue-50 hover:text-blue-600'
                                    }`}
                                onClick={() => setTab('privacy')}
                            >
                                Privacy & Security
                            </li>
                            {/* Uncomment additional items as needed */}
                            {/* 
            <li
                className={`cursor-pointer py-2 px-4 rounded-md transition-colors duration-200 ${
                    tab === 'developer'
                        ? 'bg-blue-100 text-blue-600 font-semibold shadow'
                        : 'hover:bg-blue-50 hover:text-blue-600'
                }`}
                onClick={() => setTab('developer')}
            >
                API/Developer
            </li>
            */}
                            <li
                                className={`cursor-pointer py-2 px-4 rounded-md transition-colors duration-200 ${tab === 'legal'
                                    ? 'bg-blue-100 text-blue-600 font-semibold shadow'
                                    : 'hover:bg-blue-50 hover:text-blue-600'
                                    }`}
                                onClick={() => setTab('legal')}
                            >
                                Legal
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Settings Content */}
                <div className="w-full lg:w-3/4 bg-white rounded-lg p-6 shadow mt-28">

                    <h1 className="text-4xl text-center font-bold text-gray-200 mb-8 uppercase" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)' }}>
                        Settings
                    </h1>
                    {tab === 'profile' && <ProfileSettings
                        userProfile={userProfile}
                        token={token}
                        setLoading={setLoading}
                    />}
                    {tab === 'account' && <AccountSettings />}
                    {tab === 'privacy' && <PrivacySecuritySettings />}
                    {/* {tab === 'developer' && <DeveloperSettings />} */}
                    {/* {tab === 'general' && <GeneralSettings />} */}
                    {tab === 'support' && <SupportFeedback />}
                    {tab === 'legal' && <LegalInfo />}
                </div>
            </div>
        ));
};

const ProfileSettings = ({ userProfile, token, setLoading }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
    });

    // Pre-populate form fields with user profile data when userProfile changes
    useEffect(() => {
        if (userProfile) {
            setFormData({
                name: userProfile.name || '',
                email: userProfile.email || '',
                phone: userProfile.phone || '',
            });
        }
    }, [userProfile]);

    // Handle text input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle file input changes
    const handleFileChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            profilePicture: e.target.files[0], // Store the file object
        }));
    };

    // Handle form submission
    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const { name, email, phone } = formData; // Ensure profilePicture is not used if it's not being submitted

        // Validate required fields
        if (!name || !email) {
            alert('Name and email are required.');
            return; // Exit the function if validation fails
        }

        // Prepare the data as a JSON object instead of FormData
        const data = {
            name,
            email,
            phone,
        };

        console.log('Form Data before submission:', data);

        try {
            const response = await axios.put(`https://api-bny5h3g2lq-uc.a.run.app/api/userProfile/${userProfile.uid}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json', // Set this to application/json
                },
            });
            // alert('Profile updated successfully!');
            setSecureCookie('username', name, { expires: 7, secure: true })
            window.location.reload()
            setLoading(false)

        } catch (error) {
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
            }
            console.error('Error updating profile:', error);
            setLoading(false)
            alert('Failed to update profile. Please try again.');
        }
    };



    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Profile Settings</h2>
            <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="John Doe"
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder=""
                        disabled='true'
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Phone</label>
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="+1 123 456 7890"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Phone</label>
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="+1 123 456 7890"
                    />
                </div>
                {/* <div>
                    <label className="block text-sm font-medium text-gray-700">Profile Picture</label>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div> */}
                <button
                    type="submit"
                    className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Save Changes
                </button>
            </form>
        </div>
    );
};


const AccountSettings = () => {
    const currentPlan = "Premium Plan"; // You can replace this with the actual plan from user data

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Account Settings</h2>
            <form className="space-y-4">
                {/* Display Current Subscription Plan */}
                <div>
                    <label className="block text-sm font-medium text-gray-700">Subscription Plan</label>
                    <div className="mt-1 flex items-center justify-between">
                        <span className="text-lg font-semibold">{currentPlan}</span>
                        <button
                            type="button"
                            className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Change Plan / Upgrade
                        </button>
                    </div>
                </div>

                {/* Billing Information */}
                {/* <div>
                    <label className="block text-sm font-medium text-gray-700">Billing Information</label>
                    <input
                        type="text"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Credit Card"
                    />
                </div> */}

                {/* Delete Account */}
                <div>
                    <label className="block text-sm font-medium text-gray-700">Delete Account</label>
                    <button
                        type="button"
                        className="mt-1 block w-full px-4 py-2 border border-red-600 rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 sm:text-sm"
                    >
                        Delete Account
                    </button>
                </div>

            </form>
        </div>
    );
};


// Define the components that were missing

const PrivacySecuritySettings = () => {
    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Privacy & Security Settings</h2>
            <form className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Password</label>
                    <input
                        type="password"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="********"
                    />
                </div>
                {/* Add more privacy and security related fields here */}
                <button
                    type="submit"
                    className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Save Changes
                </button>
            </form>
        </div>
    );
};

// const DeveloperSettings = () => {
//     return (
//         <div>
//             <h2 className="text-xl font-bold mb-4">API / Developer Settings</h2>
//             <p>Manage your API keys and developer settings here.</p>
//             <div className="mt-4">
//                 <label className="block text-sm font-medium text-gray-700">API Key</label>
//                 <input
//                     type="text"
//                     className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                     value="Your API Key" // Replace with actual API key logic
//                     readOnly
//                 />
//                 <button
//                     className="mt-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
//                 >
//                     Regenerate API Key
//                 </button>
//             </div>
//         </div>
//     );
// };

// const GeneralSettings = () => {
//     return (
//         <div>
//             <h2 className="text-xl font-bold mb-4">General Settings</h2>
//             <p>Manage general application settings here.</p>
//             {/* Add more general settings related fields here */}
//         </div>
//     );
// };

const SupportFeedback = () => {
    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Support & Feedback</h2>
            <p>If you have any issues or feedback, please contact us.</p>
            <form className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Your Message</label>
                    <textarea
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        rows="4"
                        placeholder="Type your message here..."
                    />
                </div>
                <button
                    type="submit"
                    className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

const LegalInfo = () => {
    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Legal Information</h2>
            <div><u><a href='https://www.botanion.cc/privacy'>Privacy Policy</a></u></div>
            <div><u><a href='https://www.botanion.cc/terms'>Terms & Conditions</a></u></div>
            <div><u><a href='https://www.botanion.cc/refund'>Refund Policy</a></u></div >
            {/* Add legal terms or link to relevant documents */}
        </div>
    );
};


// Additional component examples (Notifications, Privacy & Security, etc.) follow the same structure.

export default Settings;
