import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import StepIndicator from './StepIndicator';
import Cookies from 'js-cookie';
import { getSecureCookie } from '../utilities/safeSaves';
import SuccessFailedModal from './SuccessFailPage';

function CreateChatbot() {
    const [formData, setFormData] = useState({
        chatbotName: '',
        websiteUrl: '',
        description: '',
        address: '',
        contactEmail: '',
        contactNumber: '',
    });


    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [chatbotResponse, setChatbotResponse] = useState(null);
    const [botId, setBotId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const uid = getSecureCookie('uid');

    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleAction = (actionSuccess, chatbotId) => {
        // Trigger the modal with success/failure details
        setIsSuccess(actionSuccess);
        setMessage(actionSuccess ? 'Operation completed successfully!' : 'Operation failed. Please try again.');
        if (actionSuccess) {
            handleSuccess(chatbotId)
        }
        else {
            setShowModal(true);
        }
    };

    const handleSuccess = (chatbotId) => {
        setShowModal(true);
        setBotId(chatbotId)
    }

    const closeModal = () => {
        setShowModal(false)
        if (botId !== '') {
            navigate(`/chatbot-response/${botId}`);
            setBotId('')
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage(''); // Reset error message

        // Step 1: Validate form data before proceeding
        const { chatbotName, websiteUrl, description, contactEmail } = formData;

        // Check if required fields are filled
        if (!chatbotName || !websiteUrl || !description) {
            setErrorMessage('Chatbot Name, Website URL, and Description are required.');
            setLoading(false);
            return;
        }

        // Check if the website URL is in a valid format
        let trimmedWebsiteUrl = websiteUrl.trim();  // Use 'let' here to allow reassignment

        // Check if the URL doesn't start with 'http://' or 'https://', if so, add 'https://'
        if (!/^https?:\/\//i.test(trimmedWebsiteUrl)) {
            trimmedWebsiteUrl = 'https://' + trimmedWebsiteUrl;  // Modify the 'trimmedWebsiteUrl' variable
        }

        // URL validation
        const urlPattern = /^(https?:\/\/)?([\w\d-]+\.)+[a-z]{2,6}(\/[\w\d-]*)*\/?$/;
        if (!urlPattern.test(trimmedWebsiteUrl)) {
            setErrorMessage('Please enter a valid website URL.');
            setLoading(false);
            return;
        }

        // Optionally, validate the email if provided
        if (contactEmail && !/\S+@\S+\.\S+/.test(contactEmail)) {
            setErrorMessage('Please enter a valid contact email address.');
            setLoading(false);
            return;
        }

        try {
            // Step 1: Scrape data from the website
            const scrapeResponse = await axios.post(
                'https://api-bny5h3g2lq-uc.a.run.app/scrape',
                { url: formData.websiteUrl },
                {
                    headers: {
                        Authorization: `Bearer ${getSecureCookie('accessToken')}` // Send Bearer token
                    }
                }
            );

            // Debugging: Check the response structure

            // Step 2: Prepare the scraped data for storage in the chatbot
            const scrapedData = scrapeResponse.data?.data || []; // Use an empty array if undefined

            // Check if scrapedData is valid
            if (!Array.isArray(scrapedData)) {
                throw new Error('Scraped data is not in the expected format.');
            }


            // Merge additional entries into the summary
            const contactSection = {
                title: "Contact",
                paragraphs: [
                    `Phone Number: ${formData.contactNumber || 'N/A'}`,
                    `Address: ${formData.address || 'N/A'}`,
                    `Email: ${formData.contactEmail || 'N/A'}`,
                ],
            };

            const finalScrapedData = [...scrapedData, contactSection];

            // Step 4: Store the chatbot profile in your database
            const chatbotDataResponse = await axios.post(
                'https://api-bny5h3g2lq-uc.a.run.app/api/chatbot',
                {
                    ownerName: uid, // Assuming the chatbot name is the ownerName
                    website: formData.websiteUrl, // Including the website URL from the form
                    summary: finalScrapedData, // Store the scraped data as JSON
                    description: formData.description, // Using the description from the form
                    address: formData.address || null, // Storing address (can be null)
                    email: formData.contactEmail || null, // Storing contact email (can be null)
                    phone: formData.contactNumber || null, // Storing contact number (can be null)
                    additional: null, // Add any additional data if needed
                    botName: formData.chatbotName, // Using the chatbot name as the bot name
                    customization: {},
                },
                {
                    headers: {
                        Authorization: `Bearer ${getSecureCookie('accessToken')}` // Send Bearer token
                    }
                }
            );

            const chatbotId = chatbotDataResponse.data.chatbotId;
            // Store responses
            setResponse(scrapeResponse.data);

            handleAction(true, chatbotId)

        } catch (error) {
            console.error("Error creating chatbot:", error);
            if (error.response && error.response.status === 403 && error.response.data.error === "Email not verified. Please verify your email to proceed.") {
                setErrorMessage('Email not verified. Please verify your email to proceed.')
            }
            else if (error.response && error.response.status === 403 && error.response.data.error === "Bot creation limit reached") {
                setErrorMessage('Bot creation limit reached for your plan, upgrade your account to create more!.')
            }
            else if (error.response && error.response.status === 403 && error.response.data.error === "Please Recharge Your Balance to Create Bot") {
                setErrorMessage('Please Recharge Your Balance to Create Bot!');
            }
            else if (error.response && error.response.status === 403 && error.response.data.error === "You cannot use automated training in your plan") {
                setErrorMessage('You cannot use automated training in your plan, upgrade your account to create more!.')
            }
            else if (error.response && error.response.status === 403 && error.response.data.error === "heavy site") {
                setErrorMessage("Site is heavy to scrape. Please try again.");
            }
            else if (error.response && error.response.status === 403 && error.response.data.error === "Not enough credits to create additional bot. Please recharge. consider additional bots charge 250 $") {
                setErrorMessage("Not enough credits to create additional bot. Please recharge. consider additional bots charge 250 $");
            }
            // handleAction(false)


        } finally {
            setLoading(false);
        }
    };



    return (
        <>
            <div className="flex justify-center items-center mb-6 mt-9 sm:mt-16">
                <h1 className="text-2xl sm:text-3xl uppercase text-white font-bold text-center" style={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.6)' }}>
                    Automated Bot Training
                </h1>
            </div>

            {/* Step Indicator */}
            <StepIndicator currentStep={currentStep} />

            <div className=''>
                <div className="mb-4 sm:flex sm:items-center max-w-3xl md:max-w-5xl mx-auto"> {/* Added sm:items-center for vertical alignment */}
                    <img
                        src="https://res.cloudinary.com/dbe2fast6/image/upload/v1729852506/freepik__candid-image-photography-natural-textures-highly-r__69315_lkfiuv.jpg"
                        alt="Chatbot illustration"
                        className="hidden sm:block w-full h-64 object-cover rounded-lg shadow-md" // Hide on mobile, show on sm and larger
                    />
                </div>

                <div className="flex flex-col sm:flex-row max-w-3xl md:max-w-5xl mx-auto bg-black/10 rounded-lg shadow-lg overflow-hidden">

                    <div className="w-full sm:w-1/2 p-4 sm:p-8 overflow-y-auto">

                        {/* Image for mobile view */}
                        <div className="mb-4 sm:hidden">
                            <img src="https://res.cloudinary.com/dbe2fast6/image/upload/v1729852506/freepik__candid-image-photography-natural-textures-highly-r__69315_lkfiuv.jpg" alt="Chatbot illustration" className="w-full h-32 object-cover rounded-lg shadow-md" />
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="chatbotName" className="block text-lg font-semibold text-white mb-2">
                                    Chatbot Name
                                </label>
                                <input
                                    type="text"
                                    id="chatbotName"
                                    name="chatbotName"
                                    value={formData.chatbotName}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="websiteUrl" className="block text-lg font-semibold text-white mb-2">
                                    Website URL
                                </label>
                                <input
                                    type="url"
                                    id="websiteUrl"
                                    name="websiteUrl"
                                    value={formData.websiteUrl}
                                    onChange={handleChange}
                                    placeholder="https://example.com"
                                    className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="description" className="block text-lg font-semibold text-white mb-2">
                                    Description
                                </label>
                                <textarea
                                    id="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                    required
                                    rows="4"
                                />
                            </div>
                        </form>
                    </div>

                    {/* Right side form */}
                    <div className="w-full sm:w-1/2 p-4 sm:p-8 overflow-y-auto">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="address" className="block text-lg font-semibold text-white mb-2">
                                    Address (optional)
                                </label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="contactEmail" className="block text-lg font-semibold text-white mb-2">
                                    Contact Email (optional)
                                </label>
                                <input
                                    type="email"
                                    id="contactEmail"
                                    name="contactEmail"
                                    value={formData.contactEmail}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="contactNumber" className="block text-lg font-semibold text-white mb-2">
                                    Contact Number (optional)
                                </label>
                                <input
                                    type="tel"
                                    id="contactNumber"
                                    name="contactNumber"
                                    value={formData.contactNumber}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg bg-white/10 text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-200 ease-in-out"
                                />
                            </div>

                            <button
                                disabled={loading}
                                type="submit"
                                className={`w-full py-3 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-semibold rounded-lg shadow-md transition-all duration-300 ease-in-out 
                        ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:from-pink-600 hover:to-purple-600 hover:scale-105'}`}
                            >
                                {loading ? 'Creating Chatbot...' : 'Create Chatbot'}
                            </button>
                        </form>

                        {errorMessage && (
                            <div className="mt-4 sm:mt-6 bg-red-100 p-4 rounded-lg shadow-lg">
                                <h3 className="text-lg sm:text-xl font-bold text-red-700">Error:</h3>
                                <p className="text-red-600">{errorMessage}</p>

                                {errorMessage === "Email not verified. Please verify your email to proceed." && (
                                    <p className="text-blue-600">Click "Send Verification Email" button on the top bar.</p>
                                )}
                                {errorMessage === "Account upgrade needed. Please upgrade your account." && (
                                    <a href="/pricing" className="text-blue-600 underline">Click here to upgrade the account.</a>
                                )}
                                {errorMessage === "You cannot use automated training in your plan." && (
                                    <a href="/pricing" className="text-blue-600 underline">Click here to upgrade the account.</a>
                                )}
                                {errorMessage === "Please Recharge Your Balance to Create Bot!" && (
                                    <a href={`/${uid}/usage`} className="text-blue-600 underline">Click here to add balance.</a>
                                )}
                                {errorMessage === "Bot creation limit reached for your plan, upgrade your account to create more!" && (
                                    <a href="/pricing" className="text-blue-600 underline">Click here to upgrade the account.</a>
                                )}
                                {errorMessage === "Site is heavy to scrape. Please try again." && (
                                    <a href="/create-chatbot/ai-manual" className="text-blue-600 underline">Click here to proceed with manual input.</a>
                                )}
                                {errorMessage === "Not enough credits to create additional bot. Please recharge. consider additional bots charge 250 $" && (
                                    <a href={`/${uid}/usage`} className="text-blue-600 underline">Click here to add balance.</a>
                                )}
                            </div>
                        )}

                        {showModal && (
                            <SuccessFailedModal
                                isSuccess={isSuccess}
                                message={message}
                                onClose={closeModal}
                            />
                        )}
                    </div>
                </div>
            </div >
        </>
    );
}

export default CreateChatbot;
