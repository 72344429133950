import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getSecureCookie } from '../utilities/safeSaves'; // Ensure this path is correct

const MarxIPGOrder = ({ price, summary, type }) => {
    // State variables
    const [isSandbox, setIsSandbox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Constants
    const liveMerchantId = '671';
    const sandboxMID = 'CT2020/09/09/MR0001';

    const sandboxSecret = '$2a$10$x1CAe9YuEz9G1X1ZQrTrLOJXFu2PSvrwLuBcWpgT2ecRAx5sxfOhW';
    const liveSecret = '$2a$10$qz.QrWKJHxqXYkf6YTjIpOSE3MnPIdJ3OeOHJUcf.RNeLHeMf6Nn.';

    // Determine the current environment's base URL and secret
    const baseURL = isSandbox
        ? 'https://dev.app.marx.lk/global-api'
        : 'https://app.global.marx.lk/api';

    const userSecret = isSandbox ? sandboxSecret : liveSecret;
    const merchantId = isSandbox ? sandboxMID : liveMerchantId;

    // Retrieve customer reference from secure cookie
    const customerReference = getSecureCookie('uid');

    useEffect(() => {
        if (!customerReference) {
            window.location.href = '/login'; // Adjust URL to your sign-in page
        }
    }, [customerReference]);

    // Retrieve current URL for returnUrl
    const returnUrl = `${window.location.origin}/payment-success`;

    // Function to create order
    const createOrder = async (e) => {
        e.preventDefault(); // Prevent form submission

        if (loading) return; // Prevent multiple submissions while loading

        setLoading(true);
        setError('');

        try {
            const response = await axios.post(
                `${baseURL}/v2/ipg/orders`,
                {
                    merchantRID: `${merchantId}/${new Date().getTime()}`, // Ensure unique RID
                    amount: parseFloat(price),
                    validTimeLimit: 5, // As per your requirement
                    returnUrl: returnUrl,
                    mode: 'WEB',
                    orderSummary: summary,
                    customerReference: customerReference,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'user_secret': userSecret,
                    },
                }
            );

            if (response.data.status === 0) {
                const { payUrl } = response.data.data;
                window.location.href = payUrl; // Redirect to payment page
            } else {
                setError(`Error: ${response.data.message}`);
            }
        } catch (err) {
            console.error('Error creating order:', err);
            setError('Failed to create order. Please try again.');
        } finally {
            setLoading(false); // Reset loading state after request
        }
    };


    return (
        <>
            {customerReference && (
                <div className="flex items-center justify-center">
                    <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-lg">
                        <div className="flex justify-center mb-6">
                            <img
                                src="https://res.cloudinary.com/dbe2fast6/image/upload/v1730218164/android-chrome-192x192_hza7p6.png"
                                alt="Payment Quote Logo"
                                className="w-24 h-24"
                            />
                        </div>
                        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Your Payment Quote</h2>

                        <form onSubmit={createOrder} className="space-y-6">
                            {/* Price and Summary Display */}
                            <div className="bg-gray-50 p-4 rounded-md shadow-sm">
                                <div className="flex justify-between">
                                    <div className="flex-1">
                                        <label className="block text-sm font-medium text-gray-600">Price (USD)</label>
                                        <p className="text-xl font-bold text-gray-800">${price}</p>
                                    </div>
                                    <div className="flex-1 ml-4">
                                        <label className="block text-sm font-medium text-gray-600">Order Summary</label>
                                        <p className="text-sm text-gray-800">{summary}</p>
                                    </div>
                                </div>
                            </div>

                            {/* Environment Toggle */}
                            {/* <div className="flex items-center justify-between mt-4">
                                <label className="flex items-center text-gray-600">
                                    <input
                                        type="checkbox"
                                        checked={isSandbox}
                                        onChange={() => setIsSandbox(!isSandbox)}
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span className="ml-2 text-sm">Use Sandbox Environment</span>
                                </label>
                            </div> */}

                            {/* Submit Button */}
                            <div>
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className={`w-full py-3 px-4 rounded-md text-white ${loading ? 'bg-blue-300' : 'bg-blue-600 hover:bg-blue-700'
                                        } focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                >
                                    {loading ? 'Creating Order...' : 'Proceed to Payment'}
                                </button>
                            </div>

                            {/* Error Message */}
                            {error && (
                                <div className="text-red-500 text-sm mt-4 text-center">
                                    {error}
                                </div>
                            )}
                        </form>
                    </div>
                </div>)}
        </>
    );
};

export default MarxIPGOrder;
