import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto my-10 p-6">
            <h1 className="text-4xl text-center font-bold text-white mb-8 uppercase" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)' }}>Privacy Policy</h1>

            <div className="bg-gray-900/10 text-white p-6 rounded-lg shadow-lg space-y-6">
                <p>Last updated: October 23, 2024</p>

                <p>This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you use the service and tells you about your privacy rights and how the law protects you.</p>

                <p>We use your personal data to provide and improve the service. By using the service, you agree to the collection and use of information in accordance with this Privacy Policy.</p>

                <h2 className="text-2xl font-bold">Interpretation and Definitions</h2>
                <h3 className="text-xl font-semibold">Interpretation</h3>
                <p>The words with initial capital letters have meanings defined under the following conditions. These definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

                <h3 className="text-xl font-semibold">Definitions</h3>
                <p>For the purposes of this Privacy Policy:</p>
                <ul className="list-disc list-inside">
                    <li><strong>Account:</strong> A unique account created for you to access our service or parts of our service.</li>
                    <li><strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party.</li>
                    <li><strong>Company:</strong> Refers to Aphrohera Games PVT LTD, Teacher's College Lane, Kopay South, Kopay, Jaffna.</li>
                    <li><strong>Cookies:</strong> Small files placed on your device by a website to track activity.</li>
                    <li><strong>Country:</strong> Refers to Sri Lanka.</li>
                    <li><strong>Device:</strong> Any device that can access the service such as a computer, cellphone, or digital tablet.</li>
                    <li><strong>Personal Data:</strong> Information that relates to an identified or identifiable individual.</li>
                    <li><strong>Service:</strong> Refers to the website Botanion, accessible from https://botanion.cc/.</li>
                    <li><strong>Service Provider:</strong> A natural or legal person who processes data on behalf of the company.</li>
                    <li><strong>Third-party Social Media Service:</strong> Any social network website through which a user can log in or create an account to use the service.</li>
                    <li><strong>Usage Data:</strong> Data collected automatically, either generated by the use of the service or from the service infrastructure itself.</li>
                    <li><strong>You:</strong> The individual accessing or using the service, or the company or other legal entity on whose behalf the service is being used.</li>
                </ul>

                <h2 className="text-2xl font-bold">Collecting and Using Your Personal Data</h2>
                <h3 className="text-xl font-semibold">Types of Data Collected</h3>

                <h4 className="text-lg font-semibold">Personal Data</h4>
                <p>While using our service, we may ask you to provide certain personal data including, but not limited to:</p>
                <ul className="list-disc list-inside">
                    <li>Email address</li>
                    <li>First and last name</li>
                    <li>Phone number</li>
                    <li>Country</li>
                </ul>

                <h4 className="text-lg font-semibold">Usage Data</h4>
                <p>Usage Data is collected automatically when using the service, including IP address, browser type, and diagnostic data.</p>

                <h4 className="text-lg font-semibold">Information from Third-Party Social Media Services</h4>
                <p>We may collect personal data from your social media accounts if you log in via Google, Facebook, Instagram, Twitter, LinkedIn, or other platforms.</p>

                <h4 className="text-lg font-semibold">Tracking Technologies and Cookies</h4>
                <p>We use cookies and similar tracking technologies like web beacons to monitor activity and improve our service. Learn more about cookies in the "Cookies Policy."</p>

                <h3 className="text-xl font-semibold">Use of Your Personal Data</h3>
                <p>The company may use your personal data for the following purposes:</p>
                <ul className="list-disc list-inside">
                    <li>To provide and maintain the service.</li>
                    <li>To manage your account and registration.</li>
                    <li>To contact you with updates or marketing materials.</li>
                    <li>To manage and fulfill any purchase contracts.</li>
                    <li>For business transfers, mergers, or acquisitions.</li>
                    <li>To evaluate and improve services based on user data.</li>
                    <li>To comply with legal obligations.</li>
                </ul>

                <h3 className="text-xl font-semibold">CCPA and CPRA Compliance</h3>
                <p>If you are a resident of California, the California Consumer Privacy Act (CCPA), as amended by the California Privacy Rights Act (CPRA), grants you additional privacy rights:</p>
                <ul className="list-disc list-inside">
                    <li>The right to know what personal information we collect.</li>
                    <li>The right to request deletion of your personal data.</li>
                    <li>The right to opt out of the sale of your personal information.</li>
                </ul>

                <h3 className="text-xl font-semibold">GDPR Compliance</h3>
                <p>If you are in the European Union, we comply with the General Data Protection Regulation (GDPR), ensuring that:</p>
                <ul className="list-disc list-inside">
                    <li>We obtain your consent before processing personal data.</li>
                    <li>You have the right to access, rectify, or erase your personal data.</li>
                    <li>You can withdraw consent at any time.</li>
                </ul>

                <h3 className="text-xl font-semibold">CalOPPA Compliance</h3>
                <p>For users from California, we comply with California Online Privacy Protection Act (CalOPPA):</p>
                <ul className="list-disc list-inside">
                    <li>We provide a clear privacy policy.</li>
                    <li>You can access and update your personal data.</li>
                </ul>

                <h3 className="text-xl font-semibold">Children’s Privacy</h3>
                <p>We do not knowingly collect personal data from children under 13. If you are a parent or guardian and discover that your child has provided us with personal data, please contact us.</p>

                <h3 className="text-xl font-semibold">Analytics and Tracking Tools</h3>
                <p>We use Google Analytics and Firebase to track user activity and improve service functionality.</p>

                <h3 className="text-xl font-semibold">Email Communication</h3>
                <p>We send emails to users for transactional, promotional, or marketing purposes. You can opt out of receiving these emails.</p>

                <h3 className="text-xl font-semibold">Remarketing Services</h3>
                <p>We use remarketing services for advertising purposes.</p>

                <h3 className="text-xl font-semibold">Payment Services</h3>
                <p>Users can pay for products or services via secure payment methods integrated with the website.</p>

                <h3 className="text-xl font-semibold">Security of Your Personal Data</h3>
                <p>We strive to protect your personal data but cannot guarantee absolute security due to the nature of the internet.</p>

                <h3 className="text-xl font-semibold">Links to Other Websites</h3>
                <p>Our service may contain links to other websites. We are not responsible for the content or privacy practices of these external sites.</p>

                <h3 className="text-xl font-semibold">Changes to this Privacy Policy</h3>
                <p>We may update this Privacy Policy from time to time. Changes will be effective when posted on this page.</p>

                <h3 className="text-xl font-semibold">Contact Us</h3>
                <p>For any questions about this Privacy Policy, you can contact us by email: <a href="mailto:support@botanion.cc" className="text-blue-400">support@botanion.cc</a></p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;