import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="bg-zinc-950 text-white py-8">
            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row md:justify-between">

                    {/* Company Info */}
                    <div className="mb-6 md:mb-0 text-center md:text-left">
                        <h2 className="text-lg font-bold">Botanion</h2>
                        <address className="mt-2">Proudly made in Sri Lanka</address>
                        <div className="text-center mt-8">
                            <p>&copy; 2024 Botanion. All rights reserved.</p>
                        </div>
                    </div>

                    {/* Links */}
                    <nav className="mb-6 md:mb-0 text-center md:text-left">
                        <h2 className="text-lg font-bold">Quick Links</h2>
                        <ul className="mt-2 space-y-2">
                            <li><a href="/about" className="hover:text-blue-400">About Us</a></li>
                            <li><a href="/pricing" className="hover:text-blue-400">Pricing</a></li>
                            <li><a href="/docs" className="hover:text-blue-400">Docs</a></li>
                            <li><a href="/privacy" className="hover:text-blue-400">Privacy Policy</a></li>
                            <li><a href="/terms" className="hover:text-blue-400">Terms & Conditions</a></li>
                            <li><a href="/refund" className="hover:text-blue-400">Refund Policy</a></li>
                        </ul>
                    </nav>

                    {/* Social Media, Contact, and Community Sections */}
                    <div className="text-center md:text-left">
                        <h2 className="text-lg font-bold">Follow Us</h2>
                        <div className="flex justify-center md:justify-start space-x-4 mt-2">
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} className="h-6 w-6 hover:text-blue-400" />
                            </a>
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} className="h-6 w-6 hover:text-blue-400" />
                            </a>
                            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} className="h-6 w-6 hover:text-blue-400" />
                            </a>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} className="h-6 w-6 hover:text-blue-400" />
                            </a>
                        </div>

                        {/* Contact Section */}
                        <div className="mt-6">
                            <h2 className="text-lg font-bold">Contact</h2>
                            <ul className="mt-2 space-y-2">
                                <li><a href="mailto:info@botanion.com" className="hover:text-blue-400">info@botanion.com</a></li>
                            </ul>
                        </div>

                        {/* Community Section */}
                        <div className="mt-6">
                            <h2 className="text-lg font-bold">Community</h2>
                            <ul className="mt-2 space-y-2">
                                <li>
                                    <a href="https://discord.gg/yourdiscordlink" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400">
                                        Join our Discord
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
