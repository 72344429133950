import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { setSecureCookie } from '../utilities/safeSaves';

const CompleteProfilePage = () => {
    const location = useLocation(); // Get state from previous page
    const { email, name, userId, emailVerified } = location.state || {}; // Email and name passed from Google sign-in
    const [password, setPassword] = useState('');
    const [repassword, setRePassword] = useState('');
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [error, setError] = useState('');
    const [Loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch country data
        const fetchCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const sortedCountries = response.data.sort((a, b) =>
                    a.name.common.localeCompare(b.name.common)
                );
                setCountries(sortedCountries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, []);

    const validatePassword = (password) => {
        const minLength = 12;
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+[\]{};:'",.<>?/]).{12,}$/;
        if (password.length < minLength) return 'Password must be at least 12 characters long';
        if (!regex.test(password)) return 'Password must include uppercase, lowercase, numbers, and special characters';
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const passwordError = validatePassword(password);
        if (password !== repassword) {
            setError("Passwords do not match.");
            return;
        }
        if (passwordError) {
            setError(passwordError);
            return;
        }
        if (!country) {
            setError('Country is required.');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post('https://api-bny5h3g2lq-uc.a.run.app/api/google-signup', {
                name,
                email,
                password,
                country,
                uid: userId,
                emailVerified
            });



            const { token, uid, plan } = response.data;
            setSecureCookie('accessToken', token, { expires: 7, secure: true });
            setSecureCookie('uid', uid, { expires: 7, secure: true });
            setSecureCookie('username', name, { expires: 7, secure: true });
            setSecureCookie('plan', plan, { expires: 7, secure: true });

            window.location.replace('/'); // Redirect after successful completion
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                setError('Failed to complete profile.');
            } else {
                console.error('Error message:', error.message);
                setError('Failed to complete profile. Please try again.');
            }
        }
    };

    return (
        <div className="flex items-center justify-center">
            <div className="bg-white/10 shadow-xl rounded-3xl overflow-hidden h-3/4 w-11/12 m-5">
                <div className="p-10 flex flex-col justify-center">
                    <h2 className="text-4xl font-bold text-center text-purple-300 mb-2">
                        Complete Your Profile
                    </h2>
                    <p className="text-center text-gray-200 mb-4">
                        Provide additional details to finish setting up your account.
                    </p>
                    {error && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                            {error}
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-200 font-medium">Password</label>
                            <input
                                type="password"
                                className="w-full p-3 border border-gray-300 rounded-2xl bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-600"
                                placeholder="Enter a strong password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-200 font-medium">Confirm Password</label>
                            <input
                                type="password"
                                className="w-full p-3 border border-gray-300 rounded-2xl bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-600"
                                placeholder="Enter a strong password"
                                value={repassword}
                                onChange={(e) => setRePassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-200 font-medium">Country</label>
                            <select
                                className="w-full p-3 mt-2 border rounded-2xl bg-white/5 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                required
                            >
                                <option value="" disabled>
                                    Select your country
                                </option>
                                {countries.map((country) => (
                                    <option
                                        key={country.cca2}
                                        value={country.name.common}
                                        className="bg-black text-white"
                                    >
                                        {country.name.common}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button
                            type="submit"
                            className={`w-full bg-purple-600 text-white py-3 rounded-lg font-semibold transition duration-300 ${Loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-700'
                                }`}
                            disabled={Loading}
                        >
                            {Loading ? 'Processing...' : 'Complete Profile'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CompleteProfilePage;
