import React, { useState, useEffect } from 'react';

const planOptions = [
    { name: 'Basic', maxBots: 1, messageLimit: 150, baseCost: 0, freeCredits: 0, overageCost: 4, charge: 3 },
    { name: 'Pro', maxBots: 3, messageLimit: 600, baseCost: 24, freeCredits: 100, overageCost: 3, charge: 2 },
    { name: 'Super', maxBots: 5, messageLimit: 1400, baseCost: 49, freeCredits: 300, overageCost: 2, charge: 1 },
    { name: 'Enterprise', maxBots: Infinity, messageLimit: Infinity, baseCost: 'Contact Us', overageCost: 'Custom' }
];



function PricingCalculator() {
    const [plan, setPlan] = useState(planOptions[0]);
    const [messagesPerMonth, setMessagesPerMonth] = useState(150);
    const [bots, setBots] = useState(1);
    const [totalCost, setTotalCost] = useState(0);
    const [totalCostDollars, setTotalCostDollars] = useState(0);
    const [totalBuyingCredits, setTotalBuyingCredits] = useState(0);


    const [costBeforeDiscount, setCostBeforeDiscount] = useState(0);

    const [requiredCredits, setRequiredCredits] = useState(0);
    const [excessCredits, setExcess] = useState(0);
    const [bestRechargeOption, setBestRechargeOption] = useState(null);

    const handlePlanChange = (event) => {
        const selectedPlan = planOptions.find(p => p.name === event.target.value);
        setPlan(selectedPlan);
        if (bots > selectedPlan.maxBots) setBots(selectedPlan.maxBots);
    };

    const creditPackages = {
        Basic: [
            { credits: 150, cost: 7 },
            { credits: 300, cost: 11 },
            { credits: 680, cost: 21 },
        ],
        Pro: [
            { credits: 200, cost: 7 },
            { credits: 400, cost: 11 },
            { credits: 850, cost: 21 },
        ],
        Super: [
            { credits: 250, cost: 7 },
            { credits: 500, cost: 11 },
            { credits: 1050, cost: 21 },
        ],
    };

    const calculateCost = () => {
        const baseCost = typeof plan.baseCost === 'number' ? plan.baseCost : 0;
        const messageLimit = plan.messageLimit;
        const overageCharge = plan.overageCost;
        const charge = plan.charge;
        let excessMessages = 0;
        let totalCreditsCost = 0;

        // Calculate cost based on message count compared to monthly limit
        if (messagesPerMonth * bots <= messageLimit) {
            totalCreditsCost = messagesPerMonth * bots * charge; // Standard cost per message within limit (1 credit)
        } else {
            excessMessages = messagesPerMonth * bots - messageLimit;
            totalCreditsCost = (messageLimit * charge) + (excessMessages * overageCharge); // Overage cost
        }

        // Consider free credits in the calculation
        const discountedCost = totalCreditsCost - plan.freeCredits;

        // Ensure the total cost does not go below zero
        const finalCost = Math.max(discountedCost, 0); // Make sure final cost is not negative

        setCostBeforeDiscount(totalCreditsCost);
        setTotalCost(finalCost);
        setRequiredCredits(totalCreditsCost); // Store the raw credits cost for display purposes
        setExcess(excessMessages); // Store excess messages for display purposes
    };

    const getBestRechargeOption = (requiredCredits) => {
        const packages = creditPackages[plan.name];
        if (!packages) {
            setBestRechargeOption("Contact us for custom plans");
            return;
        }

        // Step 1: Filter for packages that meet or exceed the required credits
        const suitablePackages = packages.filter(pkg => pkg.credits >= requiredCredits);

        if (suitablePackages.length > 0) {
            // Select the lowest-cost package from those that individually meet the requirement
            const bestPackage = suitablePackages.reduce((lowestCostPackage, pkg) =>
                pkg.cost < lowestCostPackage.cost ? pkg : lowestCostPackage, suitablePackages[0]);
            setBestRechargeOption(bestPackage);
            setTotalBuyingCredits(bestPackage.credits)

            return;
        }

        // Step 2: Calculate best combination of packages if no single package meets requirement
        let bestCombination = { totalCredits: 0, totalCost: Infinity, details: "" };

        packages.forEach(pkg => {
            const multiplier = Math.ceil(requiredCredits / pkg.credits);
            const totalCredits = pkg.credits * multiplier;
            const totalCost = pkg.cost * multiplier;

            setTotalCostDollars(totalCost)


            if (totalCost < bestCombination.totalCost && totalCredits >= requiredCredits) {
                bestCombination = {
                    totalCredits,
                    totalCost,
                    details: `Buy ${multiplier} of ${pkg.credits} credits at $${pkg.cost} each for a total of $${totalCost}.`
                };
                setTotalBuyingCredits(multiplier * pkg.credits)
            }
        });

        // Set the best combination as the recharge option
        setBestRechargeOption(bestCombination);
    };



    useEffect(() => {
        setExcess(0);
        setRequiredCredits(0);
        calculateCost();
    }, [plan, messagesPerMonth, bots]);

    useEffect(() => {
        if (requiredCredits > 0) {
            getBestRechargeOption(requiredCredits);
        }
    }, [requiredCredits, plan]);

    return (
        <div className="container mx-auto px-6 py-8 text-left">
            <h1 className="text-3xl uppercase text-center text-white font-bold mb-4 mt-10" style={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.3)' }}>Pricing Calculator</h1>
            {/* Back Button */}
            <div className="mb-4">
                <button
                    onClick={() => window.history.back()}
                    className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-500"
                >
                    Back
                </button>
            </div>

            <div className="flex flex-col lg:flex-row gap-8">
                {/* Form Section */}
                <div className="w-full lg:w-2/3">
                    {/* Plan selection */}
                    <div className="mb-4">
                        <label className="block font-semibold text-gray-200 mb-2">Select Plan</label>
                        <select
                            className="w-full px-4 py-2 border rounded-md"
                            value={plan.name}
                            onChange={handlePlanChange}
                        >
                            {planOptions.map((option) => (
                                <option key={option.name} value={option.name}>
                                    {option.name} - {option.baseCost === 'Contact Us' ? 'Contact Us' : `$${option.baseCost}/month`}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Input fields for messages and bots */}
                    <div className="mb-4">
                        <label className="block font-semibold text-gray-200 mb-2">Messages per Month</label>
                        <input
                            type="number"
                            className="w-full px-4 py-2 border rounded-md"
                            value={messagesPerMonth}
                            onChange={(e) => setMessagesPerMonth(Math.max(0, parseInt(e.target.value) || 0))}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block font-semibold text-gray-200 mb-2">Number of Bots</label>
                        <input
                            type="number"
                            className="w-full px-4 py-2 border rounded-md"
                            value={bots}
                            onChange={(e) => setBots(Math.min(plan.maxBots, Math.max(1, parseInt(e.target.value) || 1)))}
                            max={plan.maxBots}
                        />
                        {bots > plan.maxBots && (
                            <p className="text-sm text-red-600">The {plan.name} plan allows a maximum of {plan.maxBots} bot(s). You have been adjusted to {plan.maxBots} bots.</p>
                        )}
                    </div>
                </div>

                {/* Cost Summary Section */}
                <div className="w-full lg:w-1/3">
                    <div className="p-4 bg-gray-100 rounded-md shadow-md">
                        <h2 className="text-lg font-bold text-gray-800">Estimated Monthly Cost</h2>
                        <p className="text-xl text-gray-800 font-semibold">
                            {totalCost !== 0 ? (
                                plan.baseCost === 'Contact Us'
                                    ? 'Contact us for pricing'
                                    : `${costBeforeDiscount} credits${plan.freeCredits > 0 ? ` - ${plan.freeCredits} free credits = ${totalCost} credits` : ''}`
                            ) : "No recharge required"}
                        </p>
                        {requiredCredits > 0 && (
                            <p className="text-sm text-gray-600 mt-1">
                                Includes overage charges for {excessCredits} extra messages.
                            </p>
                        )}
                        {plan.baseCost === 'Contact Us' && (
                            <p className="text-sm text-gray-600 mt-1">Please contact us for custom pricing.</p>
                        )}

                        {/* Best Recharge Option */}
                        {bestRechargeOption && (
                            <div className="mt-6 p-4 bg-blue-100 rounded-md shadow-md">
                                <h2 className="text-lg font-bold text-green-800">Best Recharge Option</h2>
                                <p className="text-md text-green-800">
                                    Based on your usage, the best recharge package is:<br />
                                    <span className='text-orange-800'>
                                        {!bestRechargeOption.details &&
                                            <strong> {bestRechargeOption.credits} credits for ${bestRechargeOption.cost}</strong>}
                                        <strong> {bestRechargeOption.details}</strong></span>
                                </p>

                                {/* Highlighted Per-Message Calculation */}
                                <div className="mt-4 p-4 bg-yellow-100 rounded-md shadow-md text-center">
                                    <p className="text-lg font-bold text-yellow-800">Estimated Cost per Conversation</p>
                                    {!bestRechargeOption.details ? (
                                        <strong className="text-2xl text-yellow-800">
                                            {((bestRechargeOption.cost + plan.baseCost) / ((messagesPerMonth * bots) + (totalBuyingCredits - totalCost) / plan.charge)).toFixed(2)} per Conversation
                                        </strong>
                                    ) : (
                                        <strong className="text-2xl text-yellow-800">
                                            {((totalCostDollars + plan.baseCost) / ((messagesPerMonth * bots) + (totalBuyingCredits - totalCost) / plan.charge)).toFixed(2)} per Conversation
                                        </strong>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PricingCalculator;
