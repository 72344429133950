import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingScreen from './LoadingScreen';
import { getSecureCookie } from '../utilities/safeSaves';
import { FaRobot, FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function MyBots() {
    const [bots, setBots] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingBotId, setEditingBotId] = useState(null);
    const [newBotName, setNewBotName] = useState('');
    const [botToDelete, setBotToDelete] = useState('')
    const [showConfirmModal, setShowConfirmModal] = useState(false); // State to manage confirmation modal visibility


    useEffect(() => {
        fetchBots();
    }, []);

    const fetchBots = async () => {
        setLoading(true);
        try {
            const uid = getSecureCookie('uid');
            const token = getSecureCookie('accessToken');

            const response = await axios.get(
                'https://api-bny5h3g2lq-uc.a.run.app/api/byowner/chatbot',
                {
                    params: { ownerName: uid },
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setBots(response.data);
        } catch (error) {
            console.error('Error fetching bots:', error);
        } finally {
            setLoading(false);
        }
    };

    const getColorByType = (bot) => {
        switch (bot.type) {
            case 'info':
                return 'bg-blue-500';
            case 'warning':
                return 'bg-yellow-500';
            case 'error':
                return 'bg-red-500';
            default:
                return 'bg-green-500';
        }
    };

    const handleEditClick = (bot) => {
        setEditingBotId(bot.id);
        setNewBotName(bot.botName);
    };

    const handleUpdateName = async (botId) => {
        try {
            const token = getSecureCookie('accessToken');
            await axios.put(
                `https://api-bny5h3g2lq-uc.a.run.app/api/chatbot/botname/${botId}`,
                { botName: newBotName },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setBots(bots.map(bot =>
                bot._id === botId && bot.active ? { ...bot, botName: newBotName } : bot
            ));
            await fetchBots()
            setEditingBotId(null); // Clear the editing state
        } catch (error) {
            console.error('Error updating bot name:', error);
        }
    };


    const handleDelete = async () => {
        try {
            const token = getSecureCookie('accessToken'); // Retrieve the token securely

            // Send a PUT request with the Authorization header
            const response = await axios.put(
                `https://api-bny5h3g2lq-uc.a.run.app/api/chatbot/delete/${botToDelete}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.status === 200) {
                console.log(response.data.message); // Log success message
                await fetchBots()
                // Optionally, update UI or state here to reflect the deletion
            } else {
                console.error('Failed to delete bot');
            }
        } catch (error) {
            console.error('Error deleting bot:', error);
            // Optionally, handle the error in the UI, such as showing an alert
        }
        finally {
            setShowConfirmModal(false)
        }
    };

    return (
        <div className="container mx-auto py-10 px-4">
            <div className="flex justify-center items-center mb-12">
                <h1 className="text-3xl uppercase text-center text-white font-bold mb-4 mt-4" style={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.3)' }}>
                    My Chatbots
                </h1>
            </div>

            {loading ? (
                <LoadingScreen textToShow={"Fetching Your Bots..."} />
            ) : bots.length === 0 ? (
                <p className="text-center text-lg text-gray-500">No chatbots created yet.</p>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {bots.map((bot) => (
                        <div
                            key={bot._id}
                            className="bg-gray-800 text-gray-200 rounded-lg shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 flex flex-col"
                        >
                            <div className={`${getColorByType(bot)} h-2 rounded-t-lg`} />

                            <div
                                className="relative h-48 bg-cover bg-center"
                                style={{ backgroundImage: `url(${bot.imageUrl || 'https://res.cloudinary.com/dbe2fast6/image/upload/v1729942255/freepik__candid-image-photography-natural-textures-highly-r__56289_ihipka.jpg'})` }}
                            >
                                <div className="absolute inset-0 bg-black bg-opacity-30 flex justify-center items-center">
                                    <FaRobot className="text-6xl text-white opacity-80" />
                                </div>
                            </div>

                            <div className="p-6 flex-grow">
                                <div className="flex items-center">
                                    {editingBotId === bot.id ? (
                                        <div className="flex items-center w-full text-blue-500">
                                            <input
                                                type="text"
                                                value={newBotName}
                                                onChange={(e) => setNewBotName(e.target.value)}
                                                className="border rounded-lg p-2 mb-2 flex-grow"
                                            />
                                            <button
                                                className="bg-blue-500 text-white rounded-lg px-4 py-1 ml-2"
                                                onClick={() => handleUpdateName(bot.id)}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <h2 className="text-2xl font-semibold mb-2 flex-grow">{bot.botName}</h2>
                                            <FaEdit
                                                className="text-gray-400 hover:text-gray-200 cursor-pointer ml-2"
                                                onClick={() => handleEditClick(bot)}
                                            />
                                        </>
                                    )}
                                </div>
                                <p className="text-sm text-gray-400 mb-4">{bot.description}</p>
                            </div>

                            <div className="flex justify-between items-center p-4 mt-auto bg-gray-900 rounded-b-lg">
                                <div className="flex space-x-4">
                                    <Link to={`/chatbot-response/${bot.id}`} className="text-blue-400 hover:text-blue-200">
                                        Edit
                                    </Link>
                                    <button className="text-red-400 hover:text-red-200" onClick={() => {
                                        setBotToDelete(bot.id);
                                        setShowConfirmModal(true);
                                    }}>
                                        Delete
                                    </button>
                                </div>

                                <div className="flex flex-wrap gap-2">
                                    {bot.tags ? bot.tags.map(tag => (
                                        <span key={tag} className="text-xs bg-gray-700 text-gray-300 px-2 py-1 rounded-full">
                                            {tag}
                                        </span>
                                    )) : (
                                        <span className="text-xs bg-gray-700 text-gray-300 px-2 py-1 rounded-full">
                                            {bot.website || "No website"}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {showConfirmModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
                        <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
                        <p className="mb-4">Are you sure you want to delete this Bot? This action cannot be undone.</p>
                        <div className="flex justify-end space-x-2">
                            <button
                                className="bg-gray-400 text-white py-1 px-4 rounded-lg"
                                onClick={() => setShowConfirmModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-red-600 text-white py-1 px-4 rounded-lg"
                                onClick={handleDelete}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MyBots;
