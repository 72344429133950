import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import SuccessFailedModal from './SuccessFailPage';
import { getSecureCookie } from '../utilities/safeSaves';

const PaymentSuccess = () => {
    const [searchParams] = useSearchParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasProcessed, setHasProcessed] = useState(false); // Prevents multiple API calls
    const [isSuccess, setIsSuccess] = useState(null); // Local state for success
    const [message, setMessage] = useState('Processing payment...'); // Local state for message

    const trId = searchParams.get('tr');
    const merchantRID = searchParams.get('mur');
    const token = getSecureCookie('accessToken');

    const isTransactionProcessed = () => {
        return sessionStorage.getItem(`processedTransaction-${trId}`) === 'true'; // Ensure 'true' as string
    };

    const setTransactionAsProcessed = () => {
        sessionStorage.setItem(`processedTransaction-${trId}`, 'true');
    };

    const fetchFromSession = () => {
        // Check sessionStorage for values
        const sessionIsSuccess = sessionStorage.getItem(`isSuccess-${trId}`);
        const sessionMessage = sessionStorage.getItem(`currentMessage-${trId}`);

        if (sessionIsSuccess && sessionMessage) {
            setIsSuccess(sessionIsSuccess === 'true');
            setMessage(sessionMessage);
            setIsModalOpen(true); // Show modal once data is retrieved
        } else {
            console.log('Data not in sessionStorage, retrying...');
            setTimeout(fetchFromSession, 500); // Retry after 500ms
        }
    };

    const rechargeAccount = async (api_url) => {
        if (!trId || !merchantRID || !token) {
            return;
        }

        if (hasProcessed || isTransactionProcessed()) {
            // No UI changes, just skip processing
            console.log('Transaction already processed, skipping reprocessing.');
            fetchFromSession(); // Fetch session data directly
            return; // Skip if transaction has already been processed
        }

        try {
            setTransactionAsProcessed(); // Mark this transaction as processed
            setHasProcessed(true); // Prevent further processing

            const response = await axios.post(
                // 'https://api-bny5h3g2lq-uc.a.run.app/api/recharge',
                // 'https://api-bny5h3g2lq-uc.a.run.app/api/upgrade',
                api_url,
                { trId, merchantRID },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200 && response.data?.message) {
                sessionStorage.setItem(`currentMessage-${trId}`, response.data.message);
                sessionStorage.setItem(`isSuccess-${trId}`, 'true'); // Store 'true' as string
                setMessage(response.data.message);
                setIsSuccess(true);
            } else {
                sessionStorage.setItem(`currentMessage-${trId}`, response.data?.error || 'Recharge failed.');
                sessionStorage.setItem(`isSuccess-${trId}`, 'false'); // Store 'false' as string
                setMessage(response.data?.error || 'Recharge failed.');
                setIsSuccess(false);
            }
        } catch (err) {
            console.error('Error:', err);
            sessionStorage.setItem(`currentMessage-${trId}`, err.data?.error || 'An error occurred.');
            sessionStorage.setItem(`isSuccess-${trId}`, 'false'); // Store 'false' as string
            setMessage(err.data?.error || 'An error occurred.');
            setIsSuccess(false);
        }

        fetchFromSession(); // Fetch data from sessionStorage after API call
    };

    useEffect(() => {
        const action = sessionStorage.getItem('paymentAction');
        let api_url = '';  // Use let so it can be reassigned

        // Determine the correct API URL based on the action
        if (action === 'upgrade') {
            api_url = 'https://api-bny5h3g2lq-uc.a.run.app/api/upgrade';
            // api_url = 'http://localhost:5000/api/upgrade';

        } else {
            api_url = 'https://api-bny5h3g2lq-uc.a.run.app/api/recharge';
        }

        if (trId && merchantRID && token && !hasProcessed) {
            rechargeAccount(api_url);
        }
    }, [searchParams, trId, merchantRID, token, hasProcessed]);


    const closeModal = () => {
        setIsModalOpen(false);
        sessionStorage.removeItem(`processedTransaction-${trId}`);
        sessionStorage.removeItem(`isSuccess-${trId}`);
        sessionStorage.removeItem(`currentMessage-${trId}`);
        window.location.replace(`${getSecureCookie('uid')}/usage`);
    };

    return (
        <div>
            {isModalOpen && (
                <SuccessFailedModal
                    isSuccess={isSuccess} // Use local state
                    message={message} // Use local state
                    onClose={closeModal}
                />
            )}
        </div>
    );
};

export default PaymentSuccess;
