import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FcGoogle } from 'react-icons/fc';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../firebase';
import { setSecureCookie } from '../utilities/safeSaves';

const RegisterPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRePassword] = useState('');
    const [name, setName] = useState('');
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]); // State to hold country data
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetch country data from REST Countries API
        const fetchCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');

                const sortedCountries = response.data.sort((a, b) =>
                    a.name.common.localeCompare(b.name.common)
                );

                setCountries(sortedCountries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, []);

    const validatePassword = (password) => {
        const minLength = 12;
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+[\]{};:'",.<>?/]).{12,}$/;

        if (password.length < minLength) {
            return 'Password must be at least 12 characters long';
        }
        if (!regex.test(password)) {
            return 'Password must contain uppercase, lowercase, numbers, and special characters';
        }
        if (/(\w)\1\1/.test(password)) {
            return 'Password cannot contain three consecutive repeating characters';
        }
        return ''; // No errors
    };

    const handleGoogleAuth = async () => {
        try {
            setLoading(true);
            // Trigger Google Sign-in Popup
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            // Extract user information
            const email = user.email;
            const username = user.displayName;
            const userId = user.uid;
            const emailVerified = user.emailVerified;

            console.log('User Info:', { email, username, userId, emailVerified });

            // Send email and username to backend token generator API
            const response = await axios.post('https://api-bny5h3g2lq-uc.a.run.app/api/google-signin', {
                email,
                name: username,
                uid: userId,
                emailVerified: emailVerified
            });

            const { token, name, plan, message } = response.data;
            console.log(message + " " + response.status + " " + userId)

            if (response.status === 201 && message === 'User profile completion required.') {
                // Navigate to profile completion page with email and username
                navigate('/complete-registration', {
                    state: {
                        email, name: username, userId: userId, emailVerified
                    }
                });
                console.log('Navigating with state:', {
                    email, name: username, userId: userId, emailVerified
                });

                setLoading(false);
            } else if (response.status === 200) {
                // Handle successful login (user already completed profile)
                handleLoginSuccess(token, name, userId, plan);
            }


        } catch (error) {
            console.error('Google authentication failed:', error);
            setError('Failed to authenticate with Google.');
            setLoading(false);
        }
        finally {
            setEmail('');
            setPassword('')
            setLoading(false)
        }
    };

    const handleLoginSuccess = (token, name, uid, plan) => {
        setSecureCookie('accessToken', token, { expires: 7, secure: true });
        setSecureCookie('uid', uid, { expires: 7, secure: true });
        setSecureCookie('username', name, { expires: 7, secure: true });
        setSecureCookie('plan', plan, { expires: 7, secure: true });

        setLoading(false);
        window.location.replace('/');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const passwordError = validatePassword(password);

        if (passwordError) {
            setError(passwordError);
            return;
        }

        if (password !== repassword) {
            setError('Passwords do not match');
            return;
        }

        setLoading(true);
        setError('');

        // Simple validation
        if (!email || !password || !name || !country) {
            setError('All fields are required');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post('https://api-bny5h3g2lq-uc.a.run.app/api/signup', {
                name,
                email,
                password,
                country, // Include country in the signup data
            });

            if (response.status === 201 || response.status === 200) {
                const { token, uid, name, plan } = response.data;
                setSecureCookie('accessToken', token, { expires: 7, secure: true });
                setSecureCookie('uid', uid, { expires: 7, secure: true });
                setSecureCookie('username', name, { expires: 7, secure: true });
                setSecureCookie('plan', plan, { expires: 7, secure: true });

                setLoading(false);
                window.location.replace('/');
            }
        } catch (err) {
            if (err.response) {
                console.log(err.response.status)
                if (err.response.status === 400) {
                    setError('Email already exists');
                } else {
                    setError('Error creating account. Please try again later.');
                }
            } else {
                setError('Network error. Please check your connection.');
            }
            setLoading(false);
        }
        finally {
            setEmail('');
            setPassword('')
            setLoading(false)
        }
    };


    return (
        <>
            <div className="flex items-center justify-center min-h-screen mt-5">
                <div className="bg-white/10 shadow-xl rounded-3xl overflow-hidden w-full max-w-md mt-20 mb-10">
                    <div className="p-8 flex flex-col justify-center">
                        <div className="flex justify-center mb-2">
                            <img
                                src="https://res.cloudinary.com/dbe2fast6/image/upload/v1729357377/logo-botanion-01_us1eyb.svg"
                                alt="logo"
                                className="w-14 h-14"
                            />
                        </div>
                        <h2 className="text-3xl font-bold text-center text-purple-300 mb-2">
                            Create Your Account!
                        </h2>
                        <p className="text-center text-gray-200 mb-3">
                            Innovating the Future, <br /> Build Once, Use Anywhere
                        </p>
                        <form onSubmit={handleSubmit}>
                            {error && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                                    <span>{error}</span>
                                </div>
                            )}
                            <div className="mb-2">
                                <label className="block text-gray-200 font-medium">Name*</label>
                                <input
                                    type="text"
                                    className="w-full p-3 border border-gray-300 rounded-2xl bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-600"
                                    placeholder="Ex: Amila Gunawardhana"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        if (error) setError('');
                                    }}
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-gray-200 font-medium">Email*</label>
                                <input
                                    type="email"
                                    className="w-full p-3 border border-gray-300 rounded-2xl bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-600"
                                    placeholder="Ex: amila@domain.com"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        if (error) setError('');
                                    }}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-200 font-medium">Password*</label>
                                <input
                                    type="password"
                                    className="w-full p-3 border border-gray-300 rounded-2xl bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-600"
                                    placeholder="Create a strong Password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        if (error) setError('');
                                    }}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-200 font-medium">Re-Password*</label>
                                <input
                                    type="password"
                                    className="w-full p-3 border border-gray-300 rounded-2xl bg-white/5 text-white shadow-sm focus:ring-2 focus:ring-purple-600"
                                    placeholder="Confirm Your Password"
                                    value={repassword}
                                    onChange={(e) => {
                                        setRePassword(e.target.value);
                                        if (error) setError('');
                                    }}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-200 font-medium">Country*</label>
                                <select
                                    className="w-full p-3 mt-2 border rounded-2xl bg-white/5 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
                                    value={country}
                                    onChange={(e) => {
                                        setCountry(e.target.value);
                                        if (error) setError('');
                                    }}
                                    required
                                >
                                    <option value="" disabled>
                                        Select your country
                                    </option>
                                    {countries.map((country) => (
                                        <option
                                            key={country.cca2}
                                            value={country.name.common}
                                            className="bg-black text-white"
                                        >
                                            {country.name.common}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button
                                type="submit"
                                className={`w-full bg-purple-600 text-white py-3 rounded-lg font-semibold transition duration-300 ${Loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-700'
                                    }`}
                                disabled={Loading}
                            >
                                {Loading ? "Processing..." : "Register"}
                            </button>
                        </form>
                        <div className="flex items-center justify-center mt-4">
                            <div className="border-t w-1/3"></div>
                            <span className="mx-2 text-gray-400">or</span>
                            <div className="border-t w-1/3"></div>
                        </div>
                        <button className="w-full mt-4 bg-white text-black border py-3 rounded-lg flex items-center justify-center gap-2 hover:bg-gray-100" onClick={handleGoogleAuth}>
                            <FcGoogle size={24} />
                            Register with Google
                        </button>
                        <p className="text-center mt-6 text-gray-200">
                            Already have an account?{' '}
                            <a href="/login" className="text-purple-400 font-bold">
                                Login
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegisterPage;
