import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { themes, colors, images } from './Theme';
import Sidebar from './SideBar';
import { globalIcons, templates } from './Theme';
import BasicChatArea from './Templates/ChatAreaTemplates/BasicChatArea';
import StandardChatArea from './Templates/ChatAreaTemplates/StandardChatArea';
import BasicHeader from './Templates/Header/basicHeader';
import GrandHeader from './Templates/Header/GrandHeader';
import StandardHeader from './Templates/Header/StandardHeader';
import GradientHeader from './Templates/Header/GradientHeader';
import BasicChatInput from './Templates/inputBoxTemplates/BasicInputBox';
import FormalChatInput from './Templates/inputBoxTemplates/FormalInputBox';
import MinimalChatInput from './Templates/inputBoxTemplates/MinimalInputBox';
import StandardChatInput from './Templates/inputBoxTemplates/StandardInputBox';
import MinimalHeader from './Templates/Header/MinimalistHeader'; // Added import
import IconicHeader from './Templates/Header/IconicHeader'; // Added import
import WaveHeader from './Templates/Header/WaveHeader'; // Added import
import DiagonalHeader from './Templates/Header/DiagonalHeader'; // Added import
import CurvedGradientHeader from './Templates/Header/CurvedGradientHeader'; // Added import
import DarkBoldWaveHeader from './Templates/Header/DarkBoldWaveHeader'; // Added import
import SimpleHeader from './Templates/Header/SimpleHeader'; // Added import
import ChatHeader from './Templates/Header/ChatHeader'; // Added import
import BubbleShapedChatArea from './Templates/ChatAreaTemplates/BubbleShapedChatArea'; // Added import
import BorderChatArea from './Templates/ChatAreaTemplates/BorderChatArea'; // Added import
import Border2ChatArea from './Templates/ChatAreaTemplates/Border2ChatArea'; // Added import
import TriangleStyle from './Templates/ChatAreaTemplates/TriangleStyle'; // Added import
import StepIndicator from './StepIndicator';
import Cookies from 'js-cookie';
import { getSecureCookie } from '../utilities/safeSaves';

const CustomizeAIChatBotDesign = () => {
    const { chatbotId } = useParams();

    // Split state into individual pieces for real-time updates
    const [headerId, setHeaderId] = useState('Basic');
    const [messageAreaId, setMessageAreaId] = useState('Basic');
    const [inputBoxId, setInputBoxId] = useState('Basic');
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchError, setSearchError] = useState(null);
    const [messages, setMessages] = useState([

    ]);
    const [tagline, setTagLine] = useState('I am an Assistant Bot')
    const [botTyping, setBotTyping] = useState(false);
    const messagesEndRef = useRef(null);
    const [currentTheme, setCurrentTheme] = useState(colors);
    const isInitialRender = useRef(true);
    const [chatbotWidth, setChatbotWidth] = useState('400px');
    const [sendIcon, setSendIcon] = useState(globalIcons.sendIcon);
    const [avatars, setAvatars] = useState(images);
    const [customThemes, setCustomThemes] = useState([]);
    const [currentStep, setCurrentStep] = useState(4);
    const [initialized, setInitialized] = useState(true)
    const uid = getSecureCookie('uid');
    const token = getSecureCookie('accessToken');
    const [updateLoading, setUpdateLoading] = useState(false);
    const [botLoading, setIsLoading] = useState(true);
    const [botName, setBotName] = useState("");

    const commonTemplateProps = {
        messages,
        botTyping,
        avatars,
        currentTheme,
        chatbotWidth,
        sendIcon,
        setSearchQuery,
        searchQuery,
    };

    const fetchChatbotData = async () => {
        const uid = getSecureCookie('uid');
        const token = getSecureCookie('accessToken');

        try {
            setIsLoading(true);
            const response = await axios.get(`https://api-bny5h3g2lq-uc.a.run.app/api/chatbot/${chatbotId}/${uid}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.data) {
                const botName = response.data.botName;
                setBotName(botName)

                if (response.data.greetings && Array.isArray(response.data.greetings)) {
                    // Create the greeting messages
                    const greetings = response.data.greetings.map(greet => ({
                        text: greet,
                        type: 'bot'
                    }));

                    // Set the initial greeting messages
                    setMessages(greetings);
                    localStorage.setItem(`greetings-${chatbotId}`, JSON.stringify(greetings)); // Save greetings to localStorage

                    // After greetings are set, you can add the user message and another bot message
                    const userMessage = { text: "can you tell about your pricings details?", type: 'user' }; // Assuming userInput is defined
                    const botResponse = {
                        text: 'Botanion operates on a subscription and credit system. We do not offer refunds for any monthly or yearly subscription fees or credit purchases, as all sales are final. You can view the pricing details for our chatbot solutions on our Pricing page at https://www.botanion.cc/pricing', type: 'bot'
                    }; // Replace with actual bot response

                    // Combine all messages
                    const updatedMessages = [...greetings, userMessage, botResponse];

                    // Update the state with the combined messages
                    setMessages(updatedMessages);
                }

            }

            if (response.data && response.data.customization) {
                const customizationData = response.data.customization;
                const removeQuotes = (value) => (typeof value === 'string' ? value.replace(/"/g, '') : value);

                const newCustomTheme = customizationData.customTheme || colors;
                const icon = removeQuotes(customizationData.icon || sendIcon);
                const header = removeQuotes(customizationData.selectedHeader || 'basic');
                const messageArea = removeQuotes(customizationData.selectedMessageArea || 'basic');
                const inputBox = removeQuotes(customizationData.selectedInputBox || 'basic');
                const themeName = removeQuotes(customizationData.themeName || 'default');
                const width = customizationData.width || '360px';
                const tagline = customizationData.tagline || 'I am an assistant Bot'
                const botAvatar = customizationData.newAvatar


                // Set states and save each item to localStorage with a namespaced key
                setCustomThemes(newCustomTheme);
                localStorage.setItem(`customTheme-${chatbotId}`, JSON.stringify(newCustomTheme));

                setHeaderId(header);
                localStorage.setItem(`selectedHeader-${chatbotId}`, header);

                setMessageAreaId(messageArea);
                localStorage.setItem(`selectedMessageArea-${chatbotId}`, messageArea);

                setInputBoxId(inputBox);
                localStorage.setItem(`selectedInputBox-${chatbotId}`, inputBox);

                setChatbotWidth(width);
                localStorage.setItem(`width-${chatbotId}`, width);

                setCurrentTheme(newCustomTheme);
                localStorage.setItem(`themeName-${chatbotId}`, themeName);

                setSendIcon(globalIcons[icon] || globalIcons.sendIcon);
                localStorage.setItem(`icon-${chatbotId}`, icon);

                setTagLine(tagline)
                localStorage.setItem(`tagline-${chatbotId}`, tagline);

                handleAvatarChange('botAvatar', botAvatar)
                localStorage.setItem(`newAvatar-${chatbotId}`, botAvatar);


            } else {
                const newCustomTheme = colors;
                const icon = sendIcon;
                const header = 'basic';
                const messageArea = 'basic';
                const inputBox = 'basic';
                const themeName = 'default';
                const width = '360px';

                // Set states and save each item to localStorage with a namespaced key
                setCustomThemes(newCustomTheme);
                localStorage.setItem(`customTheme-${chatbotId}`, JSON.stringify(newCustomTheme));

                setHeaderId(header);
                localStorage.setItem(`selectedHeader-${chatbotId}`, header);

                setMessageAreaId(messageArea);
                localStorage.setItem(`selectedMessageArea-${chatbotId}`, messageArea);

                setInputBoxId(inputBox);
                localStorage.setItem(`selectedInputBox-${chatbotId}`, inputBox);

                setChatbotWidth(width);
                localStorage.setItem(`width-${chatbotId}`, width);

                setCurrentTheme(newCustomTheme);
                localStorage.setItem(`themeName-${chatbotId}`, themeName);

                setSendIcon(globalIcons[icon] || globalIcons.sendIcon);
                localStorage.setItem(`icon-${chatbotId}`, icon);

            }
        } catch (error) {
            console.error('Error fetching chatbot custom data:', error);
        }
        finally {
            setIsLoading(false); // Stop loading
        }
    };


    useEffect(() => {
        // Check if customization data is already available in local storage
        const customizationExists = Object.keys(localStorage).some(key => key.endsWith(`_${chatbotId}`));
        fetchChatbotData(); // Fetch if customization data is not available
    }, [chatbotId]);


    const handleThemeChange = (themeName) => {
        const selectedTheme = themes[themeName] || colors;
        setCurrentTheme(selectedTheme);
        localStorage.setItem(`themeName-${chatbotId}`, themeName);
    };

    const handleAvatarChange = (avatarType, newAvatar) => {
        setAvatars((prevAvatars) => ({
            ...prevAvatars,
            [avatarType]: newAvatar,
        }));
        localStorage.setItem(`newAvatar-${chatbotId}`, JSON.stringify(newAvatar));
    };

    const handleCustomThemeChange = (updatedCustomColors) => {
        setCustomThemes((prevThemes) => {
            // Ensure prevThemes is an array
            const currentThemes = Array.isArray(prevThemes) ? prevThemes : [];

            return [...currentThemes, updatedCustomColors];
        });
        setCurrentTheme(updatedCustomColors);
    };


    const searchChatbots = async (query) => {
        setLoading(true);
        setSearchError(null);


        try {
            const response = await axios.post('https://api-bny5h3g2lq-uc.a.run.app/chatbot-search', {
                chatbotId,
                uid,
                token,
                query,
            });

            setSearchResults(response.data);

            setTimeout(() => {
                const finalResponse = response.data.response;
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { text: DOMPurify.sanitize(finalResponse), type: 'bot' },
                ]);
                setBotTyping(false);
            }, 1000);
        } catch (error) {
            console.error('Error fetching or processing data:', error);
            setSearchError('Error fetching chatbot data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages, loading, botTyping]);

    const renderHeader = () => {
        switch (headerId) {
            case 'Basic':
                return <BasicHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Simple':
                return <StandardHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            // case 'Grand':
            //     return <GrandHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Minimal':
                return <MinimalHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Standard':
                return <GradientHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Iconic':
                return <IconicHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Wave':
                return <WaveHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Diagonal':
                return <DiagonalHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Curved':
                return <CurvedGradientHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            // case 'DarkBoldWave':
            //     return <DarkBoldWaveHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            case 'Elegant':
                return <SimpleHeader currentTheme={currentTheme} botName={botName} avatars={avatars} tagline={tagline} />;
            // case 'Chat':
            //     return <ChatHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
            default:
                return <BasicHeader currentTheme={currentTheme} botName={botName} avatars={avatars} />;
        }
    };

    const renderBody = () => {
        switch (messageAreaId) {
            case 'Basic Dialogue Bubble':
                return <BasicChatArea {...commonTemplateProps} />;
            case 'Standard Dialogue Bubble':
                return <StandardChatArea {...commonTemplateProps} />;
            // case 'BubbleShape':
            //     return <BubbleShapedChatArea {...commonTemplateProps} />;
            case 'Bordered Dialogue Bubble':
                return <BorderChatArea {...commonTemplateProps} />;
            // case 'Border2Chat':
            //     return <Border2ChatArea {...commonTemplateProps} />;
            case 'Classic Dialogue Bubble':
                return <TriangleStyle {...commonTemplateProps} />;
            default:
                return <BasicChatArea {...commonTemplateProps} />;
        }
    };

    const renderFooter = () => {
        switch (inputBoxId) {
            case 'Basic':
                return <BasicChatInput {...commonTemplateProps} />;
            case 'Formal':
                return <FormalChatInput {...commonTemplateProps} />;
            case 'Minimal':
                return <MinimalChatInput {...commonTemplateProps} />;
            case 'Standard':
                return <StandardChatInput {...commonTemplateProps} />;
            default:
                return <BasicChatInput {...commonTemplateProps} />;
        }
    };




    // useEffect(() => {
    //     // Fetch and set the customization data when the component mounts or chatbotId changes
    //     const storedCustomizationData = getCustomizationData();
    //     setCustomizationData(prevData => ({
    //         ...prevData,
    //         ...storedCustomizationData, // Merge with existing state
    //     }));
    // }, [chatbotId]);



    return (
        <>
            <div className="ml-60 flex min-h-screen items-center justify-center">
                <Sidebar
                    onHeaderChange={setHeaderId}
                    onBodyChange={setMessageAreaId}
                    onFooterChange={setInputBoxId}
                    onThemeChange={handleThemeChange}
                    onWidthChange={setChatbotWidth}
                    onIconChange={setSendIcon}
                    onAvatarChange={handleAvatarChange}
                    onCustomThemeChange={handleCustomThemeChange}
                    currentTheme={currentTheme}
                    botId={chatbotId}
                    customTheme={customThemes}
                    updateLoading={updateLoading}
                    setUpdateLoading={setUpdateLoading}
                    isInitialRender={isInitialRender}
                    fetchChatbotData={fetchChatbotData}
                    onTagLineChange={setTagLine}
                />
                {/* Main Content Wrapper */}
                <div className="flex flex-col items-center w-full max-w-5xl px-4">
                    {/* Step Indicator */}
                    <StepIndicator currentStep={currentStep} />
                    <div className="shadow-2xl rounded-xl overflow-hidden relative bg-white border-4 border-gray-400"
                        style={{ width: chatbotWidth }}>

                        {/* Loading Indicator */}
                        {botLoading ? (
                            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-gray-600"></div>
                                <span className="ml-2 text-gray-600 font-semibold">Loading...</span>
                            </div>
                        ) : (
                            <>
                                {/* Content to display once loading is complete */}
                                {renderHeader()}
                                {renderBody()}
                                {renderFooter()}
                                <div ref={messagesEndRef} />
                            </>
                        )}
                    </div>

                </div>

            </div>
        </>
    );
};

export default CustomizeAIChatBotDesign;
