// LoadingScreen.jsx
import React, { useEffect, useState } from 'react';

const LoadingScreen = ({ textToShow }) => {
    const [isPendulum, setIsPendulum] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsPendulum(false); // Switch to full rotation after 3 seconds
        }, 3000);

        const resetPendulum = setInterval(() => {
            setIsPendulum(true); // Revert back to pendulum movement
            setTimeout(() => setIsPendulum(false), 3000); // Trigger full rotation again after 3 seconds
        }, 6000); // Full cycle time (pendulum + rotation)

        return () => {
            clearTimeout(timer);
            clearInterval(resetPendulum);
        };
    }, []);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-br from-pink-800 via-blue-700 to-indigo-900 z-50 bg-opacity-75">
            <div className="flex flex-col items-center">
                <div className="loader">
                    <img
                        src="https://res.cloudinary.com/dbe2fast6/image/upload/v1729357377/logo-botanion-01_us1eyb.svg"
                        alt="Robot Icon"
                        style={{ width: '20%' }}
                        className={isPendulum ? 'pendulum' : 'rotate'} // Conditionally apply animation
                    />
                </div>
                <p className="mt-4 text-xl font-semibold text-gray-100 animate-pulse">{textToShow}</p>
                <div className="spinner mt-4"></div>
            </div>

            <style jsx>{`
                .loader {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .loader img {
                    width: 100px;
                    transform-origin: top center;
                }

                .pendulum {
                    animation: pendulum 2s ease-in-out infinite;
                }

                .rotate {
                    animation: spin 3s linear infinite;
                }

                @keyframes pendulum {
                    0% {
                        transform: rotate(15deg);
                    }
                    50% {
                        transform: rotate(-15deg);
                    }
                    100% {
                        transform: rotate(15deg);
                    }
                }

                @keyframes spin {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }

                .spinner {
                    border: 4px solid rgba(255, 255, 255, 0.3);
                    border-top: 4px solid white;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    animation: spin 1s linear infinite;
                }
            `}</style>
        </div>
    );
};

export default LoadingScreen;
