// ChatInput.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FormalChatInput = ({
    searchQuery,
    setSearchQuery,
    sendIcon,
    currentTheme
}) => {
    return (
        <form className="flex p-2 items-center bg-white border-t border-gray-200">
            <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Type a message..."
                className="flex-1 py-2 px-4 bg-transparent focus:outline-none"
            />

            <button
                type="submit"
                className="text-white px-4 py-2 rounded-full transition-transform transform hover:scale-105"
                style={{ backgroundColor: currentTheme.buttonBg }}
                onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = currentTheme.buttonHoverBg)
                }
                onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = currentTheme.buttonBg)
                }
            >
                <FontAwesomeIcon icon={sendIcon} />
            </button>
        </form>
    );
};

export default FormalChatInput;
