import React from 'react';
import { FaEllipsisV, FaCircle } from 'react-icons/fa';

const DiagonalHeader = ({ currentTheme, botName, avatars }) => {
    return (
        <div className="relative  overflow-hidden bg-white/0" style={{ borderBottom: 'none' }} >
            {/* Diagonal Gradient Background */}
            <div
                className="w-full h-28"
                style={{ backgroundColor: currentTheme.headerBg, clipPath: 'polygon(0 0, 100% 0%, 100% 70%, 0% 100%)', }}

            ></div>

            {/* Profile Picture and Name */}
            <div className="absolute top-8 left-4 flex items-center">
                {/* Profile Picture */}
                <img
                    src={avatars.botAvatar}
                    alt="User Avatar"
                    className="h-12 w-12 rounded-full border-2 border-white mr-2"
                />
                {/* User Name */}
                <div className="flex flex-col text-left" style={{ color: currentTheme.headerTextColor }}>
                    <span className="text-lg font-semibold">{botName}</span>
                    {/* Online Status */}
                    <div className="flex items-center text-sm">
                        <FaCircle className="text-green-400 mr-1" />
                        <span>We are online!</span>
                    </div>
                </div>
            </div>

            {/* Action Menu Icon */}
            {/* <div className="absolute top-4 right-4">
                <FaEllipsisV className="text-white" />
            </div> */}
        </div>
    );
};

export default DiagonalHeader;