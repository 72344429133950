import React from 'react';
import { FaCircle, FaTimes } from 'react-icons/fa'; // Import FaTimes for the close icon
// import { FaTimesCircle } from 'react-icons/fa';
// import { FaRegWindowClose } from 'react-icons/fa';
// import { FaBackspace } from 'react-icons/fa';
// import { FaMinus } from 'react-icons/fa';
// import { FaEnvelopeOpen } from 'react-icons/fa';
// import { FaHeartBroken } from 'react-icons/fa';
import { FaAngleDown } from 'react-icons/fa';
// import { FaArrowDown } from 'react-icons/fa';
// import { FaCaretDown } from 'react-icons/fa';

const WaveHeader = ({ currentTheme, onClose, botName, avatars }) => { // Add onClose prop to handle close action
    var gradientColor = `linear-gradient(to right, ${currentTheme.buttonBg}, ${currentTheme.buttonHoverBg})`;
    return (
        <div className="relative shadow-lg overflow-hidden text-white">
            {/* Wave Gradient Background */}
            <div
                className="w-full h-36 relative"
                style={{
                    backgroundColor: currentTheme.headerBg, color: currentTheme.headerTextColor,
                    boxShadow: 'none',
                }}
            >
                {/* Bigger Wave Shape */}
                <svg
                    className="absolute bottom-0 w-full h-20"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                >
                    <path
                        fill="white"
                        fill-opacity="0.5"
                        d="M0,240L48,215C96,190,192,145,288,155C384,165,480,225,576,255C672,285,768,285,864,265C960,245,1056,210,1152,190C1248,170,1344,170,1392,170L1440,170L1440,340L0,340Z"
                    ></path>


                </svg>
            </div>

            {/* Profile Picture and Name */}
            <div className="absolute top-10 left-4 flex items-center">
                {/* Profile Picture */}
                <img
                    src={avatars.botAvatar}
                    alt="User Avatar"
                    className="h-12 w-12 rounded-full border-2 border-white mr-2"
                />
                {/* User Name */}
                <div className="flex flex-col text-left">
                    <span className="text-lg font-semibold">{botName}</span>
                    {/* Online Status */}
                    <div className="flex items-center text-sm">
                        <FaCircle className="text-green-400 mr-1" />
                        <span>We are online!</span>
                    </div>
                </div>
            </div>

            {/* Close Button */}
            <div className="absolute top-4 right-4 cursor-pointer" onClick={onClose}>
                <FaAngleDown className="text-white text-xl" />
            </div>

            {/* Action Menu Icon */}
            {/* <div className="absolute top-4 right-4">
                <FaEllipsisV className="text-white" />
            </div> */}
        </div>
    );
};

export default WaveHeader;