import { faPaperPlane, faRocket, faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';

export const templates = {
    Classic: {
        headerId: "Basic",
        messageAreaId: "Standard",
        inputBoxId: "Standard",
    },
    Classic_2: {
        headerId: "Standard",
        messageAreaId: "Standard",
        inputBoxId: "Standard",
    },
    Basic: {
        headerId: "Basic",
        messageAreaId: "Basic",
        inputBoxId: "Basic",
    },
    Basic_2: {
        headerId: "Grand",
        messageAreaId: "Standard",
        inputBoxId: "Standard",
    },
    Formal: {
        headerId: "Basic",
        messageAreaId: "Standard",
        inputBoxId: "Formal",
    },
    Formal_2: {
        headerId: "Basic",
        messageAreaId: "Standard",
        inputBoxId: "Minimal",
    },
    Formal_3: {
        headerId: "Standard",
        messageAreaId: "Standard",
        inputBoxId: "Minimal",
    },
    Formal_4: {
        headerId: "Standard",
        messageAreaId: "Standard",
        inputBoxId: "Formal",
    },

};

export const HEADER_IDS = ["Basic", "Simple", "Minimal", "Standard", "Iconic", "Wave", "Diagonal", "Curved", "Elegant"];
export const MESSAGE_AREA_IDS = ["Basic Dialogue Bubble", "Standard Dialogue Bubble", "Bordered Dialogue Bubble", "Classic Dialogue Bubble"];
export const INPUT_BOX_IDS = ["Basic", "Standard", "Formal", "Minimal"];

// theme.js
export const colors = {
    userMessageBg: '#1D4ED8',  // Default Blue for user messages
    botMessageBg: '#D1D5DB',   // Default Gray for bot messages
    botTextColor: '#111827',   // Default Dark Gray for bot text
    buttonBg: '#1D4ED8',       // Default Blue for buttons
    buttonHoverBg: '#2563EB',  // Default Hover Blue for buttons
    gradientFrom: '#34D399',   // Default Green for gradient
    gradientTo: '#3B82F6',     // Default Blue for gradient
    inputBorder: '#D1D5DB',    // Default Gray for input border
    inputTextColor: '#000000', // Default Black for input text
    headerBg: '#1D4ED8',
    headerTextColor: '#fffff',
    botTextColor: '#111827',
    linkTextColor: '#1D4ED8',
};

// Predefined Themes
export const themes = {
    // Nature Themes
    garden: {
        userMessageBg: '#5A9A3A',
        botMessageBg: '#B8DFA7',
        botTextColor: '#355E3B',
        buttonBg: '#5A9A3A',
        buttonHoverBg: '#A3E635',
        gradientFrom: '#A7F3D0',
        gradientTo: '#34D399',
        headerBg: '#5A9A3A', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    greenish: {
        userMessageBg: '#10B981',
        botMessageBg: '#A7F3D0',
        botTextColor: '#064E3B',
        buttonBg: '#059669',
        buttonHoverBg: '#10B981',
        gradientFrom: '#34D399',
        gradientTo: '#059669',
        headerBg: '#059669', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    peacock: {
        userMessageBg: '#006d77',
        botMessageBg: '#83c5be',
        botTextColor: '#023047',
        buttonBg: '#006d77',
        buttonHoverBg: '#008B8B',
        gradientFrom: '#006d77',
        gradientTo: '#70a1a3',
        headerBg: '#006d77', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    forest: {
        userMessageBg: '#2E8B57',
        botMessageBg: '#9ACD32',
        botTextColor: '#006400',
        buttonBg: '#228B22',
        buttonHoverBg: '#32CD32',
        gradientFrom: '#228B22',
        gradientTo: '#2E8B57',
        headerBg: '#228B22', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    ocean: {
        userMessageBg: '#4682B4',
        botMessageBg: '#87CEFA',
        botTextColor: '#0F4C81',
        buttonBg: '#00BFFF',
        buttonHoverBg: '#4682B4',
        gradientFrom: '#4682B4',
        gradientTo: '#1E90FF',
        headerBg: '#00BFFF', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    sunset: {
        userMessageBg: '#E76F51',
        botMessageBg: '#F4A261',
        botTextColor: '#264653',
        buttonBg: '#E9C46A',
        buttonHoverBg: '#e76f51',
        gradientFrom: '#F4A261',
        gradientTo: '#E76F51',
        headerBg: '#E9C46A', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    mountain: {
        userMessageBg: '#51291e',
        botMessageBg: '#EDF4ED',
        botTextColor: '#301014',
        buttonBg: '#4B8B3B',
        buttonHoverBg: '#3B5D28',
        gradientFrom: '#4B8B3B',
        gradientTo: '#301014',
        headerBg: '#4B8B3B', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },

    // Standard Themes
    gold: {
        userMessageBg: '#a47e1b',
        botMessageBg: '#ffe169',
        botTextColor: '#805b10',
        buttonBg: '#c9a227',
        buttonHoverBg: '#edc531',
        gradientFrom: '#c9a227',
        gradientTo: '#ffe169',
        headerBg: '#c9a227', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    red: {
        userMessageBg: '#EF4444',
        botMessageBg: '#F87171',
        botTextColor: '#7F1D1D',
        buttonBg: '#DC2626',
        buttonHoverBg: '#F87171',
        gradientFrom: '#F87171',
        gradientTo: '#DC2626',
        headerBg: '#DC2626', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    bluish: {
        userMessageBg: '#3B82F6',
        botMessageBg: '#93C5FD',
        botTextColor: '#1E40AF',
        buttonBg: '#1D4ED8',
        buttonHoverBg: '#2563EB',
        gradientFrom: '#3B82F6',
        gradientTo: '#60A5FA',
        headerBg: '#1D4ED8', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    brownish: {
        userMessageBg: '#D97706',
        botMessageBg: '#F59E0B',
        botTextColor: '#78350F',
        buttonBg: '#B45309',
        buttonHoverBg: '#F6AD2B',
        gradientFrom: '#B45309',
        gradientTo: '#D97706',
        headerBg: '#B45309', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    silver: {
        userMessageBg: '#8B8B8B',
        botMessageBg: '#C0C0C0',
        botTextColor: '#1C1C1C',
        buttonBg: '#7B7B7B',
        buttonHoverBg: '#5E5E5E',
        gradientFrom: '#A6A6A6',
        gradientTo: '#4F4F4F',
        headerBg: '#7B7B7B', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    black: {
        userMessageBg: '#454955',
        botMessageBg: '#3C3D37',
        botTextColor: '#ECDFCC',
        buttonBg: '#181C14',
        buttonHoverBg: '#697565',
        gradientFrom: '#181C14',
        gradientTo: '#3C3D37',
        headerBg: '#181C14', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },

    // Professional Themes
    medieval: {
        userMessageBg: '#5A3E36',
        botMessageBg: '#A5B3BB',
        botTextColor: '#2F3B42',
        buttonBg: '#2F3B42',
        buttonHoverBg: '#5A3E36',
        gradientFrom: '#A5B3BB',
        gradientTo: '#5A3E36',
        headerBg: '#2F3B42', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    penguin: {
        userMessageBg: '#4B5563',
        botMessageBg: '#D1D5DB',
        botTextColor: '#000000',
        buttonBg: '#374151',
        buttonHoverBg: '#6B7280',
        gradientFrom: '#4B5563',
        gradientTo: '#1F2937',
        headerBg: '#374151', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    sleek: {
        userMessageBg: '#333333',
        botMessageBg: '#666666',
        botTextColor: '#FFFFFF',
        buttonBg: '#333333',
        buttonHoverBg: '#4F4F4F',
        gradientFrom: '#333333',
        gradientTo: '#555555',
        headerBg: '#333333', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    tech: {
        userMessageBg: '#00ADB5',
        botMessageBg: '#393E46',
        botTextColor: '#EEEEEE',
        buttonBg: '#00ADB5',
        buttonHoverBg: '#007B80',
        gradientFrom: '#00ADB5',
        gradientTo: '#393E46',
        headerBg: '#00ADB5', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },

    // Fantasy Themes
    galaxy: {
        userMessageBg: '#4B0082',
        botMessageBg: '#8A2BE2',
        botTextColor: '#E0BBE4',
        buttonBg: '#6A0DAD',
        buttonHoverBg: '#8A2BE2',
        gradientFrom: '#4B0082',
        gradientTo: '#6A0DAD',
        headerBg: '#6A0DAD', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    nebula: {
        userMessageBg: '#EE82EE',
        botMessageBg: '#D8BFD8',
        botTextColor: '#9400D3',
        buttonBg: '#DA70D6',
        buttonHoverBg: '#EE82EE',
        gradientFrom: '#DA70D6',
        gradientTo: '#EE82EE',
        headerBg: '#DA70D6', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    dragon: {
        userMessageBg: '#6A2918',
        botMessageBg: '#F7BD62',
        botTextColor: '#090604',
        buttonBg: '#34100A',
        buttonHoverBg: '#434242',
        gradientFrom: '#1F1F1B',
        gradientTo: '#6A2918',
        headerBg: '#34100A', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    fire: {
        userMessageBg: '#DA1F05',
        botMessageBg: '#FFC11F',
        botTextColor: '#A10100',
        buttonBg: '#F33C04',
        buttonHoverBg: '#FE650D',
        gradientFrom: '#A10100',
        gradientTo: '#FFF75D',
        headerBg: '#F33C04', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },

    // Abstract Themes
    neon: {
        userMessageBg: '#FC1FF9',
        botMessageBg: '#BC0EEF',
        botTextColor: '#443061',
        buttonBg: '#E42536',
        buttonHoverBg: '#FC5E31',
        gradientFrom: '#FC1FF9',
        gradientTo: '#BC0EEF',
        headerBg: '#E42536', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
    industrialGlow: {
        userMessageBg: '#FF5BFF',
        botMessageBg: '#D34DEE',
        botTextColor: '#FFFFFF',
        buttonBg: '#2AC9F9',
        buttonHoverBg: '#56F1FF',
        gradientFrom: '#FF5BFF',
        gradientTo: '#D34DEE',
        headerBg: '#2AC9F9', headerTextColor: '#FFFFFF', botTextColor: '#111827', linkTextColor: '#1D4ED8', //added header Text
    },
};


export const images = {
    userAvatar: 'https://th.bing.com/th/id/OIP.hxh6omrkOuj2l9rGHNe7qQHaHa?w=188&h=188&c=7&r=0&o=5&dpr=1.3&pid=1.7',
    botAvatar: 'https://res.cloudinary.com/dbe2fast6/image/upload/v1728982612/logo-botanion-01_us1eyb.svg',  // Bot avatar path
};

export const defaultDimensions = {
    width: '400px', // Default width
    height: '600px', // Default height
};

// Define icons globally that can be used in Sidebar or other places
export const globalIcons = {
    sendIcon: faPaperPlane, // Default icon
    rocketIcon: faRocket,   // Search icon
    confirmIcon: faCheck,   // Confirm icon
    arrowIcon: faArrowRight // Arrow icon
};

export const predefinedUserAvatars = [
    'https://cdn3.iconfinder.com/data/icons/web-design-and-development-2-6/512/87-1024.png',
    'https://www.pngmart.com/files/22/User-Avatar-Profile-Transparent-Background.png',
    'https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes.png',
    'https://cdn3.iconfinder.com/data/icons/web-design-and-development-2-6/512/87-1024.png',
    'https://www.pngmart.com/files/22/User-Avatar-Profile-Transparent-Background.png',
    'https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes.png',
    'https://cdn3.iconfinder.com/data/icons/web-design-and-development-2-6/512/87-1024.png',
    'https://www.pngmart.com/files/22/User-Avatar-Profile-Transparent-Background.png',
    'https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes.png',
    'https://cdn3.iconfinder.com/data/icons/web-design-and-development-2-6/512/87-1024.png',
    'https://www.pngmart.com/files/22/User-Avatar-Profile-Transparent-Background.png',
    'https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes.png',
    'https://cdn3.iconfinder.com/data/icons/web-design-and-development-2-6/512/87-1024.png',
    'https://www.pngmart.com/files/22/User-Avatar-Profile-Transparent-Background.png',
    'https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes.png',
    'https://cdn3.iconfinder.com/data/icons/web-design-and-development-2-6/512/87-1024.png',
    'https://www.pngmart.com/files/22/User-Avatar-Profile-Transparent-Background.png',
    'https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes.png',
];

export const predefinedBotAvatars = [
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470171/woman-avatar-22_dch7rd.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731469988/woman-avatar-20_pcpmqg.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470171/woman-avatar-09_b31iqj.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470168/woman-avatar-01_rq6cc3.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470163/woman-avatar-07_edxrey.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470162/woman-avatar-12_sayxwj.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470162/woman-avatar-19_gv5ety.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470162/woman-avatar-06_vmebhc.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470162/woman-avatar-13_byylrw.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470160/woman-avatar-08_hshj22.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470159/woman-avatar-03_p12fnl.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470157/woman-avatar-21_rwazfo.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470155/woman-avatar-15_ndohjx.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470151/woman-avatar-16_jf0gbw.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470147/woman-avatar-18_nbjoid.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470146/woman-avatar-20_htgjv1.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470121/avatar-05_xfkrrp.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470131/avatar-08_mygog3.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731469007/d880dcc5-9b8d-4f7b-b16a-b8c23eda2030_hteyp5.jpg',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470121/avatar-04_kefq6o.png',
    'https://res.cloudinary.com/dz1yqf7jx/image/upload/v1731470002/woman-avatar-08_ydx4vi.png',
];
