import { Link } from 'react-router-dom';
import Carousel from './Carousel';
import Footer from './Footer';
import { useSwipeable } from 'react-swipeable';
import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import useScrollAnimation from '../utilities/useScrollAnimation'; // Import custom hook
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'; // Example using react-icons
import { Helmet } from 'react-helmet-async';

function LandingPage() {


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);

    const handleNext = () => {
        setCurrentFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
    };

    const handlePrevious = () => {
        setCurrentFeatureIndex((prevIndex) =>
            prevIndex === 0 ? features.length - 1 : prevIndex - 1
        );
    };

    const newFeature = [
        { text: "Enables easy connections between different systems, making integrations smooth and efficient.", x: -200, y: -150 },
        { text: "Built to actively engage users, creating a positive and interactive experience.", x: 200, y: -100 },
        { text: "Designed for easy and intuitive interactions, so customers feel comfortable and connected.", x: -350, y: 70 },
        { text: "Always available to assist, providing support anytime, day or night.", x: 350, y: 70 },
        { text: "Optimized to deliver high-quality service while keeping costs low.", x: -150, y: 180 },
        { text: "Efficiently manages customer questions and requests, providing reliable answers quickly.", x: 210, y: 240 }
    ];

    const newCustomFeature = [
        "Application Programming Interfaces (APIs)",
        "Engaging Capabilities",
        "User-Friendly Interaction",
        "24/7 Customer Service",
        "Cost-Efficient Solution",
        "Handles Customer Queries"
    ];

    const [currentSlide, setCurrentSlide] = React.useState(0);
    const features = [
        {
            img: "https://res.cloudinary.com/dbe2fast6/image/upload/v1728982602/customizable_njdpwv.png",
            title: "Customizable",
            description: "Effortlessly design bots tailored to your business needs, with complete control over responses and behavior."
        },
        {
            img: "https://res.cloudinary.com/dbe2fast6/image/upload/v1728982602/AiPowered_1_lvthbf.png",
            title: "AI-Powered",
            description: "Utilize AI to enhance user interactions and provide intelligent, context-aware responses."
        },
        {
            img: "https://res.cloudinary.com/dbe2fast6/image/upload/v1728982601/easy-integration_sbl9qz.png",
            title: "Easy Integration",
            description: "Quickly embed your chatbot into any website or application without the need for coding skills."
        },
        {
            img: "https://res.cloudinary.com/dbe2fast6/image/upload/v1728982599/cloud_ng0yic.png",
            title: "Cloud-Based",
            description: "Everything runs in the cloud, ensuring smooth performance and easy access whenever you need it."
        },
    ];



    const handlers = useSwipeable({
        onSwipedLeft: () => setCurrentSlide((prev) => (prev + 1) % features.length),
        onSwipedRight: () => setCurrentSlide((prev) => (prev - 1 + features.length) % features.length),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const [isSplatOut, setIsSplatOut] = useState(true);

    const toggleSplat = () => setIsSplatOut(!isSplatOut);

    const featureVariants = {
        splatOut: (i) => ({
            x: newFeature[i].x,
            y: newFeature[i].y,
            opacity: 1,
            transition: { delay: i * 0.1, type: "spring", stiffness: 100 }
        }),
        splatIn: {
            x: 0,
            y: 0,
            opacity: 0,
            transition: { type: "spring", stiffness: 100 }
        }
    };

    const newCustomFeatureVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: (i) => ({
            opacity: 1,
            x: 0,
            transition: { delay: i * 0.3 }
        })
    };

    // Refs for sections
    const heroRef = useRef(null);
    const whyChooseUsRef = useRef(null);
    const splatRef = useRef(null);
    const callToActionRef = useRef(null);

    // Scroll animation states
    const isHeroVisible = useScrollAnimation(heroRef);
    const isWhyChooseUsVisible = useScrollAnimation(whyChooseUsRef);
    const isSplatVisible = useScrollAnimation(splatRef);
    const isCallToActionVisible = useScrollAnimation(callToActionRef);

    // Observe splatRef section visibility
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsSplatOut(true);
                } else {
                    setIsSplatOut(false);
                }
            },
            { threshold: 0.5 } // Adjust threshold as needed for when to trigger the effect
        );

        if (splatRef.current) {
            observer.observe(splatRef.current);
        }

        return () => {
            if (splatRef.current) {
                observer.unobserve(splatRef.current);
            }
        };
    }, []);

    // Fixed colors for the features
    const featureColors = ['purple', 'indigo', 'blue', 'black']; // Your fixed colors

    const [pointerIndex, setPointerIndex] = useState(0);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const featureRef = useRef([]);

    const handleScroll = () => {
        newCustomFeature.forEach((_, index) => {
            const featureElement = document.getElementById(`feature-${index}`);
            if (featureElement) {
                const { top, bottom } = featureElement.getBoundingClientRect();
                const isVisible = bottom >= 0 && top <= window.innerHeight;

                setVisibleFeatures((prev) => {
                    const newVisibleFeatures = [...prev];
                    newVisibleFeatures[index] = isVisible; // Update visibility based on scroll position
                    return newVisibleFeatures;
                });
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [visibleFeatures, setVisibleFeatures] = useState(new Array(newCustomFeature.length).fill(false));

    useEffect(() => {
        const movePointer = () => {
            if (featureRef.current && featureRef.current[pointerIndex]) {
                const currentFeature = featureRef.current[pointerIndex];
                const rect = currentFeature.getBoundingClientRect();
                // Calculate the center position for the pointer
                setMousePosition({
                    x: rect.left + rect.width / 2 - 15, // 15 to center the pointer (half its width)
                    y: rect.top + rect.height / 2 - 15, // 15 to center the pointer (half its height)
                });
            }
        };

        // Start the interval to change pointer index
        const intervalId = setInterval(() => {
            setPointerIndex((prevIndex) => (prevIndex + 1) % newFeature.length);
            movePointer();
        }, 2000); // Change every 2 seconds

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, [pointerIndex]);


    return (
        <>
            <Helmet>
                <title>Botanion AI - Build AI-Powered Chatbots and enterprise-level engagement for Your Business</title>
                <meta name="description" content="Create and customize AI-powered chatbots to enhance customer engagement and streamline your business operations. Start your free trial today!" />
                <meta name="keywords" content="chatbot, AI chatbot, customer service, customizable chatbot, business engagement" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://www.botanion.cc/" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Chatbot Service",
                        "provider": {
                            "@type": "Organization",
                            "name": "Botanion",
                            "url": "https://www.botanion.cc/",
                        },
                    })}
                </script>
            </Helmet>
            <div className="mx-0 my-0 overflow-hidden">

                {/* Hero Section */}
                <section ref={heroRef} className="pb-0">
                    <motion.article
                        initial={{ opacity: 0, y: 50 }}
                        animate={isHeroVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                        transition={{ duration: 0.5 }}
                        className="container mx-auto px-6 py-16 text-center"
                    >
                        <h1 className="text-3xl md:text-5xl uppercase font-extrabold text-white mb-4" style={{ textShadow: '4px 4px 4px rgba(0, 0, 0, 0.6)' }}>
                            Build Your Own Chatbot Effortlessly
                        </h1>
                        <p className="text-base md:text-lg mb-8 text-gray-300" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)' }}>
                            Your AI Partner for Smarter, Effortless Customer Connections.
                        </p>
                        <div className="flex flex-wrap items-center justify-center gap-4 mt-6">
                            <Link to="/select-service">
                                <button className="px-6 md:px-8 py-3 md:py-4 bg-purple-600 text-white font-semibold rounded-lg shadow-lg transform hover:scale-105 hover:bg-purple-700 transition duration-200 ease-in-out">
                                    Get Started
                                </button>
                            </Link>

                            <Link to="/my-bots">
                                <button className="px-6 md:px-8 py-3 md:py-4 bg-gray-100 text-purple-600 font-semibold rounded-lg shadow-lg transform hover:scale-105 hover:bg-gray-200 transition duration-200 ease-in-out">
                                    My Bots
                                </button>
                            </Link>
                        </div>

                    </motion.article>
                </section>

                {/* Why Choose Us Section */}
                <section ref={whyChooseUsRef} className="container mx-auto py-16 px-6 bg-gray-100/10 min-h-full w-full text-center" style={{ borderRadius: '70px 70px 70px 70px' }}>
                    <motion.article
                        initial={{ opacity: 0, y: 50 }}
                        animate={isWhyChooseUsVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                        transition={{ duration: 0.5 }}
                        className="text-center mb-12 flex justify-center"
                    >
                        <h2 className="text-2xl md:text-3xl font-bold text-gray-200 w-full md:w-2/3 mx-auto">Create, customize, and deploy AI-powered chatbots to enhance your business engagement.</h2>
                    </motion.article>

                    {/* Desktop Features */}
                    <article className="hidden md:flex flex-wrap justify-center space-x-4">
                        {features.map((feature, index) => (
                            <motion.article
                                key={index}
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={isWhyChooseUsVisible ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.9 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                className="bg-white/5 p-6 border-2 border-gray-300/5 shadow-lg transition-shadow duration-300 ease-in-out transform hover:shadow-2xl w-64 flex flex-col items-center text-center mb-4"
                                style={{ borderRadius: '50px 0 50px 0' }}
                            >
                                <img loading="lazy" src={feature.img} alt={feature.title} className="mb-4 h-32 md:h-40 object-cover rounded-md" />
                                <h3 className="text-lg md:text-xl font-bold mb-2 text-pink-400">{feature.title}</h3>
                                <p className='text-white'>{feature.description}</p>
                            </motion.article>
                        ))}
                    </article>

                    {/* Mobile Features */}
                    <article className="md:hidden flex flex-col items-center">
                        <motion.article
                            className="p-6 border-2 border-gray-300/5 shadow-lg transition-shadow duration-300 ease-in-out transform hover:shadow-2xl w-full flex flex-col items-center text-center mb-4"
                        >
                            <img
                                loading="lazy"
                                src={features[currentFeatureIndex].img}
                                alt={features[currentFeatureIndex].title}
                                className="mb-4 h-32 md:h-40 object-cover rounded-md"
                            />
                            <h3 className="text-lg md:text-xl font-bold mb-2 text-pink-400">{features[currentFeatureIndex].title}</h3>
                            <p className='text-white'>{features[currentFeatureIndex].description}</p>
                        </motion.article>

                        {/* Navigation Buttons */}
                        <div className="flex justify-center w-full px-4 mt-4">
                            <button
                                onClick={handlePrevious}
                                className="bg-gray-600 text-white rounded-full p-2 mx-2"
                            >
                                <HiChevronLeft size={24} />
                            </button>
                            <button
                                onClick={handleNext}
                                className="bg-gray-600 text-white rounded-full p-2 mx-2"
                            >
                                <HiChevronRight size={24} />
                            </button>
                        </div>
                    </article>
                </section>

                {/* Splat Feature Section */}
                <section
                    ref={splatRef}
                    className="relative flex items-center justify-center py-16 mt-10"
                >
                    <div className="hidden md:flex flex-col items-center space-y-40">
                        <div className="relative flex items-center justify-center w-80 h-80">
                            <div className="p-4 rounded-full cursor-pointer flex items-center justify-center w-20 h-20 bg-white">
                                <img
                                    src="https://res.cloudinary.com/dbe2fast6/image/upload/v1728982612/logo-botanion-01_us1eyb.svg"
                                    alt="Bot Icon"
                                    className="w-full h-full object-contain"
                                />
                            </div>

                            {/* Animated Features */}
                            {newFeature.map((feature, index) => (
                                <motion.article
                                    key={feature.text}
                                    ref={(el) => (featureRef.current[index] = el)}
                                    className={`absolute p-3 rounded-lg shadow-sm text-center w-50 chat-bubble`} // Keep the size consistent
                                    style={{
                                        backgroundColor: pointerIndex === index ? featureColors[index % featureColors.length] : 'white',
                                        color: pointerIndex === index ? 'white' : 'black',
                                        // Pass background color for both the bubble and tail
                                        '--bubble-color': pointerIndex === index ? featureColors[index % featureColors.length] : 'white'
                                    }}
                                    custom={index}
                                    initial="splatIn"
                                    animate={isSplatVisible ? "splatOut" : "splatIn"}
                                    variants={featureVariants}
                                >
                                    {feature.text}
                                </motion.article>
                            ))}
                        </div>
                        <div className="hidden md:flex relative flex-col items-center justify-center text-center mt-7">
                            <div className="relative flex flex-wrap gap-4 justify-center">
                                {newCustomFeature.map((feature, index) => (
                                    <motion.article
                                        key={feature}
                                        id={`feature-${index}`} // Unique ID for each feature
                                        className={`p-3 rounded-lg shadow-sm`}
                                        style={{
                                            backgroundColor: pointerIndex === index ? featureColors[index % featureColors.length] : 'white',
                                            color: pointerIndex === index ? 'white' : 'black'
                                        }}
                                        initial={{ opacity: 0, x: 50 }}
                                        animate={visibleFeatures[index] ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }}
                                        transition={{ duration: 0.5, delay: index * 0.3 }}
                                    >
                                        {feature}
                                    </motion.article>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* Mobile Responsive Features */}
                    <div className="md:hidden flex flex-col items-center w-full">
                        <h2 className="text-xl font-bold text-gray-800">Features</h2>
                        {newCustomFeature.map((feature, index) => (
                            <motion.article
                                key={feature}
                                className="p-4 m-2 bg-gray-200 rounded-lg shadow-md w-full text-center"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.2 }}
                            >
                                <h3 className="text-lg font-semibold text-gray-700">{feature}</h3>
                                <p className="text-gray-600">{newFeature[index].text}</p> {/* Adjust content as needed */}
                            </motion.article>
                        ))}
                    </div>
                </section >
                {/* Call to Action Section */}
                < section ref={callToActionRef} className="py-6" >
                    <motion.article
                        initial={{ opacity: 0, y: 50 }}
                        animate={isCallToActionVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                        transition={{ duration: 0.5 }}
                        className="container mx-auto text-center"
                    >
                        <h2 className="text-2xl md:text-3xl font-bold mb-4 text-gray-100" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)' }}>
                            Ready to Build Your Chatbot?
                        </h2>
                        <p className="text-base md:text-lg mb-8 text-gray-300" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)' }}>
                            Start creating your chatbot today and transform the way you interact with your customers.
                        </p>
                        <Link to="/select-service">
                            <button className="px-6 md:px-8 py-3 md:py-4 bg-white text-purple-600 font-semibold rounded-lg shadow-lg hover:bg-gray-200">
                                Start Now
                            </button>
                        </Link>
                    </motion.article>
                </section >
            </div >

            <div>
                {/* Other sections */}
                <Carousel />
                {/* Other sections */}
            </div>
            <div className='mt-8'></div>
            <Footer />
        </>
    );
}

export default LandingPage;