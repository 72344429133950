// ChatInput.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BasicChatInput = ({
    searchQuery,
    setSearchQuery,
    sendIcon,
    currentTheme
}) => {
    return (
        <form
            className="flex items-center p-3 border-t bg-gray-50"
        >
            <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="flex-1 p-3 border rounded-full shadow-sm"
                style={{
                    borderColor: currentTheme.inputBorder,
                    color: currentTheme.inputTextColor,
                }}
                placeholder="Type a message..."
            />
            <button
                type="button"
                className="ml-2 p-2 rounded-full shadow-lg text-white"
                style={{ backgroundColor: currentTheme.buttonBg }}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = currentTheme.buttonHoverBg)}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = currentTheme.buttonBg)}
            >
                <FontAwesomeIcon icon={sendIcon} size="lg" />
            </button>
        </form>
    );
};

export default BasicChatInput;
